import { Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { LDClient, initialize, LDFlagChangeset, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { filter, switchMap, takeWhile, take } from 'rxjs/operators';
import { BehaviorSubject, interval, Observable, of as observableOf } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

import { environment } from 'environments/environment';
import { SentryService } from 'app/core/services/sentry.service';
import { UserService } from 'app/core/services/user.service';
import { User } from 'app/shared/models/user';
import { isUnauthenticatedUrl } from 'app/shared/helpers/search';

// Boolean Feature flag names recognized by LaunchDarkly
export enum BOOLEAN_FLAG_NAMES {
  // Empty enums are inferred to be numeric enums
  // We include a string placeholder so it is recognized as a string enum for type checking
  // if there are ever no feature flags
  // _ = '',
  LIBERTY_MUTUAL_OUTAGES = 'liberty-mutual-outages',
  HISCOX_RENEWAL_POLICY_OUTAGES = 'hiscox-renewal-policy-outages',
  BOP_CYBER_CROSS_SELL_WINDOW = 'bop-cyber-cross-sell-window',
  BOP_RENEWAL_CYBER_CROSS_SELL_WINDOW = 'bop-renewal-cyber-cross-sell-window',
  HISCOX_CYBER_CROSS_SELL_WINDOW = 'hiscox-cyber-cross-sell-window',
  COALITION_CYBER_STANDALONE_UPSELL = 'coalition-cyber-standalone-upsell',
  COALITION_CYBER_UPSELL_DEFAULT_TO_STANDALONE = 'coalition-cyber-upsell-default-to-standalone',
  COALITION_CYBER_REWARDS_PROGRESS_BAR = 'coalition-cyber-rewards-progress-bar',
  DOMAIN_ON_BOP_PLUS_POLICY_INFO = 'domain-on-bop-plus-policy-info',
  BOP_CYBER_BUNDLE_BIND = 'bop-cyber-bundle-bind',
  BOP_CYBER_BUNDLE_QUOTE_REVIEW = 'bop-cyber-bundle-quote-review',
  DISABLE_POLICIES_PANE_REWORK_AVAILABILITY = 'disable-policies-pane-rework-availability',
  BUNDLE_QUOTE_CREATION = 'bundle-quote-creation',
  CYBER_QUOTE_HELP_VIDEO = 'cyber-quote-help-video',
  CYBER_RISK_PROFILE = 'cyber-risk-profile',
  BOP_BUILDING_PREFILL = 'bop-building-prefill',
  USER_ATTRIBUTES_ONBOARDING_REDIRECT = 'user-attributes-onboarding-redirect',
  NEW_USER_BIND_REWARDS_IN_PORTAL = 'new-user-bind-rewards-in-portal',
  REWARDS_ACTIVITY_PANE = 'rewards-activity-pane',
  EVERPEAK_WORKERS_COMP = 'everpeak-workers-comp',
  REWARDS_TIER_PANEL = 'rewards-tier-panel',
  STREAKS_UI_ENABLED = 'streaks-ui-enabled',
  AI_CHECKER_FEATURE = 'ai-checker-feature',
  SEGMENT_ANALYTICS = 'segment-analytics',
  ALTERNATIVE_CARRIER_CARD = 'attune-wc-alternative-carrier-card',
  COMMISSION_PLAN = 'commission-plan',
  ATTUNE_WC_SUMMER_INCENTIVE = 'attune-wc-summer-incentive',
  ATTUNE_WC_BIND_BLOCK = 'attune-wc-bind-block',
  ATTUNE_WC_NON_PREFERRED_NO_ACCESS = 'attune-wc-non-preferred-no-access',
  ATTUNE_BOP_BIND_BLOCK = 'attune-bop-bind-block',
  ADP_WC_ACCESS = 'adp-wc-access',
  LOSS_RUNS_DOWNLOAD = 'loss-runs-download',
  BOP_CLASS_PREFERENCE = 'bop-class-preference',
  WC_CLASS_PREFERENCE = 'wc-class-preference',
  SHOW_WC_STRONGLY_PREFERRED = 'show-wc-strongly-preferred',
}

// JSON Feature flag names recognized by LaunchDarkly
export enum JSON_FLAG_NAMES {
  // Empty enums are inferred to be numeric enums
  // We include a string placeholder so it is recognized as a string enum for type checking
  // if there are ever no feature flags
  // _ = '',
  ATTUNE_WC_ENABLED_STATES = 'attune-wc-enabled-states',
  BIND_BLOCK_AM_MAPPING = 'bind-block-am-mapping',
  ATTUNE_BOP_FORTEGRA_ENABLED_STATES = 'attune-bop-fortegra-enabled-states',
}

// These are the supported return types for JSON flags.
export type JsonFlagReturnValue = any[] | Record<string, any> | null;
export type BooleanFlagReturnValue = boolean | null;

// Flags which are forced to an "enabled" state in test environments
// (All others are disabled)
const TEST_ENABLED_FLAGS = [
  BOOLEAN_FLAG_NAMES.EVERPEAK_WORKERS_COMP,
  BOOLEAN_FLAG_NAMES.WC_CLASS_PREFERENCE,
  BOOLEAN_FLAG_NAMES.BOP_CLASS_PREFERENCE,
  BOOLEAN_FLAG_NAMES.SHOW_WC_STRONGLY_PREFERRED,
];
// Key/value pairings of JSON flags that are "enabled" and return non-null data in test environments
const TEST_ENABLED_JSON_FLAGS = {
  [JSON_FLAG_NAMES.ATTUNE_WC_ENABLED_STATES]: ['SC', 'AZ', 'GA', 'TN', 'NC', 'MI'],
  [JSON_FLAG_NAMES.BIND_BLOCK_AM_MAPPING]: {
    accountManagers: [
      {
        name: 'Test Manager',
        bopBookBalanceLink: 'bop-book-balance-link',
        wcBookBalanceLink: 'wc-book-balance-link',
        states: ['NY', 'OH'],
      },
    ],
  },
  [JSON_FLAG_NAMES.ATTUNE_BOP_FORTEGRA_ENABLED_STATES]: {
    releaseDates: [
      {
        date: '10/28/2024',
        state: 'DE',
      },
    ],
  },
};

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  launchDarklyClient: LDClient;
  launchDarklyClientInitialized = false;
  // Given that the relevant feature flags in any given Agent Portal application
  // runtime will likely be a small subset of the client-side flags available in
  // LaunchDarkly, an internal hashmap is kept and added to when a flag's state
  // is checked via `isEnabled`.
  booleanFeatureFlags: { [key: string]: BehaviorSubject<BooleanFlagReturnValue> } = {};
  jsonFeatureFlags: { [key: string]: BehaviorSubject<JsonFlagReturnValue> } = {};
  currentUser: User | null = null;

  readonly isInMockMode;

  constructor(
    private sentryService: SentryService,
    private router: Router,
    private userService: UserService
  ) {
    this.isInMockMode = environment.useMockFeatureFlags;
    try {
      this.launchDarklyClient = !this.isInMockMode
        ? initialize(environment.launchDarklyKey, {
            // The LaunchDarkly client maintains the user state. To allow
            // access to flags as soon as possible, it's initialized with the
            // key `agent_portal_anonymous_user` for the anomyous state, and
            // reinitialized using `identify` when user data is available. All
            // of the user's feature flag values are loaded into memory.
            // Currently, individual anonymous users aren't supported in Agent
            // Portal for flag checks. Each individual anonymous user counts
            // towards LaunchDarkly's monthly active users count. If we wish
            // to progressively roll out a flag to anonymous users, we can
            // support via `anonymous: true` and remove the `key` property:
            // https://launchdarkly.github.io/js-client-sdk/interfaces/LDUser.html#anonymous
            key: 'agent_portal_anonymous_user',
          })
        : // To avoid API calls to LaunchDarkly in unit tests, the minimum valid
          // object required for the constructor, i.e. one containing the `on` method,
          // is returned. More values are set in the actual spec file for this
          // Service. Any other unit tests for Services/Components that relies on the
          // `FeatureFlagService` should use the `MockFeatureFlagService`.
          ({ on: function () {} } as unknown as LDClient);

      if (this.isInMockMode) {
        this.launchDarklyClientInitialized = true;
      }

      // `initialized` is triggered when the client successfully starts up and has
      // feature flag data.
      this.launchDarklyClient.on('initialized', () => {
        this.launchDarklyClientInitialized = true;
        const initialFlagSet = this.launchDarklyClient.allFlags();
        this.setValuesUsingFlagSet(initialFlagSet);

        this.sentryService.notify('Feature Flags: LaunchDarkly client initialized', {
          severity: 'info',
          metaData: {
            initialFlagSet,
          },
        });
      });

      // `failed` is triggered when the client encountered an error that prevented
      // it from connecting to LaunchDarkly, e.g. invalid environment ID. All flag
      // evaluations will result in a default value.
      this.launchDarklyClient.on('failed', (error: Error) => {
        // TODO: Consider logging errors to the console in non-Production environments.
        // The Sentry notification doesn't appear in the console.
        this.sentryService.notify('Feature Flags: LaunchDarkly client failed to initialize', {
          severity: 'error',
          metaData: {
            launchDarklyError: error.message,
            launchDarklyStack: error.stack,
          },
        });
      });

      // `change` is triggered when a flag state's changes, e.g. goes from
      // `false` to `true`, and when a user is identified to LaunchDarkly via the
      // `identify` function.
      this.launchDarklyClient.on('change', (flagChangeSet: LDFlagChangeset) => {
        if (!this.launchDarklyClientInitialized) {
          // A `change` event seems to fire simultaneously with client initialization based
          // on Sentry logs. We don't want to alert on this case because it is expected.
          // Instead we log and return early.
          this.sentryService.notify(
            'Feature Flags: LaunchDarkly client flag change received during initialization',
            {
              severity: 'info',
              metaData: {
                flagChangeSet,
              },
            }
          );
          return;
        }
        this.updateValuesUsingFlagChange(flagChangeSet);
        this.sentryService.notify('Feature Flags: LaunchDarkly client flag change triggered', {
          severity: 'info',
          metaData: {
            flagChangeSet,
          },
        });

        // TODO: Consider adding logging for non-Production environments since
        // LaunchDarkly doesn't log on its own.
      });

      // `error` is triggered when an error occurs during a LaunchDarkly client
      // operation.
      this.launchDarklyClient.on('error', (error: Error) => {
        // TODO: Consider logging errors to the console in non-Production environments.
        // The Sentry notification doesn't appear in the console.
        this.sentryService.notify('Feature Flags: LaunchDarkly client threw an error', {
          severity: 'error',
          metaData: {
            launchDarklyError: error.message,
            launchDarklyStack: error.stack,
          },
        });
      });

      // Because the FeatureFlagService may be initialized on
      // pages where user information is not available, listen
      // for route changes until the `currentUser` is defined.
      this.router.events
        .pipe(
          takeWhile(() => this.currentUser === null),
          filter((event) => event instanceof NavigationEnd),
          filter((event) => !isUnauthenticatedUrl((event as NavigationEnd).url))
        )
        .subscribe(() => {
          this.fetchUserInformation();
        });
      // ⬆️ TODO: This will need to be updated if we ever use a flag that's present
      // on both unauth'd & auth'd routes _and_ has a different value depending on
      // the user. At the moment, logging out may result in an incorrect flag state.
    } catch (error) {
      // To be safe, we'll catch any errors thrown during invoking the
      // `initialize` method or using the client's `on` method.
      this.sentryService.notify('Feature Flags: failed to create instance of service', {
        severity: 'error',
        metaData: {
          underlyingErrorMessage: error && error.message,
        },
      });
    }
  }

  public fetchUserInformation() {
    this.userService
      .getUser()
      .pipe(filter((user) => !!user))
      .subscribe((user) => {
        this.currentUser = user;
        // V3 upgrade notes:
        // - "The 3.0 version of this SDK lets you use contexts.
        // When you migrate from version 2.x, you should replace every instance of a user
        // with a context."
        // - A context always has a kind attribute.
        // - For user context, using `kind = 'user'`.
        // flags that use producerCode in LD need to be updated
        // to look at the user context -> /costume/producerCode instead
        const launchDarklyUserContext = {
          kind: 'user',
          // email comparisons in LD are case-sensitive. We should normalize emails to lowercase.
          key: this.currentUser.userName?.toLowerCase(),
          custom: {
            producerCode: this.currentUser.producer,
          },
        };

        try {
          this.launchDarklyClient
            .identify(launchDarklyUserContext)
            .then(() => {
              this.sentryService.notify('Feature Flags: LaunchDarkly client identified user', {
                severity: 'info',
                metaData: {
                  launchDarklyUser: launchDarklyUserContext,
                },
              });
            })
            .catch((reason) => {
              // TODO: Consider logging errors to the console in non-Production environments.
              // The Sentry notification doesn't appear in the console.
              this.sentryService.notify(
                'Feature Flags: error when identifying user to LaunchDarkly client',
                {
                  severity: 'error',
                  metaData: {
                    reason,
                  },
                }
              );
            });
        } catch (error) {
          this.sentryService.notify(
            'Feature Flags: error raised invoking LaunchDarkly client `identify` method',
            {
              severity: 'error',
              metaData: {
                underlyingErrorMessage: error && error.message,
              },
            }
          );
        }
      });
  }

  private getFlagValue<FlagValueType>(
    flagName: BOOLEAN_FLAG_NAMES | JSON_FLAG_NAMES,
    requireUserIdentification = false
  ): null | FlagValueType {
    if (!this.launchDarklyClientInitialized) {
      return null;
    }
    if (requireUserIdentification && !this.currentUser) {
      return null;
    }

    let flagValue: null | FlagValueType = null;
    try {
      flagValue = this.launchDarklyClient.variation(flagName, null);
      if (flagValue === null) {
        this.sentryService.notify(
          'Feature Flags: unable to determine flag state. Check if flag was created in LaunchDarkly.',
          {
            severity: 'error',
            metaData: {
              flagName,
            },
          }
        );
      }
    } catch (error) {
      this.sentryService.notify(
        'Feature Flags: error raised invoking LaunchDarkly client `variation` method. Flag state could not be determined.',
        {
          severity: 'error',
          metaData: {
            flagName,
            underlyingErrorMessage: error && error.message,
          },
        }
      );
    }

    // TODO: Consider adding logging for non-Production environments since
    // LaunchDarkly doesn't log on its own.
    return flagValue;
  }

  private updateValuesUsingFlagChange(flagChangeSet: LDFlagChangeset) {
    // For each flag that is passed in from LaunchDarkly's update,
    // 1) Check that it is tracked internally
    // 2) Check if the flag's value has changed and publish the new value
    for (const flagName of Object.keys(flagChangeSet)) {
      const newValue = flagChangeSet[flagName].current;
      if (Object.prototype.hasOwnProperty.call(this.booleanFeatureFlags, flagName)) {
        const previousValue = this.booleanFeatureFlags[flagName].value;
        if (previousValue !== newValue) {
          this.booleanFeatureFlags[flagName].next(newValue);
        }
      } else if (Object.prototype.hasOwnProperty.call(this.jsonFeatureFlags, flagName)) {
        const previousValue = this.jsonFeatureFlags[flagName].value;
        if (!isEqual(previousValue, newValue)) {
          this.jsonFeatureFlags[flagName].next(newValue);
        }
      }
    }
  }

  private setValuesUsingFlagSet(flagSet: LDFlagSet) {
    if (!this.launchDarklyClientInitialized) {
      this.sentryService.notify(
        'Feature Flags: attempt made to set flag state before LaunchDarkly client initialized',
        {
          severity: 'error',
          metaData: {
            flagSet,
          },
        }
      );
      return;
    }

    // For each flag that is tracked internally, check against the set provided
    // by the LaunchDarkly client to see if the flag's value has changed and
    // publish the new value.
    const setInitialFlagValue = (
      flagCollection: typeof this.booleanFeatureFlags | typeof this.jsonFeatureFlags,
      flagName: string
    ) => {
      const previousValue = flagCollection[flagName].value;
      const newValue = flagSet[flagName];
      if (newValue === undefined) {
        // Log and skip any flags we're tracking internally, but are not in LaunchDarkly.
        this.sentryService.notify(
          'Feature Flags: found flag that is tracked internally but not available in LaunchDarkly',
          {
            severity: 'error',
            metaData: {
              flagName,
            },
          }
        );
        return;
      }

      if (!isEqual(previousValue, newValue)) {
        flagCollection[flagName].next(newValue);
      }
    };

    // Set initial values for boolean feature flags.
    for (const flagName of Object.keys(this.booleanFeatureFlags)) {
      setInitialFlagValue(this.booleanFeatureFlags, flagName);
    }

    // Set initial values for JSON feature flags.
    for (const flagName of Object.keys(this.jsonFeatureFlags)) {
      setInitialFlagValue(this.jsonFeatureFlags, flagName);
    }
  }

  public isEnabled(
    flagName: BOOLEAN_FLAG_NAMES,
    requireUserIdentification = false
  ): Observable<BooleanFlagReturnValue> {
    if (this.isInMockMode) {
      return observableOf(TEST_ENABLED_FLAGS.includes(flagName) || null);
    }

    // If an external service or component requests information about a feature
    // flag that isn't being tracked, add it to the list of feature flags.
    if (this.booleanFeatureFlags[flagName] === undefined) {
      const flagEnabled = this.getFlagValue<BooleanFlagReturnValue>(
        flagName,
        requireUserIdentification
      );
      this.booleanFeatureFlags[flagName] = new BehaviorSubject(flagEnabled);
    }

    return this.booleanFeatureFlags[flagName].asObservable();
  }

  /**
   * Variant of the isEnabled check that first ensures that the feature flag
   * service is enabled, to avoid returning a falsy value by default.
   *
   * This method is useful when we want to be sure that the flag is available
   * before showing anything (for example when there are two different views
   * for a user depending on the flag state) since there is a brief window
   * between the app loading (e.g. on direct navigation or a page refresh) and
   * the feature flag service initializing.
   *
   * @param flagName name of the flag to check
   */
  public guaranteeIsEnabled(flagName: BOOLEAN_FLAG_NAMES): Observable<BooleanFlagReturnValue> {
    return interval(500).pipe(
      // Ensure feature flag is available before setting commissions view to avoid
      // briefly showing the default version first.
      filter(() => {
        return this.launchDarklyClientInitialized;
      }),
      take(1),
      switchMap(() => this.isEnabled(flagName))
    );
  }

  /**
   * @param flagName name of the flag to check
   * @returns Observable of JSON data for a given JSON flag.
   */

  public getJsonFlagValue<FlagValueType extends JsonFlagReturnValue>(
    flagName: JSON_FLAG_NAMES
  ): Observable<FlagValueType | null> {
    if (this.isInMockMode) {
      return observableOf<FlagValueType>(
        (TEST_ENABLED_JSON_FLAGS[flagName] || null) as FlagValueType
      );
    }

    // Check if the flag is already being tracked. If not, add it to the list of feature flags.
    if (this.jsonFeatureFlags[flagName] === undefined) {
      const flagJsonData = this.getFlagValue<FlagValueType>(flagName);
      this.jsonFeatureFlags[flagName] = new BehaviorSubject(flagJsonData);
    }

    // Return the Observable stream of the JSON flag value
    return this.jsonFeatureFlags[flagName].asObservable() as Observable<FlagValueType>;
  }
}
