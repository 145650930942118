import * as _ from 'lodash';
import * as moment from 'moment';

import {
  AccountPolicyPeriod,
  BP7Location,
  Clause,
  PolicyPeriod,
  QuotedPeriod,
  AccountLocation,
  PolicyPeriodResponse,
  WCALine,
} from 'app/bop/guidewire/typings';
import { US_DATE_MASK } from 'app/constants';

const BOP_LIABILITY_CLAUSE_CODE = 'BP7BusinessLiability';
const BOP_PATTERN_CODES: { [key: string]: string } = {
  aggregateLimit: 'BP7AggregateLimit',
  medicalLimitPerPerson: 'BP7OptionalMedicalCovLimitPerPerson',
  perOccurenceLimit: 'BP7EachOccLimit',
  tentansFireLiabilityLimit: 'BP7TenantsFireLiabLimit',
};

const EXCESS_CLAUSE_CODE = 'CUP_Primary_Liability_CUE';
const EXCESS_PATTERN_CODES: { [key: string]: string } = {
  aggregateLimit: 'CUP_GeneralAggregateLimit_CUE',
  perOccurenceLimit: 'CUP_OccurenceLimit_CUE',
};

// Pricing change went into ClarionDoor at 10AM Feb 11
const PRICING_BUG_DEPLOY_TIME = moment('2019-02-11T10:00:00.000-05:00');

/**
 * Iterates through clauses on the policy, looking for clauseCode, typically a primary
 * clause with the most important limits etc.
 * args:
 *  - clauses = AllClauses.Entry for a line of business
 *  - clauseCode = Policy Clause Code e.g. "BP7BusinessLiability" for main GL in BOP
 *  - patternCodes = { niceKey: "BP7FoobarObscurLimit" }
 *
 * returns {
 *   niceKey: valueForBP7FoobarObscurLimit;
 *   ... for each niceKey in patternCodes
 * }
 **/
const extractDetails = (
  clauses: Clause[],
  clauseCode: string,
  patternCodes: { [key: string]: string },
  owningCoverables?: string[]
) => {
  const details: { [key: string]: string } = {};

  const foundClause = _.find(clauses, (clause) => {
    if (owningCoverables) {
      return (
        clause.Pattern.Code === clauseCode &&
        owningCoverables.includes(clause.OwningCoverable.TypeIDString)
      );
    }
    return clause.Pattern.Code === clauseCode;
  });

  const clauseTerms = foundClause && foundClause.CovTerms ? foundClause.CovTerms.Entry : [];
  _.each(clauseTerms, (coverageTerm) => {
    const parsedKey = _.findKey(patternCodes, (key) => {
      return key === coverageTerm.PatternCode;
    });

    if (parsedKey) {
      details[parsedKey] = String(coverageTerm.CovTermValueForRating_HUSA);
    }
  });
  return details;
};

const extractExperienceModifiers = (wcaLine: WCALine): ExperienceModifier[] => {
  return _.flatMap(
    wcaLine.WCAJurisdictions.Entry,
    (jurisdiction) => jurisdiction.AllModifierVersions.Entry
  )
    .filter((modifier) => modifier.PatternCode === 'WCAExpMod' && modifier?.RateModifier)
    .map((modifier) => {
      return {
        startDate: moment(modifier.EffectiveDate).format(US_DATE_MASK),
        endDate: moment(modifier.ExpirationDate).format(US_DATE_MASK),
        modifierRate: parseFloat(modifier.RateModifier),
        state: modifier.State,
      };
    });
};

const anyBuildingsHaveBadClassificationNumber = (bp7Locations: any): boolean => {
  return _.some(bp7Locations, (locationEntry) => {
    const buildings = _.get(locationEntry, 'Buildings.Entry', []);
    return _.some(buildings, (building) => {
      return ![1, 2, 3, 4, 5].includes(
        parseInt(_.get(building, 'Classifications.Entry.0.ClassificationNumber', 1), 10)
      );
    });
  });
};

/**
 * Backend policy periods come with two sources of Contact / Company Name data:
 *
 *  - The PolicyAddress and company DisplayName
 *  - The ContactDenorm - a pointer to the contact associated with this policy period
 *
 * this method detects any differences and returns true if they don't match.
 */
const addressOrCompanyNameChanged = (policyPeriod: QuotedPeriod): boolean => {
  const policyAddress = policyPeriod.PolicyAddress;
  const policyCompanyName = policyPeriod.PrimaryNamedInsured.DisplayName;
  const contactDenorm = policyPeriod.PrimaryNamedInsured.ContactDenorm;
  const contactAddress = contactDenorm.PrimaryAddress;

  if (!policyAddress || !contactAddress) {
    console.warn('Unexpected missing Policy Period addresses', policyAddress, contactAddress);
    return false;
  }

  if (
    policyAddress.AddressLine1 !== contactAddress.AddressLine1 ||
    policyAddress.AddressLine2 !== contactAddress.AddressLine2 ||
    policyAddress.City !== contactAddress.City ||
    policyAddress.State !== contactAddress.State ||
    policyAddress.PostalCode !== contactAddress.PostalCode ||
    policyCompanyName !== contactDenorm.Name
  ) {
    return true;
  }

  return false;
};

/**
 * Prior to 2019-02-11, multiple classifications were mispriced, this method detects quotes like this.
 **/
const checkIfQuoteIsOverpriced = (locations: BP7Location[], updatedAt: number): boolean => {
  const moreThanOneClassification =
    locations.length > 1 ||
    locations[0].Buildings.Entry.length > 1 ||
    locations[0].Buildings.Entry[0].Classifications.Entry.length > 1;

  if (moreThanOneClassification && moment(updatedAt).isBefore(PRICING_BUG_DEPLOY_TIME)) {
    return true;
  }
  return false;
};

const hasMoreThanOneClassification = (location: BP7Location) => {
  return _.some(location.Buildings.Entry, (building) => {
    return building.Classifications.Entry.length > 1;
  });
};

const locationHasMultipleBuildingsOrClassifications = (locations: BP7Location[]) => {
  return _.some(locations, (location) => {
    return location.Buildings.Entry.length > 1 || hasMoreThanOneClassification(location);
  });
};

interface CoverageDetails {
  employmentPracticesLiabilityCoverage: { [key: string]: string };
  liquorLiabilityCoverage: { [key: string]: string };
  spoilageCoverage: { [key: string]: string };
  cyberCoverage: { [key: string]: string };
}

export const mapPolicyPeriodToQuoteDetails = (policy: QuotedPeriod): QuoteDetails => {
  const isBopQuote = policy.LineBusinessType === 'Businessowners Line (v7)';
  const isExcessQuote = policy.LineBusinessType === 'Commercial Excess Liability Line';
  const EL_PATTERN_CODE = 'CUP_EmployersLiability_CUE';
  const CA_PATTERN_CODE = 'CUP_AutoMobileLiability_CUE';
  const UMUIM_PATTERN_CODE = 'CUP_UM_UIM_CUE';
  let CUE_EXTANT_PATTERN_CODES: { [K: string]: Clause } = {};

  if (policy.CommercialUmbrellaLine_CUE) {
    CUE_EXTANT_PATTERN_CODES = policy.CommercialUmbrellaLine_CUE.AllClauses.Entry.reduce(
      (codes: { [K: string]: any }, clause) => {
        codes[clause.Pattern.Code] = clause;
        return codes;
      },
      {}
    );
  }

  let patternCodeDetails: { [key: string]: string } = {};
  let locationDetails: { [key: string]: string }[] = [];
  const coverageDetails: CoverageDetails = {
    employmentPracticesLiabilityCoverage: {},
    liquorLiabilityCoverage: {},
    spoilageCoverage: {},
    cyberCoverage: {},
  };
  let hasCyberCoverageBug = false;
  let isOverpriced = false;
  let hazardGroup: string | undefined;
  let governingClassDescription: string | undefined;
  let governingClassCode: string | undefined;
  let safetyReview = false;

  if (policy.BP7Line !== undefined && policy.BP7Line !== null && policy.BP7LineExists) {
    const clauses = policy.BP7Line.AllClauses.Entry;
    locationDetails = policy.BP7Line.BP7Locations.Entry.map((location: BP7Location): any => {
      const locationNum = location.Buildings.Entry[0].Building.PolicyLocation.LocationNum;
      const buildingNum = location.Buildings.Entry[0].Building.BuildingNum;
      const classCode = location.Buildings.Entry[0].Classifications.Entry[0].ClassCode;
      const ids = [
        location.TypeIDString,
        location.Buildings.Entry[0].TypeIDString,
        location.Buildings.Entry[0].Classifications.Entry[0].TypeIDString,
      ];
      return {
        ...extractDetails(
          clauses,
          'BP7BuildWind_HUSA',
          { windDeductible: 'BP7WindOrHailDed_HUSA' },
          ids
        ),
        ...extractDetails(
          clauses,
          'BP7ClassificationBusinessPersonalProperty',
          { businessPersonalProperty: 'BP7BusnPrsnlPropLimit' },
          ids
        ),
        ...extractDetails(clauses, 'BP7Structure', { buildingLimit: 'BP7BuildingLimit' }, ids),
        ...extractDetails(
          clauses,
          'BP7LocationPropertyDeductibles',
          {
            aopDeductible: 'BP7OptionalDeductible',
          },
          ids
        ),
        buildingNum,
        locationNum,
        classCode,
      };
    });

    patternCodeDetails = {
      ...patternCodeDetails,
      ...extractDetails(clauses, BOP_LIABILITY_CLAUSE_CODE, BOP_PATTERN_CODES),
      lineBusinessType: policy.BP7Line.BP7LineBusinessType,
    };

    coverageDetails.employmentPracticesLiabilityCoverage = extractDetails(
      clauses,
      'BP7EmploymentRelatedPracticesLiabilityCov',
      {
        limit: 'BP7AggLimit1',
      }
    );

    coverageDetails.liquorLiabilityCoverage = extractDetails(clauses, 'BP7LiquorLiabCov', {
      aggregateLimit: 'BP7LiquorAggregateLimit1',
      eachCommonCauseLimit: 'BP7LiquorEachCommonCauseLimit1',
    });

    coverageDetails.spoilageCoverage = extractDetails(clauses, 'BP7SpoilgCov', {
      limit: 'BP7Limit32',
    });

    coverageDetails.cyberCoverage = extractDetails(clauses, 'BP7DataResponseCyberLiability', {
      limit: 'BP7CoverageEndorsementAggregate',
    });

    if (anyBuildingsHaveBadClassificationNumber(_.get(policy, 'BP7Line.BP7Locations.Entry', []))) {
      // TODO(olex): Rename to hasBuggedClassificationNumber
      // This bug affects more coverage terms than cyber, leaving the term here for hotfix clarity
      hasCyberCoverageBug = true;
    }

    isOverpriced = checkIfQuoteIsOverpriced(policy.BP7Line.BP7Locations.Entry, policy.UpdateTime);
  }

  if (policy.CommercialUmbrellaLine_CUE) {
    patternCodeDetails = {
      ...patternCodeDetails,
      ...extractDetails(
        policy.CommercialUmbrellaLine_CUE.AllClauses.Entry,
        EXCESS_CLAUSE_CODE,
        EXCESS_PATTERN_CODES
      ),
    };
  }

  let experienceModifiers: ExperienceModifier[] | undefined;
  if (policy.WCALineExists && policy.WCALine) {
    const clauses = policy.WCALine.AllClauses.Entry;
    hazardGroup = policy.WCALine?.WCAGoverningClass?.HazardGroup;
    governingClassCode = policy.WCALine.WCAGoverningClass?.Code;
    governingClassDescription = policy.WCALine.WCAGoverningClass?.Classification;
    safetyReview = policy.WCALine.SafetyReview_ATTN || false;
    const wcPatternCodes = {
      ...extractDetails(clauses, 'WCABenefitsDedCov', {
        wcDeductible: 'WCABenefitsDedCovDeductible',
      }),
      ...extractDetails(clauses, 'WCAWCEmpLiabInsurancePolicyACov', {
        wcEmpLiabilityLimits: 'WCAWCEmpLiabInsurancePolicyACovEmpLiabLimit',
      }),
    };
    patternCodeDetails = {
      ...patternCodeDetails,
      ...wcPatternCodes,
    };

    experienceModifiers = extractExperienceModifiers(policy.WCALine);
  }

  const excessCLLimit = isExcessQuote ? patternCodeDetails.aggregateLimit || false : false;

  let uwIssues = [];
  if (policy.UWIssuesIncludingSoftDeleted && policy.UWIssuesIncludingSoftDeleted.Entry) {
    uwIssues = policy.UWIssuesIncludingSoftDeleted.Entry.map((issue: any) => {
      return issue.ShortDescription;
    });
  }

  let accountLocations: AccountLocation[] = [];
  if (policy.PolicyLocations && policy.PolicyLocations.Entry) {
    accountLocations = policy.PolicyLocations.Entry.map((policyLocation) => {
      return policyLocation.AccountLocation;
    });
  }

  let product: InsuranceProduct = 'Unknown';
  if (isBopQuote) {
    product = 'BOP';
  } else if (policy.WCALineExists) {
    product = 'WC';
  } else if (isExcessQuote) {
    product = 'Excess';
  } else if (policy.CPLineExists) {
    product = 'Habitational';
  }

  return {
    accountLocations: accountLocations,
    allCosts: policy.AllCosts ? policy.AllCosts.Entry : [],
    baseState: policy.BaseState,
    bindable: policy.Status.toLowerCase() === 'quoted' && !hasCyberCoverageBug && !isOverpriced,
    contact: policy.PrimaryNamedInsured.ContactDenorm,
    coverages: coverageDetails,
    createdAt: policy.QuoteCreateTime,
    excessCAClause: (isExcessQuote && CUE_EXTANT_PATTERN_CODES[CA_PATTERN_CODE]) || false,
    excessCLLimit: excessCLLimit ? parseInt(excessCLLimit, 10) : false,
    excessELClause: (isExcessQuote && CUE_EXTANT_PATTERN_CODES[EL_PATTERN_CODE]) || false,
    excessUMUIMClause: (isExcessQuote && CUE_EXTANT_PATTERN_CODES[UMUIM_PATTERN_CODE]) || false,
    experienceModifiers: experienceModifiers,
    hasCyberCoverageBug: hasCyberCoverageBug,
    hasExcessPolicy: isBopQuote && !!policy.Job.LinkedJobNumber_ATN,
    hasExcessPolicyDeclined:
      policy.UWIssuesIncludingSoftDeleted !== null &&
      policy.UWIssuesIncludingSoftDeleted !== undefined,
    hazardGroup,
    governingClassDescription,
    governingClassCode,
    safetyReview,
    locationHasMultipleBuildingsOrClassifications: policy.BP7Line
      ? locationHasMultipleBuildingsOrClassifications(policy.BP7Line.BP7Locations.Entry)
      : false,
    id: policy.Job.JobNumber,
    isQuoteOverpriced: isOverpriced,
    linkedJobId: policy.Job.LinkedJobNumber_ATN || null,
    locations: locationDetails,
    needsRequote: addressOrCompanyNameChanged(policy),
    patternCodes: patternCodeDetails,
    policyEnd: moment(policy.PeriodEnd),
    policyStart: moment(policy.PeriodStart),
    product: product,
    quoteName: policy.Job.QuoteName_HUSA || 'Quote details',
    status: policy.Status,
    uwCompanyCode: policy.UWCompany.Code,
    totalCost: parseFloat(policy.TotalCostRPT),
    totalPremium: parseFloat(policy.TotalPremiumRPT),
    totalTaxes: policy.TotalTaxesAndFeesExcludingTechAndProcessingFees_ATTN
      ? parseFloat(policy.TotalTaxesAndFeesExcludingTechAndProcessingFees_ATTN)
      : parseFloat(policy.TotalTaxesAndFees_HUSA),
    technologyFee: parseFloat(policy.TotalTechFees_ATTN || '0'),
    processingFee: parseFloat(policy.ProcessingFees_ATTN || '0'),
    tsRequestId: policy.Job.TSRequestID_HUSA || '',
    updatedAt: moment(policy.UpdateTime),
    uwIssues: uwIssues,
  };
};

export const isExcess = (policyTermTransaction: PolicyPeriod) => {
  return (
    policyTermTransaction &&
    policyTermTransaction.LineBusinessType &&
    policyTermTransaction.LineBusinessType.match('Commercial Excess')
  );
};

export const isBop = (policyTermTransaction: PolicyPeriod) => {
  return (
    policyTermTransaction &&
    policyTermTransaction.LineBusinessType &&
    policyTermTransaction.LineBusinessType.match('Businessowners')
  );
};

export const isAttuneWc = (policyTermTransaction: PolicyPeriod) => {
  return (
    policyTermTransaction &&
    policyTermTransaction.LineBusinessType &&
    policyTermTransaction.LineBusinessType.match("Workers' Comp Line")
  );
};

export const isHab = (policyTermTransaction: PolicyPeriod) => {
  return (
    policyTermTransaction &&
    _.get(policyTermTransaction, 'PolicyTerm.PortalViewableTermPeriods.Entry', []).some(
      (displayTerm: AccountPolicyPeriod) => {
        return displayTerm.Policy.ProductCode === 'Habitational';
      }
    )
  );
};

export const displayProductName = (policyTermTransaction: PolicyPeriod) => {
  if (isBop(policyTermTransaction)) {
    return "Business Owner's Policy";
  } else if (isAttuneWc(policyTermTransaction)) {
    return "Workers' Compensation Policy";
  } else if (isExcess(policyTermTransaction)) {
    return 'Excess Liability Policy';
  } else if (isHab(policyTermTransaction)) {
    return 'Apartments Program Policy';
  } else {
    return 'Policy';
  }
};

export const mapToQuoteDetails = (payload: PolicyPeriodResponse): QuoteDetails => {
  return mapPolicyPeriodToQuoteDetails(payload.return.PolicyPeriod);
};
