import { Component } from '@angular/core';

// Components
import { SteppedFormPageComponent } from 'app/shared/stepped-form/stepped-form-page.component';

// Models
import {
  CoveragesCreditsFormGroup,
  nonNCCIExperienceModifiers,
} from 'app/workers-comp/attune/models/state-coverages-form.model';
import {
  THREE_YEAR_LOSS_RATIO_OPTONS,
  PERCENT_MEDICAL_CLAIMS_OPTIONS,
  PERCENT_UNINSURED_CONTRACTOR_OPTIONS,
} from 'app/workers-comp/attune/constants';

// Services
import { AttuneWcQuoteFormService } from 'app/workers-comp/attune/services/attune-wc-quote-form.service';

@Component({
  selector: 'app-wc-coverages-credits-page',
  templateUrl: './attune-wc-coverages-credits-page.component.html',
})
export class AttuneWcCoveragesCreditsPageComponent extends SteppedFormPageComponent<
  AttuneWcQuoteFormService,
  CoveragesCreditsFormGroup
> {
  formPath: string = 'coveragesAndCredits';

  threeYearLossRatioOptions = THREE_YEAR_LOSS_RATIO_OPTONS;
  percentageMedicalClaimsOptions = PERCENT_MEDICAL_CLAIMS_OPTIONS;
  percentUninsuredContractorOptions = PERCENT_UNINSURED_CONTRACTOR_OPTIONS;

  constructor(protected formService: AttuneWcQuoteFormService) {
    super(formService);
  }

  get experienceModsFormGroup() {
    return this.form.get('nonNCCIExperienceModifiers') as nonNCCIExperienceModifiers;
  }
}
