/**
 * This file contains a utility to generate the new/edit quote routes for Attune WC.
 * These two routes will use the same components and this ensures the two routes will stay in sync.
 */
import { AuthGuard } from 'app/core/guards/auth.guard';
import { FeatureFlagEnabledGuard } from 'app/core/guards/feature-flag-enabled.guard';
import { BOOLEAN_FLAG_NAMES } from 'app/core/services/feature-flag.service';

import { AttuneWcBasicInfoPageComponent } from 'app/workers-comp/attune/components/form-pages/quote/basic-info/attune-wc-basic-info-page.component';
import { AttuneWcExecutivesPageComponent } from 'app/workers-comp/attune/components/form-pages/quote/executive-elections/attune-wc-executives-page.component';
import { AttuneWcGuidelinesPageComponent } from 'app/workers-comp/attune/components/form-pages/quote/guidelines/attune-wc-guidelines-page.component';
import { AttuneWcLocationPageComponent } from 'app/workers-comp/attune/components/form-pages/quote/location/attune-wc-location-page.component';
import { AttuneWcQuoteFormComponent } from 'app/workers-comp/attune/components/form/quote/attune-wc-quote-form.component';
import { AttuneWcCoveragesCreditsPageComponent } from 'app/workers-comp/attune/components/form-pages/quote/coverages-credits/attune-wc-coverages-credits-page.component';
import { SteppedFormRedirectPageComponent } from 'app/shared/stepped-form/stepped-form-account-redirect-page.component';

function createAttuneWcQuoteRoute(basePath: string) {
  return {
    canActivate: [AuthGuard, FeatureFlagEnabledGuard],
    component: AttuneWcQuoteFormComponent,
    path: basePath,
    data: {
      flagName: BOOLEAN_FLAG_NAMES.EVERPEAK_WORKERS_COMP,
    },
    children: [
      {
        component: SteppedFormRedirectPageComponent,
        path: 'account',
      },
      {
        component: AttuneWcGuidelinesPageComponent,
        path: 'guidelines',
      },
      {
        component: AttuneWcBasicInfoPageComponent,
        path: 'basic-info',
      },
      {
        component: AttuneWcLocationPageComponent,
        path: 'location/:locationId',
      },
      {
        component: AttuneWcExecutivesPageComponent,
        path: 'executive-elections',
      },
      {
        component: AttuneWcCoveragesCreditsPageComponent,
        path: 'coverages-credits',
      },
    ],
  };
}

export const ATTUNE_WC_NEW_QUOTE_ROUTE = createAttuneWcQuoteRoute(
  'accounts/:accountId/attune/workers-comp/new'
);
export const ATTUNE_WC_EDIT_QUOTE_ROUTE = createAttuneWcQuoteRoute(
  'accounts/:accountId/attune/workers-comp/quotes/:quoteNumber/edit'
);
export const ATTUNE_WC_DRAFT_QUOTE_ROUTE = createAttuneWcQuoteRoute(
  'accounts/:accountId/attune/workers-comp/drafts/:draftId'
);
