<section class="form-animation">
  <h1>Basic info</h1>

  <section>
    <app-form-field-text
      inputId="effectiveDate"
      labelText="Policy effective date"
      [form]="form"
      [submitted]="submitted()"
      nameOfFormControl="effectiveDate"
      appDateMask
    >
    </app-form-field-text>
    <div class="validation" role="alert" *ngIf="fieldInvalid('effectiveDate')">
      <p class="validation-message">
        Please enter a date that is today or up to three months from today.
      </p>
    </div>
  </section>

  <section>
    <div class="form-split">
      <app-form-field-text
        inputId="employerIdentificationNumber"
        [form]="form"
        [submitted]="submitted()"
        labelText="FEIN"
        nameOfFormControl="employerIdentificationNumber"
        placeholder="00-0000000"
        appFormatMask
        maskFormat="XX-XXXXXXX"
        allowedCharacters="NUMBERS_ONLY"
      >
      </app-form-field-text>
      <app-form-field-text
        inputId="yearsInBusiness"
        [form]="form"
        [submitted]="submitted()"
        labelText="Years in business"
        nameOfFormControl="yearsInBusiness"
        appDelimitedNumberMask
      >
      </app-form-field-text>
    </div>
    <!-- Warning message for a placeholder FEIN -->
    <div
      *ngIf="form.get('employerIdentificationNumber')?.hasError('placeholderFein')"
      class="dialog-text dialog-text__error"
    >
      <strong>Is this a real FEIN number?</strong> FEINs are used to calculate premium, so please,
      no placeholder values. If you proceed with a placeholder FEIN, your account will be flagged
      for internal review.
    </div>
  </section>

  <section>
    <app-form-field-select
      inputId="organizationType"
      labelText="Organization type"
      [form]="form"
      [submitted]="submitted()"
      [readonly]="isOrgTypeReadonly"
      [availableOptions]="organizationTypes"
      nameOfFormControl="organizationType"
      labelText="Organization type"
    >
    </app-form-field-select>

    <app-form-field-select
      inputId="empLiabilityLimits"
      [form]="form"
      [submitted]="submitted()"
      labelText="Employer's Liability Limits (Each Claim, Policy Limit, Each Employee)"
      nameOfFormControl="employersLiabilityLimits"
      [availableOptions]="empLiabilityLimitsOptions"
    >
    </app-form-field-select>

    <app-form-field-select
      inputId="deductible"
      [form]="form"
      [submitted]="submitted()"
      [readonly]="isDeductibleReadOnly()"
      [tooltipText]="deductibleToolTip()"
      labelText="Deductible"
      nameOfFormControl="deductible"
      [availableOptions]="deductibleOptions"
    >
    </app-form-field-select>
  </section>

  <section>
    <app-form-field-radio
      inputId="hasWaiverOfSubrogation"
      [form]="waiverOfSubFormGroup"
      [submitted]="submitted()"
      questionText="Would you like a waiver of subrogation?"
      nameOfFormControl="hasWaiverOfSubrogation"
    >
    </app-form-field-radio>

    <div class="form-subsection">
      <app-form-field-radio
        inputId="typeOfWaiver"
        [form]="waiverOfSubFormGroup"
        [submitted]="submitted()"
        [options]="waiverOfSubrogationOptions"
        questionText="Type of waiver:"
        nameOfFormControl="waiverType"
      >
      </app-form-field-radio>

      <section *ngIf="specificWaiversFormArray?.enabled" class="js-specific-waiver-controls">
        <div
          id="specific-waiver-of-subrigation-{{ i }}"
          class="multi-form-item"
          *ngFor="let specificWaiver of specificWaiversFormArray.controls; let i = index"
        >
          <app-form-field-text
            inputId="specific-waiver-{{ i }}-nameOrOrg"
            [form]="specificWaiver"
            [submitted]="submitted()"
            labelText="Name of person or organization"
            nameOfFormControl="nameOrOrg"
          >
          </app-form-field-text>

          <app-form-field-address
            labelText="Address"
            prefix="specific-waiver-{{ i }}-address"
            [form]="specificWaiver.get('address')"
            [submitted]="submitted()"
          ></app-form-field-address>

          <app-form-field-text
            inputId="specific-waiver-{{ i }}-description"
            [form]="specificWaiver"
            [submitted]="submitted()"
            labelText="Description"
            nameOfFormControl="description"
          >
          </app-form-field-text>

          <div class="nav-button-group">
            <a
              class="button button__small button__secondary js-remove-specific-waiver-button"
              (click)="removeSpecificWaiver(i)"
              (keyup.enter)="removeSpecificWaiver(i)"
              *ngIf="specificWaiversFormArray.length > 1"
            >
              Remove
            </a>
          </div>
        </div>

        <button
          class="
            button button__secondary button__full-width button__no-margin
            js-add-specific-waiver-button
          "
          type="button"
          (click)="addSpecificWaiver()"
        >
          Add specific waiver
        </button>
      </section>
    </div>
  </section>

  <section>
    <app-number-of-locations
      [form]="form"
      [submitted]="submitted()"
      [currentNumberOfLocations]="locationsFormArray().length"
      (addLocations)="onAddLocations($event)"
      (removeLocations)="onRemoveLocations($event)"
    >
    </app-number-of-locations>
    <div
      *ngIf="form.get('numberOfLocations')?.hasError('max')"
      class="dialog-text dialog-text__warning dialog-text__warning-icon"
    >
      <span>
        We currently don't quote more than 6 locations online but our customer care team will be
        happy to through our
        <a href="https://app.attuneinsurance.com/help-center">Help Center</a> or over the phone
        <a href="tel:18885304650">(1-888-530-4650)</a>.
      </span>
    </div>
  </section>

  <!-- Eligibility related questions -->
  <section>
    <app-form-field-radio
      inputId="lessThan100PercentOwnership"
      [form]="form"
      [submitted]="submitted()"
      questionText="Does the insured own 100% of the business?"
      [options]="ownershipPercentageOptions"
      nameOfFormControl="lessThan100PercentOwnership"
    >
    </app-form-field-radio>

    <app-form-field-radio
      inputId="bankruptcyNotDischarged"
      [form]="form"
      [submitted]="submitted()"
      questionText="Is the insured currently in bankruptcy which has not been discharged?"
      nameOfFormControl="bankruptcyNotDischarged"
    >
    </app-form-field-radio>
  </section>
  <!-- End of eligibility related questions -->
</section>
