import { Component, OnInit } from '@angular/core';
import { times } from 'lodash';

import { SteppedFormPageComponent } from 'app/shared/stepped-form/stepped-form-page.component';
import { BasicsInfoFormGroup } from 'app/workers-comp/attune/models/quote-form.model';

// Services
import { AttuneWcQuoteFormService } from 'app/workers-comp/attune/services/attune-wc-quote-form.service';
import { OrganizationTypeService } from 'app/shared/services/organization-type.service';

// Constants
import {
  EMP_LIABILITY_LIMITS_OPTIONS,
  WAIVER_OF_SUB_OPTIONS,
  NO_DEDUCTIBLE_STATES,
  NO_DEDUCTIBLE_STATES_FULL_NAMES,
} from 'app/workers-comp/attune/constants';

@Component({
  selector: 'app-wc-basic-info-page',
  templateUrl: './attune-wc-basic-info-page.component.html',
})
export class AttuneWcBasicInfoPageComponent
  extends SteppedFormPageComponent<AttuneWcQuoteFormService, BasicsInfoFormGroup>
  implements OnInit
{
  formPath = 'basicInfo';

  // Form options
  organizationTypes: { [key: string]: string } = {};
  empLiabilityLimitsOptions = EMP_LIABILITY_LIMITS_OPTIONS;
  waiverOfSubrogationOptions = WAIVER_OF_SUB_OPTIONS;

  // When answering 'yes' to 'Does the insured own 100% of the business?' the value of lessThan100PercentOwnership should be false.
  ownershipPercentageOptions = {
    Yes: false,
    No: true,
  };

  constructor(
    protected formService: AttuneWcQuoteFormService,
    protected orgTypeService: OrganizationTypeService
  ) {
    super(formService);
  }

  ngOnInit(): void {
    this.setOrganizationTypes();
    super.ngOnInit();
  }

  locationsFormArray() {
    return this.formService.locationsFormArray();
  }

  onAddLocations(numberToAdd: number) {
    times(numberToAdd, () => this.formService.addLocation());
  }

  onRemoveLocations(numberToRemove: number) {
    times(numberToRemove, () => this.formService.removeLastLocation());
  }

  setOrganizationTypes() {
    this.orgTypeService.getOrganizationTypes().forEach((orgType) => {
      this.organizationTypes[orgType.value] = orgType.name;
    });
  }

  /**
   * Start of waiver of subrogation methods.
   */

  removeSpecificWaiver(index: number) {
    return this.formService.removeSpecificWaiver(index);
  }

  addSpecificWaiver() {
    return this.formService.addSpecificWaiver();
  }

  get waiverOfSubFormGroup() {
    return this.form.get('waiverOfSubrogation');
  }

  get specificWaiversFormArray() {
    return this.form.get('waiverOfSubrogation.specificWaivers');
  }

  /**
   * End of waiver of subrogation methods
   */

  isDeductibleReadOnly() {
    return this.locationStates.some((state) => {
      return NO_DEDUCTIBLE_STATES.includes(state);
    });
  }

  deductibleToolTip() {
    if (this.isDeductibleReadOnly()) {
      return `Not applicable in ${NO_DEDUCTIBLE_STATES_FULL_NAMES.join(', ')}.`;
    }
    return '';
  }

  get isOrgTypeReadonly() {
    return this.formService.isOrgTypeReadonly;
  }

  get locationStates() {
    return this.formService.uniqueLocationStates;
  }

  get deductibleOptions() {
    return this.formService.deductibleOptions;
  }
}
