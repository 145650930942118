import { Directive, Input, OnInit } from '@angular/core';
import { BaseMaskDirective } from './base-mask.directive';

@Directive({
  selector: '[appFloatMask]',
})
export class FloatMaskDirective extends BaseMaskDirective implements OnInit {
  @Input() maxWholeDigits: number = 10;
  @Input() maxDecimalPlaces: number = 2;

  ngOnInit() {
    // We add one to account for the decimal point.
    this.MAX_DIGITS = this.maxWholeDigits + this.maxDecimalPlaces + 1;
  }

  getMask(value: string): string {
    let formattedValue = this.getNumberValueFormattedWithDecimals(value, false);

    if (formattedValue) {
      let [whole, decimal] = formattedValue.split('.');

      if (this.maxWholeDigits && whole && whole.length > this.maxWholeDigits) {
        // Take extra numbers from 'whole' beyond the maxWholeDigits to form a new decimal
        const newDecimal = whole.slice(this.maxWholeDigits);
        whole = whole.slice(0, this.maxWholeDigits);

        // If there was an existing decimal, append it after the new decimal
        if (newDecimal) {
          decimal = newDecimal + (decimal || '');
        }
      }

      if (this.maxDecimalPlaces && decimal && decimal.length > this.maxDecimalPlaces) {
        decimal = decimal.slice(0, this.maxDecimalPlaces);
      }
      // This allows us to insert a decimal point automatically
      // once a user types past the max whole digits allowed
      formattedValue = decimal !== undefined ? `${whole}.${decimal}` : whole;
    }

    return formattedValue || '';
  }
}
