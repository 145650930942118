<section *ngIf="form">
  <div class="form-split">
    <app-form-field-text
      inputId="{{ state }}-riskId"
      [form]="form"
      [submitted]="submitted"
      labelText="{{ fullStateName }} Risk-ID"
      subLabelText="If Risk-ID does not exist, or is not available, leave blank"
      nameOfFormControl="riskId"
      [placeholder]="riskIdPlaceholder"
      appNumberMask
      [maxLength]="riskIdValidationLength"
    >
    </app-form-field-text>

    <app-form-field-text
      inputId="{{ state }}-experienceMod"
      [form]="form"
      [submitted]="submitted"
      labelText="{{ fullStateName }} Experience Mod"
      subLabelText="If {{ fullStateName }} Risk-ID does not exist, or is not available, leave blank"
      nameOfFormControl="experienceMod"
      appFloatMask
      maxWholeDigits="1"
      maxDecimalPlaces="2"
    >
    </app-form-field-text>
  </div>

  <div class="validation" role="alert" *ngIf="submitted && form.hasError('requireBothFields')">
    <p class="validation-message">Both Risk-ID and Experience Mod are required.</p>
  </div>
</section>
