import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FEINPlaceholdersWithoutHyphen } from 'app/workers-comp/shared/constants';

export const riskIdExperienceModValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const riskId = control.get('riskId')?.value;
  const experienceMod = control.get('experienceMod')?.value;

  // If either field has a value, then both are required.
  if ((riskId && !experienceMod) || (!riskId && experienceMod)) {
    return { requireBothFields: true };
  }

  return null;
};

export const RISK_ID_LENGTH_BY_STATE: { [stateCode: string]: number } = {
  MI: 9,
  PA: 7,
  NC: 7,
};

export const riskIdValidator = (stateCode: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const digits = control.value.replace(/\D+/g, '');
    const requiredLength = RISK_ID_LENGTH_BY_STATE[stateCode] || 9;
    if (digits.length !== requiredLength || FEINPlaceholdersWithoutHyphen.includes(digits)) {
      return { invalidRiskId: { value: control.value } };
    }

    return null;
  };
};
