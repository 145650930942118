<section class="form-animation">
  <h1 class="form-header-below-link">Executive elections</h1>

  <!-- TODO - awaiting final copy for FAQ -->
  <!-- <div class="helper-card-container">
    <article class="faq-container helper-card">
      <h5 class="h5">Frequently asked questions</h5>
      <app-faqs-list [faqs]="[]"></app-faqs-list>
    </article>
  </div> -->

  <app-dialog-box type="info">
    Note: If the executive intends to adopt the default coverage for their state, no election is
    necessary.
  </app-dialog-box>

  <ng-container *ngIf="getExecutiveElections() as executiveElections">
    <div
      *ngIf="showExecutiveElectionsWarning"
      class="dialog-text dialog-text__warning dialog-text__inline"
    >
      <strong
        >Executive elections are dependent upon state and organization type, which were
        changed.</strong
      >
      Please verify that executive elections remain as intended, and add or remove as appropriate.
    </div>

    <section
      id="executives-{{ i }}"
      class="multi-form-item"
      *ngFor="let execElection of executiveElections.controls; let i = index"
    >
      <app-form-field-select
        inputId="executives-{{ i }}-location"
        [form]="execElection"
        [submitted]="submitted()"
        labelText="Location"
        nameOfFormControl="locationNum"
        [availableOptions]="locationSelectOptions"
      >
      </app-form-field-select>

      <!-- Show the remaining executive elections inputs only after location is selected. -->
      <ng-container *ngIf="locationSelected(execElection)">
        <div class="form-split">
          <app-form-field-text
            inputId="executives-{{ i }}-firstName"
            [form]="execElection"
            [submitted]="submitted()"
            labelText="First name"
            nameOfFormControl="firstName"
          >
          </app-form-field-text>

          <app-form-field-text
            inputId="executives-{{ i }}-lastName"
            [form]="execElection"
            [submitted]="submitted()"
            labelText="Last name"
            nameOfFormControl="lastName"
          >
          </app-form-field-text>
        </div>

        <div class="form-split">
          <app-form-field-select
            inputId="executives-{{ i }}-title"
            [form]="execElection"
            [submitted]="submitted()"
            labelText="Title"
            [availableOptions]="getTitleOptions(execElection)"
            nameOfFormControl="title"
          >
          </app-form-field-select>

          <app-form-field-text
            inputId="executives-{{ i }}-ownership"
            [form]="execElection"
            [submitted]="submitted()"
            labelText="Ownership %"
            nameOfFormControl="ownership"
            maxLength="3"
            appNumberMask
          >
          </app-form-field-text>
        </div>

        <app-form-field-radio
          *ngIf="execTitleSelected(execElection)"
          inputId="executives-{{ i }}-isIncluded"
          [form]="execElection"
          [submitted]="submitted()"
          [options]="{
            Excluded: false,
            Included: true
          }"
          questionText="Executive is..."
          nameOfFormControl="isIncluded"
        >
        </app-form-field-radio>

        <a
          (click)="navigateToLocationForExec(execElection)"
          (keyup.enter)="navigateToLocationForExec(execElection)"
          class="js-revise-location-payroll-link dialog-text-link"
          >Revise payroll if necessary</a
        >

        <ng-container *ngIf="submitted() && execElection?.errors?.invalidElection">
          <div class="validation">
            <div class="validation-message">
              {{ execElection?.errors?.invalidElection }}
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="nav-button-group">
        <a
          class="button button__small button__secondary js-remove-executive-button"
          (click)="removeExecutiveElection(i)"
          (keyup.enter)="removeExecutiveElection(i)"
        >
          Remove
        </a>
      </div>
    </section>

    <button
      id="add-executive"
      class="
        button button__secondary button__full-width button__form-margin
        js-add-executive-button
      "
      type="button"
      [ngClass]="{
        button__error: submitted() && executiveElections && executiveElections.errors
      }"
      (click)="addExecutiveElection()"
      (keyup.enter)="addExecutiveElection()"
    >
      Add executive election (optional)
    </button>

    <app-form-field-checkbox
      inputId="executive-election-attestation"
      [labelText]="execElectionAttestation"
      nameOfFormControl="attestation"
      [submitted]="submitted()"
      [form]="form"
    >
    </app-form-field-checkbox>
  </ng-container>
</section>
