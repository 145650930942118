import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { WcQuoteWithDocuments } from '../../workers-comp/employers/models/wc-priced-quote';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { ProducerDetailsResponse } from 'app/bop/guidewire/typings';
import { US_DATE_ALL_DIGIT_MASK } from 'app/constants';
import { AttuneBopFortegraEnabledStates } from 'app/shared/services/typings';

const bopV2LaunchDates: { [key: string]: string } = environment.bopV2LaunchDates || {};
export const nyMeTooLaunchDate = environment.nyMeTooLaunchDate || '';

export const disallowAccountEdits = (
  insuredAccount: InsuredAccount,
  quoteWithDocuments: WcQuoteWithDocuments | null
) => {
  const wcQuote = quoteWithDocuments ? quoteWithDocuments.quote : null;
  return insuredAccount.policiesWithTerms.length > 0 || (!!wcQuote && wcQuote.status === 'QUOTED');
};

export const shouldGetBopV2 = (
  state: string | null | undefined,
  policyEffectiveDate?: moment.Moment
) => {
  const dateToCompare = policyEffectiveDate ? policyEffectiveDate : moment();
  return (
    state && bopV2LaunchDates[state] && dateToCompare.isSameOrAfter(moment(bopV2LaunchDates[state]))
  );
};

export const shouldGetMeToo = (
  state: string | null | undefined,
  policyEffectiveDate?: moment.Moment
) => {
  if (!nyMeTooLaunchDate || state !== 'NY') {
    return false;
  }

  const nyGoForwardLaunchDate = bopV2LaunchDates['NY'];

  const dateToCompare = policyEffectiveDate ? policyEffectiveDate : moment();

  if (nyGoForwardLaunchDate && dateToCompare.isSameOrAfter(moment(nyGoForwardLaunchDate))) {
    return false;
  }

  return dateToCompare.isSameOrAfter(moment(nyMeTooLaunchDate));
};

export const shouldGetFortegra = (
  attuneBopFortegraEnabledStates: AttuneBopFortegraEnabledStates,
  state: string
) => {
  if ('releaseDates' in attuneBopFortegraEnabledStates) {
    const matchingReleaseDate = attuneBopFortegraEnabledStates.releaseDates.find(
      (release) => release.state === state
    );
    if (matchingReleaseDate) {
      // Explicitly specify the format of the date to avoid deprecation errors in the logs
      return (
        matchingReleaseDate &&
        moment(matchingReleaseDate.date, US_DATE_ALL_DIGIT_MASK).isSameOrBefore(moment.now())
      );
    }
  }
  return false;
};

export const getLaunchedStates = () => {
  return Object.keys(bopV2LaunchDates).filter((state) =>
    moment().isAfter(moment(bopV2LaunchDates[state]))
  );
};

export const getUpcomingStates = () => {
  return Object.keys(bopV2LaunchDates).filter(
    (state) => !moment().isAfter(moment(bopV2LaunchDates[state]))
  );
};

export interface AccountManager {
  name: string;
  wcBookBalanceLink: string;
  bopBookBalanceLink: string;
  states: string[];
}
export interface AccountManagerFlagResponse {
  accountManagers: AccountManager[];
}

export const getAccountManager = (
  producerDetails: ProducerDetailsResponse,
  accountManagersResponse: AccountManagerFlagResponse
): AccountManager | undefined => {
  const accountManagers = accountManagersResponse?.accountManagers || [];
  return accountManagers.find((accountManager) =>
    accountManager.states.includes(producerDetails?.State)
  );
};
