import { NKLL_TEMPLATE_URL } from 'app/features/attune-bop/models/constants';
import {
  addressNode,
  AddressNode,
  DivNode,
  FormDslNode,
  MultiInputNode,
  TextAreaNode,
  TextNode,
  FileUploadNode,
  ValueConditional,
  EvalConditional,
  DialogBoxNode,
  CheckboxNode,
  ValidationMessageNode,
} from 'app/shared/form-dsl/constants/form-dsl-typings';
import {
  ATTUNE_NEW_BUSINESS_APP,
  LOST_POLICY_RELEASE,
  BROKER_OF_RECORD_CHANGE,
  HELLO_SIGN_UPDATE_REFUND_BANK_INFO,
  ADDITIONAL_INSURED_TYPES,
} from './support-constants';
import { EvaluatorName, ValidatorName } from 'app/shared/form-dsl/constants/form-dsl-constants';
import { UntypedFormGroup } from '@angular/forms';
import { SimpleValidator } from 'app/features/attune-bop/models/form-validators';
import { EXTRA_DATA_FIELD_NAME } from 'app/shared/form-dsl/components/form-dsl-form/form-dsl-form.component';
import { US_STATES_DICT } from 'app/shared/services/us-state.service';
import { EMP_LIABILITY_LIMITS_OPTIONS } from 'app/workers-comp/attune/constants';

export const UNAUTHENTICATED_ROUTE = 'help-center';
export const AUTHENTICATED_ROUTE = 'support';

export const ZENDESK_HELP_CENTER_PROD = 'https://attunehelp.zendesk.com/hc/en-us/articles/';
export const ZENDESK_HELP_CENTER_SANDBOX =
  'https://attunehelp1562077002.zendesk.com/hc/en-us/articles/';

// Help Center Zendesk articles that are used in dialog boxes
export enum HelpCenterZendeskArticles {
  AccountBillingHistory = 'https://attunehelp.zendesk.com/hc/en-us/articles/360031889072-How-can-I-view-the-account-billing-history-and-schedule-of-invoices-for-my-businessowners-and-excess-policy-',
  MakePaymentBop = 'https://attunehelp.zendesk.com/hc/en-us/articles/4412569041179-How-can-I-pay-for-the-BOP-',
  EnrollAutopayBop = 'https://attunehelp.zendesk.com/hc/en-us/articles/4412560771483-How-can-I-enroll-in-autopay-for-BOP-',
  WcInvoices = 'https://attunehelp.zendesk.com/hc/en-us/articles/360032231171-How-can-I-obtain-a-copy-of-the-insured-s-WC-invoice-',
  MakePaymentWc = 'https://attunehelp.zendesk.com/hc/en-us/articles/4412576037147-How-can-I-pay-for-my-client-s-EMPLOYERS-policy-',
  EnrollAutopayWc = 'https://attunehelp.zendesk.com/hc/en-us/articles/360032612752-How-can-I-enroll-in-autopay-and-what-is-PrecisePay-',
}

// UI form fields that have a corresponding Zendesk form field
export enum HelpFormFieldNames {
  Account = 'accountNumber',
  AccountYNumber = 'accountYNumber',
  AddLocationReason = 'addLocationReason',
  AgencyContactPersonFirstName = 'agencyContactPersonFirstName',
  AgencyContactPersonLastName = 'agencyContactPersonLastName',
  AgencyContactPersonPhoneNumber = 'agencyContactPersonPhoneNumber',
  AgencyContactPersonEmail = 'agencyContactPersonEmail',
  AgencyMailingAddress = 'agencyMailingAddress',
  AgencyManagementSystem = 'agencyManagementSystem',
  AgencyManagementSystemVersion = 'agencyManagementSystemVersion',
  AgencyName = 'agencyName',
  AgencyPhysicalAddress = 'agencyPhysicalAddress',
  AgencyPhoneNumber = 'agencyPhoneNumber',
  AiAddress = 'aiAddress',
  AiUpdatedAddress = 'aiUpdatedAddress',
  AiAddOrUpdate = 'aiAddOrUpdate',
  AiName = 'aiName',
  AiIdToUpdate = 'aiIdToUpdate',
  AiNameToUpdate = 'aiNameToUpdate',
  AiUpdateName = 'aiUpdateName',
  AiUpdateAddress = 'aiUpdateAddress',
  AiUpdateType = 'aiUpdateType',
  AiUpdateBuilding = 'aiUpdateBuilding',
  AiUpdateLocation = 'aiUpdateLocation',
  AiInsurableInterest = 'aiInsurableInterest',
  AiLeasedEquipment = 'aiLeasedEquipment',
  AiParagraphAbc = 'aiParagraphAbc',
  AiCompletedOperations = 'aiCompletedOperations',
  AiVendorProducts = 'aiVendorProducts',
  AiType = 'aiType',
  AiBuilding = 'aiBuilding',
  AiLocation = 'aiLocation',
  AmountReceived = 'amountReceived',
  AnnualRevenue = 'annualRevenue',
  AnnualPayroll = 'annualPayroll',
  AutomaticSprinklers = 'automaticSprinklers',
  BuildingPersonalPropertyLimitOwner = 'buildingPersonalPropertyLimitOwner',
  BuildingPersonalPropertyLimitTenant = 'buildingPersonalPropertyLimitTenant',
  BuildingLimit = 'buildingLimit',
  BusinessAddress = 'businessAddress',
  BusinessMailingAddress = 'businessMailingAddress',
  CancellationDate = 'cancellationEffectiveDate',
  CancellationPolicyType = 'cancellationPolicyType',
  CancellationReason = 'cancellationReason',
  CancellationReasonOther = 'cancellationReasonOther',
  ChangeCoverageAddDescription = 'changeCoverageAddDescription',
  ChangeCoverageAddRemove = 'changeCoverageAddRemove',
  ChangeCoverageAddress = 'changeCoverageAddress',
  ChangeCoverageBlanketLimit = 'changeCoverageBlanketLimit',
  ChangeCoverageBppLimit = 'changeCoverageBppLimit',
  ChangeCoverageBuildingLimit = 'changeCoverageBuildingLimit',
  ChangeCoverageEquipmentList = 'changeCoverageEquipmentList',
  ChangeCoverageFullyInsured = 'changeCoverageFullyInsured',
  ChangeCoverageInsuredContractor = 'changeCoverageInsuredContractor',
  ChangeCoverageItemSublimit = 'changeCoverageItemSublimit',
  ChangeCoverageInsuredUpdates = 'changeCoverageInsuredUpdates',
  ChangeCoverageLiabilityLimit = 'changeCoverageLiabilityLimit',
  ChangeCoverageReason = 'changeCoverageReason',
  ChangeCoverageRemoveType = 'changeCoverageRemoveType',
  ChangeCoverageScheduleApply = 'changeCoverageScheduleApply',
  ChangeCoverageType = 'changeCoverageType',
  ChangeCoverageTypeWc = 'changeCoverageTypeWc',
  ChangeCoverageUpdateDeductible = 'changeCoverageUpdateDeductible',
  ChangeCoverageUpdateLimit = 'changeCoverageUpdateLimit',
  ChangeCoverageUpdateType = 'changeCoverageUpdateType',
  ChangeInOperation = 'changeInOperation',
  CheckMailingAddress = 'checkMailingAddress',
  ClericalError = 'clericalError',
  ConstructionType = 'constructionType',
  Dba = 'dba',
  DeclineReason = 'declineReason',
  DescriptionOfChange = 'descriptionOfChange',
  EffectiveDate = 'effectiveDate',
  ElectricPlumbingHvacUpdated = 'electricPlumbingHvacUpdated',
  EmployeesPerLocation = 'employeesPerLocation',
  ExposuresProjectedSales = 'exposuresProjectedSales',
  ExposuresPayroll = 'exposuresPayroll',
  ExposuresBusinessType = 'exposuresBusinessType',
  ExposuresSalesLessThanProjected = 'exposuresSalesLessThanProjected',
  ExposuresPayrollDecreasing = 'exposuresPayrollDecreasing',
  InsuredEmail = 'insuredEmail',
  InsuredState = 'insuredState',
  Interest = 'interest',
  LineOfBusiness = 'lineOfBusiness',
  LocationAddOrUpdate = 'locationAddOrUpdate',
  LocationOfCoveredOps = 'locationOfCoveredOps',
  MailingAddress = 'mailingAddress',
  MeansOfEgress = 'meansOfEgress',
  MeetsEligibilityGuidelines = 'meetsEligibilityGuidelines',
  NumberOfEmployees = 'numberOfEmployees',
  NumberOfStories = 'numberOfStories',
  NamedInsuredChangeType = 'namedInsuredChangeType',
  NamedInsuredAdditionalName = 'namedInsuredAdditionalName',
  NamedInsuredAdditionalAddress = 'namedInsuredAdditionalAddress',
  NamedInsuredAdditionalFein = 'namedInsuredAdditionalFein',
  NamedInsuredAdditionalOperations = 'namedInsuredAdditionalOperations',
  NamedInsuredAdditionalCommonOwnership = 'namedInsuredAdditionalCommonOwnership',
  NamedInsuredPrimaryName = 'namedInsuredPrimaryName',
  NamedInsuredPrimaryAddress = 'namedInsuredPrimaryAddress',
  NamedInsuredExplanation = 'namedInsuredExplanation',
  NamedInsuredChangeInOperation = 'namedInsuredChangeInOperation',
  NamedInsuredChangeInOwnershipWc = 'namedInsuredChangeInOwnershipWc',
  NamedInsuredChangeEMod = 'namedInsuredChangeEMod',
  NamedInsuredChangeFein = 'namedInsuredChangeFein',
  NamedInsuredChangeDescription = 'namedInsuredChangeDescription',
  NewEffectiveDate = 'newEffectiveDate',
  NewEffectiveDateReason = 'newEffectiveDateReason',
  PaymentPlan = 'paymentPlan',
  Policy = 'policyNumber',
  ProducerCode = 'producerCode',
  RoofUpdate = 'roofUpdate',
  RemoveAddress = 'removeAddress',
  RemoveLocationReason = 'removeLocationReason',
  SplitPPC = 'splitPPC',
  SquareFootage = 'squareFootage',
  StatementDate = 'statementDate',
  UpdateLocationOldAddress = 'updateLocationOldAddress',
  UpdateLocationNewAddress = 'updateLocationNewAddress',
  UpdateLocationReason = 'updateLocationReason',
  UserId = 'userId',
  QuoteNumber = 'quoteNumber',
  WosAddress = 'wosAddress',
  WosDescription = 'wosDescription',
  WosName = 'wosName',
  WosNameRequired = 'wosNameRequired', // Separate field name needed due to how conditional rendering logic behaves
  WosType = 'wosType',
  YearBuilt = 'yearBuilt',
}

// UI form fields that do not have a corresponding form field in Zendesk
// Note that any non-file fields in this category must be manually excluded from uploadFieldNames
export enum UIFormFieldNames {
  BorDocuments = 'borDocuments',
  LocationDocuments = 'locationDocuments',
  LprDocuments = 'lprDocuments',
  LprAndBusinessClosedDocuments = 'lprAndBusinessClosedDocuments',
  LprAndBusinessSoldDocuments = 'lprAndBusinessSoldDocuments',
  LprAndCoverageReplacedDocuments = 'lprAndCoverageReplacedDocuments',
  LroDocuments = 'lorDocuments',
  NkllDocuments = 'nkllDocuments',
  AddAiNkllDocuments = 'aiAddNkllDocuments',
  UpdateDeductibleNkllDocuments = 'updateDeductibleNkllDocuments',
  PolicyValidationMessage = 'policyValidationMessage',
  SupplementaryDocuments = 'supplementaryDocuments',
  RequestPaymentScheduleBopTip = 'requestPaymentScheduleBopTip',
  RequestInvoiceWcTip = 'requestInvoiceWcTip',
  MakePaymentBopTip = 'makePaymentBopTip',
  MakePaymentWcTip = 'makePaymentWcTip',
  ConfirmPaymentBopTip = 'confirmPaymentBopTip',
  EnrollAutopayBopTip = 'enrollAutopayBopTip',
  EnrollAutopayWcTip = 'enrollAutopayWcTip',
  HiscoxSubmitTip = 'hiscoxSubmitTip',
  MultipleRequestTip = 'multipleRequestTip',
  ExposuresTaxReturns = 'exposuresTaxReturns',
  ExposuresNewPayrollConfirmation = 'exposuresNewPayrollConfirmation',
  ProofOfBindRequest = 'proofOfBindRequest',
  NamedInsuredChangeEModChecked = 'namedInsuredChangeEModChecked',
  NamedInsuredChangeFeinChecked = 'namedInsuredChangeFeinChecked',
  NamedInsuredChangeOwnerChecked = 'namedInsuredChangeOwnerChecked',
  WcErm14Form = 'wcErm14Form',
  WcErm14FormMi = 'wcErm14FormMi',
  WcErm14FormPa = 'wcErm14FormPa',
}

/**
 * Tells the component how to format the form control input before sending to Zendesk, as follows:
 * ADDRESS => Format an address group into a single string
 * DATE => Format a date input into YYYY-MM-DD
 * DEFAULT => Send input value to ZD as is
 */
export const fieldFormats: {
  [key in HelpFormFieldNames]: 'ADDRESS' | 'ADDRESS_GROUP' | 'DATE' | 'DEFAULT';
} = {
  [HelpFormFieldNames.AiAddress]: 'ADDRESS_GROUP',
  [HelpFormFieldNames.AiUpdatedAddress]: 'ADDRESS_GROUP',
  [HelpFormFieldNames.BusinessAddress]: 'ADDRESS',
  [HelpFormFieldNames.BusinessMailingAddress]: 'ADDRESS_GROUP',
  [HelpFormFieldNames.CheckMailingAddress]: 'ADDRESS',
  [HelpFormFieldNames.MailingAddress]: 'ADDRESS',
  [HelpFormFieldNames.CancellationDate]: 'DATE',
  [HelpFormFieldNames.EffectiveDate]: 'DATE',
  [HelpFormFieldNames.StatementDate]: 'DATE',
  [HelpFormFieldNames.AgencyContactPersonFirstName]: 'DEFAULT',
  [HelpFormFieldNames.AgencyContactPersonLastName]: 'DEFAULT',
  [HelpFormFieldNames.AgencyContactPersonPhoneNumber]: 'DEFAULT',
  [HelpFormFieldNames.AgencyContactPersonEmail]: 'DEFAULT',
  [HelpFormFieldNames.AgencyMailingAddress]: 'ADDRESS',
  [HelpFormFieldNames.AgencyManagementSystem]: 'DEFAULT',
  [HelpFormFieldNames.AgencyManagementSystemVersion]: 'DEFAULT',
  [HelpFormFieldNames.AgencyName]: 'DEFAULT',
  [HelpFormFieldNames.AgencyPhoneNumber]: 'DEFAULT',
  [HelpFormFieldNames.AgencyPhysicalAddress]: 'ADDRESS',
  [HelpFormFieldNames.Account]: 'DEFAULT',
  [HelpFormFieldNames.AccountYNumber]: 'DEFAULT',
  [HelpFormFieldNames.AddLocationReason]: 'DEFAULT',
  [HelpFormFieldNames.AiAddOrUpdate]: 'DEFAULT',
  [HelpFormFieldNames.AiName]: 'DEFAULT',
  [HelpFormFieldNames.AiIdToUpdate]: 'DEFAULT',
  [HelpFormFieldNames.AiNameToUpdate]: 'DEFAULT',
  [HelpFormFieldNames.AiUpdateName]: 'DEFAULT',
  [HelpFormFieldNames.AiUpdateAddress]: 'ADDRESS',
  [HelpFormFieldNames.AiUpdateType]: 'DEFAULT',
  [HelpFormFieldNames.AiUpdateBuilding]: 'DEFAULT',
  [HelpFormFieldNames.AiUpdateLocation]: 'DEFAULT',
  [HelpFormFieldNames.AiInsurableInterest]: 'DEFAULT',
  [HelpFormFieldNames.AiLeasedEquipment]: 'DEFAULT',
  [HelpFormFieldNames.AiParagraphAbc]: 'DEFAULT',
  [HelpFormFieldNames.AiCompletedOperations]: 'DEFAULT',
  [HelpFormFieldNames.AiVendorProducts]: 'DEFAULT',
  [HelpFormFieldNames.AiType]: 'DEFAULT',
  [HelpFormFieldNames.AiBuilding]: 'DEFAULT',
  [HelpFormFieldNames.AiLocation]: 'DEFAULT',
  [HelpFormFieldNames.AmountReceived]: 'DEFAULT',
  [HelpFormFieldNames.CancellationPolicyType]: 'DEFAULT',
  [HelpFormFieldNames.AnnualRevenue]: 'DEFAULT',
  [HelpFormFieldNames.AnnualPayroll]: 'DEFAULT',
  [HelpFormFieldNames.AutomaticSprinklers]: 'DEFAULT',
  [HelpFormFieldNames.BuildingPersonalPropertyLimitOwner]: 'DEFAULT',
  [HelpFormFieldNames.BuildingPersonalPropertyLimitTenant]: 'DEFAULT',
  [HelpFormFieldNames.BuildingLimit]: 'DEFAULT',
  [HelpFormFieldNames.CancellationReason]: 'DEFAULT',
  [HelpFormFieldNames.CancellationReasonOther]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageAddDescription]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageAddRemove]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageAddress]: 'ADDRESS',
  [HelpFormFieldNames.ChangeCoverageBlanketLimit]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageBppLimit]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageBuildingLimit]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageEquipmentList]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageFullyInsured]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageInsuredContractor]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageInsuredUpdates]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageItemSublimit]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageLiabilityLimit]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageReason]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageRemoveType]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageScheduleApply]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageType]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageTypeWc]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageUpdateDeductible]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageUpdateLimit]: 'DEFAULT',
  [HelpFormFieldNames.ChangeCoverageUpdateType]: 'DEFAULT',
  [HelpFormFieldNames.ChangeInOperation]: 'DEFAULT',
  [HelpFormFieldNames.ClericalError]: 'DEFAULT',
  [HelpFormFieldNames.ConstructionType]: 'DEFAULT',
  [HelpFormFieldNames.Dba]: 'DEFAULT',
  [HelpFormFieldNames.DeclineReason]: 'DEFAULT',
  [HelpFormFieldNames.DescriptionOfChange]: 'DEFAULT',
  [HelpFormFieldNames.ElectricPlumbingHvacUpdated]: 'DEFAULT',
  [HelpFormFieldNames.ExposuresProjectedSales]: 'DEFAULT',
  [HelpFormFieldNames.ExposuresPayroll]: 'DEFAULT',
  [HelpFormFieldNames.ExposuresBusinessType]: 'DEFAULT',
  [HelpFormFieldNames.ExposuresSalesLessThanProjected]: 'DEFAULT',
  [HelpFormFieldNames.ExposuresPayrollDecreasing]: 'DEFAULT',
  [HelpFormFieldNames.InsuredEmail]: 'DEFAULT',
  [HelpFormFieldNames.InsuredState]: 'DEFAULT',
  [HelpFormFieldNames.Interest]: 'DEFAULT',
  [HelpFormFieldNames.LineOfBusiness]: 'DEFAULT',
  [HelpFormFieldNames.LocationAddOrUpdate]: 'DEFAULT',
  [HelpFormFieldNames.LocationOfCoveredOps]: 'DEFAULT',
  [HelpFormFieldNames.MeansOfEgress]: 'DEFAULT',
  [HelpFormFieldNames.MeetsEligibilityGuidelines]: 'DEFAULT',
  [HelpFormFieldNames.NamedInsuredAdditionalName]: 'DEFAULT',
  [HelpFormFieldNames.NamedInsuredAdditionalAddress]: 'ADDRESS_GROUP',
  [HelpFormFieldNames.NamedInsuredAdditionalFein]: 'DEFAULT',
  [HelpFormFieldNames.NamedInsuredAdditionalOperations]: 'DEFAULT',
  [HelpFormFieldNames.NamedInsuredAdditionalCommonOwnership]: 'DEFAULT',
  [HelpFormFieldNames.NamedInsuredPrimaryName]: 'DEFAULT',
  [HelpFormFieldNames.NamedInsuredPrimaryAddress]: 'ADDRESS_GROUP',
  [HelpFormFieldNames.NamedInsuredChangeType]: 'DEFAULT',
  [HelpFormFieldNames.NamedInsuredExplanation]: 'DEFAULT',
  [HelpFormFieldNames.NamedInsuredChangeInOperation]: 'DEFAULT',
  [HelpFormFieldNames.NamedInsuredChangeInOwnershipWc]: 'DEFAULT',
  [HelpFormFieldNames.NamedInsuredChangeEMod]: 'DEFAULT',
  [HelpFormFieldNames.NamedInsuredChangeFein]: 'DEFAULT',
  [HelpFormFieldNames.NamedInsuredChangeDescription]: 'DEFAULT',
  [HelpFormFieldNames.NewEffectiveDate]: 'DATE',
  [HelpFormFieldNames.NewEffectiveDateReason]: 'DEFAULT',
  [HelpFormFieldNames.NumberOfEmployees]: 'DEFAULT',
  [HelpFormFieldNames.NumberOfStories]: 'DEFAULT',
  [HelpFormFieldNames.EmployeesPerLocation]: 'DEFAULT',
  [HelpFormFieldNames.PaymentPlan]: 'DEFAULT',
  [HelpFormFieldNames.Policy]: 'DEFAULT',
  [HelpFormFieldNames.ProducerCode]: 'DEFAULT',
  [HelpFormFieldNames.RoofUpdate]: 'DEFAULT',
  [HelpFormFieldNames.RemoveAddress]: 'ADDRESS',
  [HelpFormFieldNames.RemoveLocationReason]: 'DEFAULT',
  [HelpFormFieldNames.SplitPPC]: 'DEFAULT',
  [HelpFormFieldNames.SquareFootage]: 'DEFAULT',
  [HelpFormFieldNames.UpdateLocationOldAddress]: 'ADDRESS',
  [HelpFormFieldNames.UpdateLocationNewAddress]: 'ADDRESS',
  [HelpFormFieldNames.UpdateLocationReason]: 'DEFAULT',
  [HelpFormFieldNames.UserId]: 'DEFAULT',
  [HelpFormFieldNames.QuoteNumber]: 'DEFAULT',
  [HelpFormFieldNames.WosAddress]: 'ADDRESS_GROUP',
  [HelpFormFieldNames.WosDescription]: 'DEFAULT',
  [HelpFormFieldNames.WosName]: 'DEFAULT',
  [HelpFormFieldNames.WosNameRequired]: 'DEFAULT',
  [HelpFormFieldNames.WosType]: 'DEFAULT',
  [HelpFormFieldNames.YearBuilt]: 'DEFAULT',
};

// Validator for policy number, which depends on a sibling line of business field being present
// If no such field is present, it will default to permitting any known policy number format
export const policyNumberBusinessValidator = (group: UntypedFormGroup) => {
  const policyNumberFormats: Record<string, RegExp> = {
    // First format is for BOP 1.0, second is for BOP+
    bop: /(^[a-z0-9]+-[0-9]{5}(-[0-9]{2})?$)|(^[a-z0-9]{17})$/gi,
    general_liability: /^([A-Z]{3})?[0-9]{7,8}$/gi,
    professional_liability: /^([A-Z]{3})?[0-9]{7,8}$/gi,
    // end of line ($) missing because we may have a policy term appended to the id
    cyber_liability: /^C-[a-zA-Z0-9]{4}-[0-9]{6}-CYBER-20[0-9]{2}/gi,
    // The EIG- prefix and -00/0N suffix are hidden in the portal, but EMPLOYERS uses them
    wc: /(^(EIG)?[0-9]{7}[-\s]?([\d]{2})?$)|(^WCV( )?[0-9]+( )?[0-9]+$)/gi,
    attune_work_comp: /^WCV\s*[0-9]{7}\s*[0-9]{2}\s*$/gi,
    xs: /^[0-9]{7}$/gi,
  };

  let currentLine = '';
  const lineOfBusinessInput = group.get(HelpFormFieldNames.LineOfBusiness);
  if (lineOfBusinessInput) {
    currentLine = lineOfBusinessInput.value;
  }

  let policyNumber = '';
  const policyNumberInput = group.get(HelpFormFieldNames.Policy);
  if (policyNumberInput) {
    policyNumber = policyNumberInput.value;
  }
  if (!policyNumber || !currentLine) {
    return null;
  }

  let isValid = false;
  const matchingFormat = policyNumberFormats[currentLine];
  if (matchingFormat) {
    isValid = matchingFormat.exec(policyNumber.trim()) !== null;
  } else {
    isValid = Object.values(policyNumberFormats).some(
      (regexTest) => regexTest.exec(policyNumber.trim()) !== null
    );
  }

  if (!isValid) {
    return {
      policyNumberFormat: 'This policy number does not have the expected format',
    };
  } else {
    return null;
  }
};

export const uploadFieldNames: UIFormFieldNames[] = Object.values(UIFormFieldNames).filter(
  (field) => {
    // If this list expands we may want to refactor UIFormFieldNames into separate lists of upload and non-upload fields
    const nonUploadFields = [
      UIFormFieldNames.PolicyValidationMessage,
      UIFormFieldNames.RequestPaymentScheduleBopTip,
      UIFormFieldNames.RequestInvoiceWcTip,
      UIFormFieldNames.MakePaymentBopTip,
      UIFormFieldNames.MakePaymentWcTip,
      UIFormFieldNames.ConfirmPaymentBopTip,
      UIFormFieldNames.EnrollAutopayBopTip,
      UIFormFieldNames.EnrollAutopayWcTip,
      UIFormFieldNames.HiscoxSubmitTip,
      UIFormFieldNames.MultipleRequestTip,
      UIFormFieldNames.NamedInsuredChangeEModChecked,
      UIFormFieldNames.NamedInsuredChangeFeinChecked,
      UIFormFieldNames.NamedInsuredChangeOwnerChecked,
    ];
    return !nonUploadFields.includes(field);
  }
);

export enum HelpFormSlug {
  AccessAccount = 'account-access',
  AddBuilding = 'add-building',
  AdditionalInsured = 'additional-insured',
  AddWaiver = 'waiver-of-subrogation',
  AssistClass = 'classification',
  AssistEligibility = 'eligibility',
  AssistGuidelines = 'appetite-guidelines',
  AssistLRO = 'lro',
  AutopayStatus = 'autopay-status',
  BindQuote = 'bind-quote',
  BindCpspQuote = 'bind-cpsp-quote',
  BindBlockedQuote = 'bind-blocked-quote',
  CantLogin = 'login-help',
  CantQuoteOrBind = 'quote-bind-help',
  ChangeAutopay = 'change-autopay',
  ChangeCoverage = 'change-coverage',
  ChangePayment = 'change-payment-plan',
  ChangeQuote = 'change-quote',
  ChangeEffectiveDate = 'change-effective-date',
  ConfirmCancel = 'confirm-cancellation',
  ConfirmEligibility = 'confirm-eligibility',
  ConfirmNKLL = 'confirm-nkll',
  ConfirmPayment = 'confirm-payment',
  ConfirmReceipt = 'confirm-receipt',
  ConfirmRefund = 'confirm-refund',
  ConfirmReinstatement = 'confirm-reinstatement',
  DisputeCancel = 'dispute-cancellation',
  EditRenewal = 'edit-renewal',
  EnrollAutopay = 'enroll-autopay',
  GetRefund = 'get-refund',
  GiveFeedback = 'feedback',
  InquireCoverage = 'coverage-question',
  InsuredEmail = 'insured-email',
  Location = 'update-location',
  MakePayment = 'make-payment',
  MakeReinstatement = 'reinstatement-payment',
  NamedInsured = 'named-insured',
  Other = 'other',
  OtherAgency = 'other-agency',
  OtherAutopay = 'other-autopay',
  OtherCancel = 'other-cancel',
  OtherCommission = 'other-commission',
  OtherBilling = 'other-billing',
  OtherDocuments = 'other-documents',
  OtherEligibility = 'other-eligibility',
  OtherEndorsement = 'other-endorsement',
  OtherFeedback = 'other-feedback',
  OtherInsured = 'other-insured',
  OtherPayment = 'other-payment',
  OtherPolicy = 'other-policy',
  OtherPortal = 'other-portal',
  OtherQuote = 'other-quote',
  OtherRefund = 'other-refund',
  OtherReinstatement = 'other-reinstatement',
  OtherRenewal = 'other-renewal',
  OtherTechSupport = 'other-tech',
  PaymentInstructions = 'payment-instructions',
  RemoveAccess = 'remove-access',
  RequestAppt = 'request-appointment',
  RequestBOR = 'request-bor',
  RequestCancel = 'request-cancellation',
  RequestCOI = 'request-coi',
  RequestCommission = 'request-commission',
  RequestCredentials = 'request-credentials',
  RequestDocuments = 'request-documents',
  RequestFeature = 'request-feature',
  RequestForms = 'request-forms',
  RequestInvoice = 'request-invoice',
  RequestIvans = 'request-ivans',
  NewIvans = 'new-ivans',
  RequestLR = 'request-lrs-policy',
  RequestLRDocs = 'request-lrs-docs',
  RequestQuote = 'request-quote-letter',
  RequestReinstatement = 'request-reinstatement',
  RequestRenewal = 'request-renewal',
  RequestPayment = 'request-schedule',
  RequestReview = 'request-review',
  ResetPassword = 'reset-password',
  ResolveQuoteContingency = 'resolve-contingency',
  UnenrollAutopay = 'unenroll-autopay',
  UpdateAccount = 'update-account',
  UpdateAddress = 'update-address',
  UpdateBusiness = 'update-business',
  UpdateEmail = 'update-email',
  UpdateSalesPayroll = 'update-sales-payroll',
  UpdateInfo = 'update-information',
  UpdateComBank = 'commission-update-banking',
  UpdateRefundBank = 'refund-update-banking',
}

// Form DSL field configs

// Form DSL field configs - factory helpers
const dateNode = ({
  nameOfFormControl,
  labelText,
  inputId = nameOfFormControl,
  required = false,
  specifierText = '',
  errorText = 'Please enter a valid date',
  validators = [],
}: {
  nameOfFormControl: HelpFormFieldNames;
  labelText: string;
  inputId?: HelpFormFieldNames;
  required?: boolean;
  specifierText?: string;
  errorText?: string;
  validators?: ValidatorName[];
}): TextNode => {
  return {
    primitive: 'DATE',
    nameOfFormControl,
    inputId,
    inputType: 'number',
    labelText,
    specifierText,
    showErrorText: !!errorText,
    errorText,
    required,
    validators: [ValidatorName.VALID_DATE, ...validators],
  };
};

const fileUploadNode = ({
  nameOfFormControl,
  labelText,
  inputId = nameOfFormControl,
  required = false,
  specifierText = '',
  accept = '.pdf,.doc,.docx,.csv,.txt,.xls,.xlsx,.eml,image/*',
  validators = [ValidatorName.UPLOADS_SUCCESSFUL],
}: {
  nameOfFormControl: UIFormFieldNames;
  labelText: string;
  inputId?: UIFormFieldNames;
  required?: boolean;
  specifierText?: string;
  accept?: string;
  validators?: ValidatorName[];
}): FileUploadNode => {
  return {
    primitive: 'FILE_UPLOAD',
    inputId,
    nameOfFormControl,
    labelText,
    isArray: true,
    values: [],
    specifierText,
    required,
    fileMetadata: {},
    accept,
    validators,
  };
};
// END Form DSL field configs - factory helpers

// NOTE: All VALUES of radio components must match the 'tag' value of that field in Zendesk in order for the field to populate successfully.
//       All KEYS of select components must match the 'tag' value of that field in Zendesk in order for the field to populate successfully.
const accountField: TextNode = {
  primitive: 'NUMBER',
  nameOfFormControl: HelpFormFieldNames.Account,
  inputId: HelpFormFieldNames.Account,
  labelText: 'Account number',
  specifierText: '(optional)',
  required: false,
};

const accountRequiredField: TextNode = {
  primitive: 'NUMBER',
  nameOfFormControl: HelpFormFieldNames.Account,
  inputId: HelpFormFieldNames.Account,
  labelText: 'Account number',
  required: true,
};

const userIdField: TextNode = {
  primitive: 'TEXT',
  nameOfFormControl: HelpFormFieldNames.UserId,
  inputId: HelpFormFieldNames.UserId,
  inputType: 'text',
  labelText: 'IVANS Mailbox ID',
  questionNote: 'Needed to download policy information to your AMS via your IVANS mailbox.',
  validators: [ValidatorName.ALPHANUMERIC],
  required: true,
};

const accountYNumberField: TextNode = {
  primitive: 'TEXT',
  nameOfFormControl: HelpFormFieldNames.AccountYNumber,
  inputId: HelpFormFieldNames.AccountYNumber,
  inputType: 'text',
  labelText: 'Y Account Number',
  questionNote: 'Needed to download policy information to your AMS via your IVANS mailbox.',
  validators: [ValidatorName.ALPHANUMERIC],
  required: true,
};

const agencyNameField: TextNode = {
  primitive: 'TEXT',
  nameOfFormControl: HelpFormFieldNames.AgencyName,
  inputId: HelpFormFieldNames.AgencyName,
  inputType: 'text',
  labelText: 'Agency Name',
  validators: [ValidatorName.ALPHANUMERIC],
  required: true,
};

const agencyPhoneNumberField: TextNode = {
  primitive: 'TEXT',
  nameOfFormControl: HelpFormFieldNames.AgencyPhoneNumber,
  inputId: HelpFormFieldNames.AgencyPhoneNumber,
  inputType: 'text',
  labelText: 'Agency Phone Number',
  errorText: 'Please enter a valid phone number',
  validators: [ValidatorName.VALID_PHONE_NUMBER],
  required: true,
};

const agencyContactPersonFirstNameField: TextNode = {
  primitive: 'TEXT',
  nameOfFormControl: HelpFormFieldNames.AgencyContactPersonFirstName,
  inputId: HelpFormFieldNames.AgencyContactPersonFirstName,
  inputType: 'text',
  labelText: 'Agency Contact Person',
  placeholder: 'First Name',
  required: true,
  cssClass: 'agency-contact-field',
};

const agencyContactPersonLastNameField: TextNode = {
  primitive: 'TEXT',
  nameOfFormControl: HelpFormFieldNames.AgencyContactPersonLastName,
  inputId: HelpFormFieldNames.AgencyContactPersonLastName,
  inputType: 'text',
  labelText: '',
  placeholder: 'Last Name',
  required: true,
  cssClass: 'agency-contact-field',
};

const agencyContactPersonPhoneNumberField: TextNode = {
  primitive: 'TEXT',
  nameOfFormControl: HelpFormFieldNames.AgencyContactPersonPhoneNumber,
  inputId: HelpFormFieldNames.AgencyContactPersonPhoneNumber,
  inputType: 'text',
  labelText: '',
  placeholder: 'Phone Number',
  errorText: 'Please enter a valid phone number',
  validators: [ValidatorName.VALID_PHONE_NUMBER],
  required: true,
};

const agencyContactPersonEmailField: TextNode = {
  primitive: 'TEXT',
  nameOfFormControl: HelpFormFieldNames.AgencyContactPersonEmail,
  inputId: HelpFormFieldNames.AgencyContactPersonEmail,
  inputType: 'text',
  labelText: '',
  placeholder: 'Email',
  errorText: 'Please enter a valid email address.',
  validators: [ValidatorName.VALID_EMAIL],
  required: true,
};

const agencyContactPersonFields: DivNode = {
  primitive: 'DIV',
  children: [agencyContactPersonFirstNameField, agencyContactPersonLastNameField],
  cssClass: 'form-row',
};

const agencyManagementSystemField: MultiInputNode = {
  primitive: 'SELECT',
  inputId: HelpFormFieldNames.AgencyManagementSystem,
  nameOfFormControl: HelpFormFieldNames.AgencyManagementSystem,
  labelText: 'Agency Management System',
  options: {
    'agencypro_-_agency_software': 'AgencyPro - Agency Software',
    agency_automationteam: 'Agency AutomationTeam',
    agentdesk: 'AgentDesk',
    applied_epic: 'Applied Epic',
    applied_epic_for_salesforce: 'Applied Epic for Salesforce',
    better_agency: 'Better Agency',
    cote_software___solutions: 'CoTe Software & Solutions',
    ebixasp: 'EbixASP',
    'edi_concepts_-_instar': 'EDI Concepts - INSTAR',
    'ezagent-agency_software': 'EZAgent-Agency Software',
    'easyapps_pro_-_agency_software': 'Easyapps Pro - Agency Software',
    evo: 'eVo',
    ezlynx: 'EZLynx',
    hawksoft: 'Hawksoft',
    instar: 'InStar',
    agency_matrix: 'ITC',
    jenesisclassic: 'JenesisClassic',
    jenesisnow: 'JenesisNow',
    'jenesis_-_agency_advantage': 'Jenesis - Agency Advantage',
    keepthemsmiling: 'Keepthemsmiling',
    nasa_eclipse: 'NASA Eclipse',
    newton: 'Newton',
    nexsure: 'Nexsure',
    nexus_linx: 'Nexus Linx',
    novidea: 'Novidea',
    nowcerts_momentum: 'NowCerts Momentum',
    powermanage_from_quomation: 'PowerManage from Quomation',
    quikfuzion_amp: 'QuikFuzion AMP',
    record_guardian: 'Record Guardian',
    'partner_platform_-_sis': 'Partner Platform - SIS',
    seigeaos: 'SeigeAOS',
    sentry: 'Sentry',
    special_agent: 'Special Agent',
    vertafore_ams360: 'Vertafore AMS360',
    vertafore_qqcatalyst: 'Vertafore QQCatalyst',
    vertafore_sagitta: 'Vertafore Sagitta',
    veruna: 'Veruna',
    xanatek: 'Xanatek',
    other_: 'Other (please specify in the Message field)',
  },
  required: true,
  cssClass: 'agency-system-field',
};

const agencyManagementSystemVersionField: TextNode = {
  primitive: 'TEXT',
  nameOfFormControl: HelpFormFieldNames.AgencyManagementSystemVersion,
  inputId: HelpFormFieldNames.AgencyManagementSystemVersion,
  inputType: 'text',
  labelText: 'Version',
  specifierText: '(optional)',
  errorText: 'Please enter a valid version number',
  cssClass: 'agency-system-version-field',
};

const agencyManagementSystemFields: DivNode = {
  primitive: 'DIV',
  children: [agencyManagementSystemField, agencyManagementSystemVersionField],
  cssClass: 'form-row',
};

const lineOfBusinessField: MultiInputNode = {
  primitive: 'SELECT',
  nameOfFormControl: HelpFormFieldNames.LineOfBusiness,
  inputId: HelpFormFieldNames.LineOfBusiness,
  options: {
    'businessowners____excess__accredited__i.e._bop_':
      "Businessowners' & Excess (Accredited, i.e. BOP+)",
    wc: 'Workers Comp (EMPLOYERS)',
    general_liability: 'General Liability',
    professional_liability: 'Professional Liability',
    flood: 'Flood',
    cyber_liability: 'Cyber Liability',
    bop: "Businessowners' & Excess (Blackboard)",
    core_xs_speciality: 'Core Specialty XS',
    businessowners____excess__liberty_mutual_: "Businessowners' & Excess (Liberty Mutual)",
    custom_protector__liberty_mutual_: 'Custom Protector (Liberty Mutual)',
    'n/a_product': "My question isn't product specific",
  },
  labelText: 'Line of Business',
  required: false,
};

const policyField: TextNode = {
  primitive: 'TEXT',
  nameOfFormControl: HelpFormFieldNames.Policy,
  inputId: HelpFormFieldNames.Policy,
  inputType: 'text',
  labelText: 'Policy number',
  specifierText: '(optional)',
  required: false,
};

const producerCodeField: TextNode = {
  primitive: 'TEXT',
  nameOfFormControl: HelpFormFieldNames.ProducerCode,
  inputId: HelpFormFieldNames.ProducerCode,
  inputType: 'text',
  labelText: 'Producer code',
  validators: [ValidatorName.ALPHANUMERIC],
  required: true,
};

const policyRequiredField: TextNode = {
  primitive: 'TEXT',
  nameOfFormControl: HelpFormFieldNames.Policy,
  inputId: HelpFormFieldNames.Policy,
  inputType: 'text',
  labelText: 'Policy number',
  required: true,
};

const multipleRequestTip: DialogBoxNode = {
  primitive: 'DIALOG',
  nameOfFormControl: UIFormFieldNames.MultipleRequestTip,
  inputId: UIFormFieldNames.MultipleRequestTip,
  type: 'primary',
  header: 'Do you need to make more than one policy change?',
  content: `<p>Submit a form for each request. This will ensure that we capture all the information we'll need to process your changes upfront.</p>`,
  cssClass: 'mb-2',
};

const policyValidationMessage: ValidationMessageNode = {
  primitive: 'VALIDATION_MESSAGE',
  errorType: 'policyNumberFormat',
  nameOfFormControl: UIFormFieldNames.PolicyValidationMessage,
  inputId: UIFormFieldNames.PolicyValidationMessage,
  required: false,
  validationMessage: `
  <div class="validation-message">
    <strong class="validation-title">Uh oh — that policy number looks incorrect</strong>
    <p>
      We won't be able to process your request with an incorrect policy number.
      To learn more about where you can find policy numbers,
      <a href="https://attunehelp.zendesk.com/hc/en-us/articles/4412958902427-Where-do-I-find-a-policy-number-" target="_blank">
        click here</a>.
    </p>
  </div>
  `,
};

const insuredEmailField: TextNode = {
  primitive: 'TEXT',
  nameOfFormControl: HelpFormFieldNames.InsuredEmail,
  inputId: HelpFormFieldNames.InsuredEmail,
  inputType: 'email',
  labelText: 'Insured billing email address',
  showErrorText: true,
  errorText: 'Please enter a valid email address.',
  required: true,
  validators: [ValidatorName.VALID_EMAIL],
};

const effectiveDateField = dateNode({
  nameOfFormControl: HelpFormFieldNames.EffectiveDate,
  labelText: 'Effective date',
  required: true,
});

const cancelRequestEffectiveDateField = dateNode({
  nameOfFormControl: HelpFormFieldNames.EffectiveDate,
  labelText: 'Cancellation effective date',
  required: true,
});

const cancellationEffectiveDateField = dateNode({
  nameOfFormControl: HelpFormFieldNames.CancellationDate,
  labelText: 'Cancellation effective date',
  required: true,
});

const cancellationReasonField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.EffectiveDate, HelpFormFieldNames.CancellationPolicyType],
  enableEvaluator: EvaluatorName.IS_MIDTERM_OR_RENEWAL_BACKDATED_5_DAYS,
  conditionalChildren: [
    {
      primitive: 'RADIO',
      nameOfFormControl: HelpFormFieldNames.CancellationReason,
      inputId: HelpFormFieldNames.CancellationReason,
      labelText: 'Reason for cancellation',
      options: {
        'Business sold': 'business_sold',
        'Business closed': 'business_closed',
        'Replaced coverage': 'replaced_coverage',
        other: 'cancel_reason_other',
      },
      required: true,
    },
  ],
};

const meetsEligibilityGuidelinesField: MultiInputNode = {
  primitive: 'RADIO',
  nameOfFormControl: HelpFormFieldNames.MeetsEligibilityGuidelines,
  inputId: HelpFormFieldNames.MeetsEligibilityGuidelines,
  labelText: 'Does this policy meet all current eligibility guidelines?',
  options: {
    Yes: 'yes_reinstatement',
    No: 'no_reinstatement',
  },
  required: true,
};

const quoteNumberRequiredField: TextNode = {
  primitive: 'TEXT',
  inputType: 'number',
  nameOfFormControl: HelpFormFieldNames.QuoteNumber,
  inputId: HelpFormFieldNames.QuoteNumber,
  labelText: 'Quote number',
  required: true,
};

// Note: carrier quote numbers have both numbers and characters, so input needs to be text
const quoteNumberMixedRequiredField: TextNode = {
  primitive: 'TEXT',
  inputType: 'text',
  nameOfFormControl: HelpFormFieldNames.QuoteNumber,
  inputId: HelpFormFieldNames.QuoteNumber,
  labelText: 'Quote number',
  required: true,
};

const quoteNumberField: TextNode = {
  primitive: 'TEXT',
  inputType: 'number',
  nameOfFormControl: HelpFormFieldNames.QuoteNumber,
  inputId: HelpFormFieldNames.QuoteNumber,
  labelText: 'Quote number',
  specifierText: '(optional)',
  required: false,
};

const declineReasonField: TextAreaNode = {
  primitive: 'TEXT_AREA',
  nameOfFormControl: HelpFormFieldNames.DeclineReason,
  inputId: HelpFormFieldNames.DeclineReason,
  labelText: 'Declination reason',
  required: true,
};

const aiAddOrUpdateField: MultiInputNode = {
  primitive: 'RADIO',
  nameOfFormControl: HelpFormFieldNames.AiAddOrUpdate,
  inputId: HelpFormFieldNames.AiAddOrUpdate,
  labelText: 'What would you like to do?',
  options: {
    'Add an additional insured': 'add_an_additional_insured',
    'Update an additional insured': 'update_an_additional_insured',
    'Remove an additional insured': 'remove_an_additional_insured',
  },
  required: true,
};

const aiNameField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.AiAddOrUpdate, EXTRA_DATA_FIELD_NAME],
  enableEvaluator: EvaluatorName.IS_ADD_OR_REMOVE_WITHOUT_AI_LIST,
  conditionalChildren: [
    {
      primitive: 'TEXT',
      nameOfFormControl: HelpFormFieldNames.AiName,
      inputId: HelpFormFieldNames.AiName,
      maxLength: '60',
      specifierText: '(max 60 characters)',
      inputType: 'text',
      labelText: 'Additional insured name',
      required: true,
    },
  ],
};

const aiTypeField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.AiAddOrUpdate, EXTRA_DATA_FIELD_NAME],
  enableEvaluator: EvaluatorName.ADD_AI_OR_REMOVE_WITH_NO_AI_OPTIONS,
  conditionalChildren: [
    {
      primitive: 'SELECT',
      nameOfFormControl: HelpFormFieldNames.AiType,
      inputId: HelpFormFieldNames.AiType,
      labelText: 'Type',
      options: ADDITIONAL_INSURED_TYPES,
      questionNote:
        'Not sure which type to use? <a href="https://attunehelp.zendesk.com/hc/en-us/articles/360044224431" target="_blank" data-amplitude-input-name="link-to-additional-insured-guide" >See our guide</a>',
      required: true,
    },
  ],
};

const aiBuildingField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.AiType, EXTRA_DATA_FIELD_NAME],
  enableEvaluator: EvaluatorName.IS_AI_PLACEMENT_BUILDING,
  conditionalChildren: [
    {
      primitive: 'TRADITIONAL_RADIO',
      nameOfFormControl: HelpFormFieldNames.AiBuilding,
      inputId: HelpFormFieldNames.AiBuilding,
      labelText: 'Building',
      options: {}, // overwritten by dynamicSource
      dynamicSource: 'additionalInsuredBuildings',
      required: true,
    },
  ],
};

const aiLocationField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.AiType, EXTRA_DATA_FIELD_NAME],
  enableEvaluator: EvaluatorName.IS_AI_PLACEMENT_LOCATION,
  conditionalChildren: [
    {
      primitive: 'TRADITIONAL_RADIO',
      nameOfFormControl: HelpFormFieldNames.AiLocation,
      inputId: HelpFormFieldNames.AiLocation,
      labelText: 'Location',
      options: {}, // overwritten by dynamicSource
      dynamicSource: 'additionalInsuredLocations',
      required: true,
    },
  ],
};

const aiAddressField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.AiAddOrUpdate, EXTRA_DATA_FIELD_NAME],
  enableEvaluator: EvaluatorName.ADD_AI_OR_REMOVE_WITH_NO_AI_OPTIONS,
  conditionalChildren: [
    addressNode(
      {
        nameOfFormControl: HelpFormFieldNames.AiAddress,
        labelText: 'Additional insured business address',
        prefix: HelpFormFieldNames.BusinessAddress,
      },
      true
    ),
  ],
};

const aiLocationOfCoveredOpsField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [
    HelpFormFieldNames.AiAddOrUpdate,
    HelpFormFieldNames.AiType,
    HelpFormFieldNames.AiUpdateType,
  ],
  enableEvaluator: EvaluatorName.NEEDS_LOCATION_OF_COVERED_OPS,
  conditionalChildren: [
    {
      primitive: 'TEXT',
      nameOfFormControl: HelpFormFieldNames.LocationOfCoveredOps,
      inputId: HelpFormFieldNames.LocationOfCoveredOps,
      labelText: 'Location of covered operations',
    },
  ],
};

const mismatchedUserAccountWarning: DialogBoxNode = {
  primitive: 'DIALOG',
  type: 'primary',
  content: `<p>Current user does not have any account with that number.</p>`,
  cssClass: 'form-hide',
  dynamicSource: 'forbiddenAccountWarning',
};

const mismatchedAccountPolicyWarning: DialogBoxNode = {
  primitive: 'DIALOG',
  type: 'primary',
  content: `<p>This account does not have any policy with that number.</p>`,
  cssClass: 'dialog-text__margin-bottom',
  dynamicSource: 'accountMismatchWarning',
};

const aiNonePresentWarning: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.AiAddOrUpdate, EXTRA_DATA_FIELD_NAME],
  enableEvaluator: EvaluatorName.UPDATE_OR_REMOVE_HAS_NO_AI_OPTIONS,
  conditionalChildren: [
    {
      primitive: 'DIALOG',
      type: 'primary',
      content: `<p>We couldn't find any Additional Insureds on that policy.\
      Did you mean to select "Add an additional insured"?</p>`,
      cssClass: 'dialog-text__margin-bottom',
      dynamicSource: 'noAdditionalInsuredWarning',
      nameOfFormControl: HelpFormFieldNames.AiNameToUpdate,
    },
  ],
};

const wosNonWc: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LineOfBusiness,
  enableEvaluator: EvaluatorName.IS_NOT_ATTUNE_WC,
  conditionalChildren: [
    {
      primitive: 'TEXT',
      nameOfFormControl: HelpFormFieldNames.WosName,
      inputId: HelpFormFieldNames.WosName,
      inputType: 'text',
      labelText: 'Legal entity or organization',
      maxLength: '60',
      specifierText: '(optional, max 60 characters)',
      questionNote: 'If Blackboard policy, please enter entity name',
      required: false,
    },
  ],
};

const wosSpecific: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.WosType,
  enableValue: 'specific',
  conditionalChildren: [
    {
      primitive: 'TEXT',
      nameOfFormControl: HelpFormFieldNames.WosNameRequired,
      inputId: HelpFormFieldNames.WosNameRequired,
      inputType: 'text',
      labelText: 'Legal entity or organization',
      maxLength: '60',
      specifierText: '(max 60 characters)',
      required: true,
    },
    addressNode({
      nameOfFormControl: HelpFormFieldNames.WosAddress,
      labelText: 'Address',
      prefix: HelpFormFieldNames.WosAddress,
      required: true,
    }),
    {
      primitive: 'TEXT_AREA',
      inputId: HelpFormFieldNames.WosDescription,
      nameOfFormControl: HelpFormFieldNames.WosDescription,
      labelText: 'Description',
      required: true,
    },
  ],
};

const descriptionOfChangeField: TextAreaNode = {
  primitive: 'TEXT_AREA',
  nameOfFormControl: HelpFormFieldNames.DescriptionOfChange,
  inputId: HelpFormFieldNames.DescriptionOfChange,
  labelText: 'Description of change',
  required: true,
};

const baseChangeCoverageOptions = {
  change_bpp_building_limit: 'Change BPP or building limit',
  add_remove_coverage: 'Add new or remove existing coverage',
  schedule_equipment: 'Schedule equipment on policy (contractors only)',
  update_limit: 'Update limit for an existing coverage',
  update_deductible: 'Update deductible for an existing coverage',
};

const changeCoverageTypeField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LineOfBusiness,
  enableEvaluator: EvaluatorName.IS_NOT_ATTUNE_WC,
  conditionalChildren: [
    {
      primitive: 'SELECT',
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageType,
      inputId: HelpFormFieldNames.ChangeCoverageType,
      labelText: 'Type of change request',
      options: baseChangeCoverageOptions,
      required: true,
    },
  ],
};

const changeCoverageTypeWcField: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LineOfBusiness,
  enableValue: 'attune_work_comp',
  conditionalChildren: [
    {
      primitive: 'SELECT',
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageTypeWc,
      inputId: HelpFormFieldNames.ChangeCoverageTypeWc,
      labelText: 'Type of change request',
      options: {
        update_liability_limits: 'Update liability limits',
        ...baseChangeCoverageOptions,
      },
      required: true,
    },
  ],
};

const splitPPCField: MultiInputNode = {
  primitive: 'RADIO',
  nameOfFormControl: HelpFormFieldNames.SplitPPC,
  inputId: HelpFormFieldNames.SplitPPC,
  labelText:
    'Is this building within 1,000 feet of a creditable water supply, such as a fire hydrant, suction point, or dry hydrant?',
  options: {
    Yes: 'yes_splitppc',
    No: 'no_splitppc',
  },
  required: true,
};

const employeesPerLocationField: TextAreaNode = {
  primitive: 'TEXT_AREA',
  nameOfFormControl: HelpFormFieldNames.EmployeesPerLocation,
  inputId: HelpFormFieldNames.EmployeesPerLocation,
  labelText: 'For each location, please indicate the address and number of employees',
  required: true,
};

const paymentPlanField: MultiInputNode = {
  primitive: 'RADIO',
  nameOfFormControl: HelpFormFieldNames.PaymentPlan,
  inputId: HelpFormFieldNames.PaymentPlan,
  labelText: 'Payment plan',
  options: {
    '1-Pay': '1-pay_plan',
    '4-Pay': '4-pay_plan',
    '10-Pay': '10-pay_plan',
  },
  required: true,
};

const dbaField: TextNode = {
  primitive: 'TEXT',
  nameOfFormControl: HelpFormFieldNames.Dba,
  inputId: HelpFormFieldNames.Dba,
  inputType: 'text',
  labelText: 'DBA or Operating Name',
  required: false,
};

const locationAddOrUpdateField: MultiInputNode = {
  primitive: 'RADIO',
  inputId: HelpFormFieldNames.LocationAddOrUpdate,
  nameOfFormControl: HelpFormFieldNames.LocationAddOrUpdate,
  labelText: 'What would you like to do?',
  options: {
    'Add location': 'add_a_new_location',
    'Update location': 'update_an_existing_location',
    'Remove location': 'remove_an_existing_location',
  },
  required: true,
};

const changeInOperationField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LineOfBusiness,
  enableEvaluator: EvaluatorName.IS_NOT_ATTUNE_WC,
  conditionalChildren: [
    {
      primitive: 'RADIO',
      inputId: HelpFormFieldNames.ChangeInOperation,
      nameOfFormControl: HelpFormFieldNames.ChangeInOperation,
      labelText:
        "Has there been any change in organizational structure, operation, or insured's scope of work?",
      options: {
        Yes: 'yes_add_location',
        No: 'no_add_location',
      },
      required: true,
    },
  ],
};

const agencyMailingAddressDifferent: CheckboxNode = {
  primitive: 'CHECKBOX',
  inputId: 'agencyMailingAddressDifferent',
  nameOfFormControl: 'agencyMailingAddressDifferent',
  labelText: 'Agency Mailing Address different from above',
};

const agencyMailingAddressField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: 'agencyMailingAddressDifferent',
  enableEvaluator: EvaluatorName.CHECKBOX,
  conditionalChildren: [
    addressNode({
      nameOfFormControl: HelpFormFieldNames.AgencyMailingAddress,
      labelText: 'Agency Mailing Address',
      prefix: HelpFormFieldNames.AgencyMailingAddress,
      required: true,
    }),
  ],
};

const agencyPhysicalAddressField: AddressNode = addressNode({
  nameOfFormControl: HelpFormFieldNames.AgencyPhysicalAddress,
  labelText: 'Agency Physical Address',
  prefix: HelpFormFieldNames.AgencyPhysicalAddress,
  required: true,
});

const businessMailingAddressField: AddressNode = addressNode({
  nameOfFormControl: HelpFormFieldNames.BusinessMailingAddress,
  labelText: 'Business mailing address',
  prefix: HelpFormFieldNames.BusinessMailingAddress,
  required: false,
});

const checkMailingAddressField: AddressNode = addressNode({
  nameOfFormControl: HelpFormFieldNames.CheckMailingAddress,
  labelText: 'Check mailing address',
  prefix: HelpFormFieldNames.CheckMailingAddress,
  required: true,
});

const updateRefundBankDialogField: DialogBoxNode = {
  primitive: 'DIALOG',
  type: 'primary',
  header: 'Read this first',
  content: `<p>Since we are unable to accept or update banking information through chat, email, \
    or phone, please either complete this HelloSign form or confirm the best mailing \
    address to which payment should be issued. Allow 2-3 weeks after submission of this \
    information for processing.</p>\
    <a class=dialog-text-link href="${HELLO_SIGN_UPDATE_REFUND_BANK_INFO}" target="_blank">Fill out HelloSign form</a>`,
  cssClass: 'dialog-text__margin-bottom',
};

const updateCommissionBankDialogField: DialogBoxNode = {
  primitive: 'DIALOG',
  type: 'primary',
  header: 'Read this first',
  content: `<p>Since we are unable to accept or update banking information through chat, email, \
    or phone, please make this update in the finance tab. <strong>Note that only the dedicated finance \
    user can use this feature.</strong> If you don’t know who that is, submit this form.
  </p>\
    <a class=dialog-text-link href="/settings/finances">Go to finance tab</a>`,
  cssClass: 'dialog-text__margin-bottom',
};

const fileUploadField = fileUploadNode({
  nameOfFormControl: UIFormFieldNames.SupplementaryDocuments,
  labelText: 'Supplementary documents',
  specifierText: '(optional)',
});

const newBusinessAppDocsField = fileUploadNode({
  nameOfFormControl: UIFormFieldNames.LocationDocuments,
  labelText: `Please complete and upload page 2 of the <a href="${ATTUNE_NEW_BUSINESS_APP}" target="_blank">Attune New Business Application</a>`,
  required: true,
});

const borDocumentsField = fileUploadNode({
  nameOfFormControl: UIFormFieldNames.BorDocuments,
  labelText: `Please complete and upload an <a href="${BROKER_OF_RECORD_CHANGE}" target="_blank">Agent/Broker of Record Change (ACORD 36)</a> form.`,
  required: true,
});

const lprDocumentsField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.EffectiveDate, HelpFormFieldNames.CancellationReason],
  enableEvaluator: EvaluatorName.IS_BACKDATED_OTHER_OR_WITHIN_30_DAYS_AND_REASON,
  conditionalChildren: [
    fileUploadNode({
      nameOfFormControl: UIFormFieldNames.LprDocuments,
      labelText: `Please complete and upload a <a href="${LOST_POLICY_RELEASE}" target="_blank">Lost Policy Release (LPR) form</a>.`,
      required: true,
    }),
  ],
};

const supplementaryDocsField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [
    HelpFormFieldNames.ChangeCoverageType,
    HelpFormFieldNames.ChangeCoverageAddRemove,
    HelpFormFieldNames.EffectiveDate,
  ],
  enableEvaluator: EvaluatorName.HAS_SUPPLEMENTARY_DOCS_UPLOAD,
  conditionalChildren: [fileUploadField],
};

const aiNkllDocsField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.AiAddOrUpdate, HelpFormFieldNames.EffectiveDate],
  enableEvaluator: EvaluatorName.IS_UPDATE_OR_REMOVE_AI_AND_BACKDATED,
  conditionalChildren: [
    fileUploadNode({
      nameOfFormControl: UIFormFieldNames.NkllDocuments,
      labelText: `Please complete and upload a <a href="${NKLL_TEMPLATE_URL}" target="_blank">No Known Loss Letter (NKLL)</a>. An NKLL is required for additional insured endorsements when backdated (excluding BP 04 09, BP 04 10, BP 12 03, BP 12 31, Mortgage Holder).`,
      specifierText: '(optional)',
    }),
  ],
};

export interface NkllDocsUploadConfig {
  nameOfFormControl?: UIFormFieldNames;
  labelText?: string;
  dependsOn?: string | string[];
  enableEvaluator?: EvaluatorName;
  required?: boolean;
}

const nkllDefaultLabelText = `Please complete and upload a
                              <a href="${NKLL_TEMPLATE_URL}" target="_blank">
                              No Known Loss Letter (NKLL)</a>.
                              An NKLL is required for this type of endorsement when backdated.`;

const getNkllDocsField = ({
  nameOfFormControl = UIFormFieldNames.NkllDocuments,
  labelText = nkllDefaultLabelText,
  dependsOn = HelpFormFieldNames.EffectiveDate,
  enableEvaluator = EvaluatorName.IS_BEFORE_TODAY,
  required = true,
}: NkllDocsUploadConfig = {}): EvalConditional => {
  return {
    primitive: 'EVAL-CONDITIONAL',
    dependsOn,
    enableEvaluator,
    conditionalChildren: [
      fileUploadNode({
        nameOfFormControl,
        labelText,
        required,
      }),
    ],
  };
};

const nkllDocsField = getNkllDocsField({
  dependsOn: [
    HelpFormFieldNames.ChangeCoverageType,
    HelpFormFieldNames.ChangeCoverageAddRemove,
    HelpFormFieldNames.EffectiveDate,
  ],
  enableEvaluator: EvaluatorName.HAS_NKLL_DOCS_UPLOAD,
});

const updateDeductibleBackdatedNkllDocsField = getNkllDocsField({
  nameOfFormControl: UIFormFieldNames.UpdateDeductibleNkllDocuments,
  labelText: 'A NKLL is required if the request being submitted is to reduce a deductible.',
  dependsOn: [HelpFormFieldNames.ChangeCoverageType, HelpFormFieldNames.EffectiveDate],
  enableEvaluator: EvaluatorName.IS_UPDATE_DEDUCTIBLE_AND_BACKDATED,
  required: false,
});

const bindQuoteNkllDocsField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: HelpFormFieldNames.EffectiveDate,
  enableEvaluator: EvaluatorName.IS_BEFORE_TODAY,
  conditionalChildren: [
    fileUploadNode({
      nameOfFormControl: UIFormFieldNames.NkllDocuments,
      labelText: `Please complete and upload a <a href="${NKLL_TEMPLATE_URL}" target="_blank">No Known Loss Letter (NKLL)</a>. An NKLL is required for backdated quote binds.`,
      required: true,
    }),
  ],
};

const locationNkllDocsField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: HelpFormFieldNames.EffectiveDate,
  enableEvaluator: EvaluatorName.IS_BACKDATED_MORE_THAN_30_DAYS,
  conditionalChildren: [
    fileUploadNode({
      nameOfFormControl: UIFormFieldNames.NkllDocuments,
      labelText: `Please complete and upload a <a href="${NKLL_TEMPLATE_URL}" target="_blank">No Known Loss Letter (NKLL)</a>. An NKLL is required for backdated quote binds.`,
      required: true,
    }),
  ],
};

const locationFileUploadField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: HelpFormFieldNames.EffectiveDate,
  enableEvaluator: EvaluatorName.IS_NOT_BACKDATED_MORE_THAN_30_DAYS,
  conditionalChildren: [
    fileUploadNode({
      nameOfFormControl: UIFormFieldNames.SupplementaryDocuments,
      labelText: 'Supplementary documents',
      specifierText: '(optional)',
    }),
  ],
};

const lroSupplementaryDocsField = fileUploadNode({
  nameOfFormControl: UIFormFieldNames.LroDocuments,
  labelText:
    'Please provide a rent roll including a list of tenants/unit owners, square footage, and description of tenant operations at each location',
  specifierText: '(optional)',
});

const reinstatementNkllDocsField = fileUploadNode({
  nameOfFormControl: UIFormFieldNames.NkllDocuments,
  labelText: `Please complete and upload a <a href="${NKLL_TEMPLATE_URL}" target="_blank">No Known Loss Letter (NKLL)</a>. An NKLL is required for all reinstatement requests.`,
  required: true,
});

const commissionStatementDialogField: DialogBoxNode = {
  primitive: 'DIALOG',
  type: 'primary',
  header: 'Read this first',
  content:
    'We email commission statements for BOP and XS to the dedicated agency \
    finance user on the 15th of every month and include all transactions since the \
    15th of the prior month. Attune pays on the annual premium total of the policy, not \
    on the installment or collected amount. Payments are sent on the 15th of each month \
    and should be received within 2-3 business days of the statement.',
  cssClass: 'dialog-text__margin-bottom',
};

const statementDateField = dateNode({
  nameOfFormControl: HelpFormFieldNames.StatementDate,
  labelText: 'Statement date',
  required: true,
});

const amountReceivedField: TextNode = {
  primitive: 'MONEY_WITH_DECIMAL',
  nameOfFormControl: HelpFormFieldNames.AmountReceived,
  inputId: HelpFormFieldNames.AmountReceived,
  inputType: 'number',
  labelText: 'Amount received',
  specifierText: '(optional)',
  showErrorText: true,
  errorText: 'Please enter a valid amount',
  required: false,
};

const aiNameToUpdateField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.AiAddOrUpdate, EXTRA_DATA_FIELD_NAME],
  enableEvaluator: EvaluatorName.UPDATE_HAS_NO_AI_OPTIONS,
  conditionalChildren: [
    {
      primitive: 'TEXT',
      nameOfFormControl: HelpFormFieldNames.AiNameToUpdate,
      inputId: HelpFormFieldNames.AiNameToUpdate,
      labelText: 'What is the name of the existing additional insured you would like to update?',
    },
  ],
};

const aiIdToUpdateField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.AiAddOrUpdate, EXTRA_DATA_FIELD_NAME],
  enableEvaluator: EvaluatorName.UPDATE_OR_REMOVE_HAS_AI_OPTIONS,
  conditionalChildren: [
    {
      primitive: 'TRADITIONAL_RADIO',
      nameOfFormControl: HelpFormFieldNames.AiIdToUpdate,
      inputId: HelpFormFieldNames.AiIdToUpdate,
      labelText: 'What is the name of the existing additional insured you would like to update?',
      options: { other: "Other/Don't Know" },
      dynamicSource: 'additionalInsuredList',
      required: true,
    },
  ],
};

const cancellationPolicyType: MultiInputNode = {
  primitive: 'RADIO',
  inputId: HelpFormFieldNames.CancellationPolicyType,
  nameOfFormControl: HelpFormFieldNames.CancellationPolicyType,
  labelText: 'Is this cancellation for a midterm or renewal policy?',
  options: {
    Active: 'active_cancellation',
    Renewal: 'renewal_cancellation',
  },
  required: true,
};

const cancellationReasonOtherField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [
    HelpFormFieldNames.EffectiveDate,
    HelpFormFieldNames.CancellationPolicyType,
    HelpFormFieldNames.CancellationReason,
  ],
  enableEvaluator: EvaluatorName.REQUIRES_CANCELLATION_REASON,
  conditionalChildren: [
    {
      primitive: 'TEXT_AREA',
      inputId: HelpFormFieldNames.CancellationReasonOther,
      nameOfFormControl: HelpFormFieldNames.CancellationReasonOther,
      labelText: 'Reason for requesting termination of coverage',
      required: true,
    },
  ],
  required: true,
};

const cancellationAcknowledgementField: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.CancellationPolicyType,
  enableValue: 'renewal_cancellation',
  conditionalChildren: [
    {
      primitive: 'CHECKBOX',
      inputId: 'cancellationAcknowledgement',
      nameOfFormControl: 'cancellationAcknowledgement',
      labelText:
        "By submitting this form, I acknowledge that my office and the insured are aware \
        that coverage for this risk will end at the time of the current policy's expiration.",
    },
  ],
};

const addLocationReasonField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LocationAddOrUpdate, HelpFormFieldNames.LineOfBusiness],
  enableEvaluator: EvaluatorName.IS_ADD_LOCATION_NON_WC,
  conditionalChildren: [
    {
      primitive: 'TEXT_AREA',
      inputId: HelpFormFieldNames.AddLocationReason,
      nameOfFormControl: HelpFormFieldNames.AddLocationReason,
      labelText: 'Please provide an explanation for why the insured is adding this location',
      required: true,
    },
  ],
};

const aiUpdateNameField: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.AiAddOrUpdate,
  enableValue: 'update_an_additional_insured',
  conditionalChildren: [
    {
      primitive: 'TEXT',
      nameOfFormControl: HelpFormFieldNames.AiUpdateName,
      inputId: HelpFormFieldNames.AiUpdateName,
      labelText: 'Updated name of additional insured',
      specifierText: '(optional)',
      required: false,
    },
  ],
};

const updateLocationReasonField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LocationAddOrUpdate, HelpFormFieldNames.LineOfBusiness],
  enableEvaluator: EvaluatorName.IS_UPDATE_LOCATION_NON_WC,
  conditionalChildren: [
    {
      primitive: 'TEXT_AREA',
      inputId: HelpFormFieldNames.UpdateLocationReason,
      nameOfFormControl: HelpFormFieldNames.UpdateLocationReason,
      labelText: 'Please provide an explanation for why the insured is updating this location',
      required: true,
    },
  ],
};

const aiUpdateAddressField: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.AiAddOrUpdate,
  enableValue: 'update_an_additional_insured',
  conditionalChildren: [
    addressNode(
      {
        nameOfFormControl: HelpFormFieldNames.AiUpdatedAddress,
        labelText: 'Updated additional insured business address',
        specifierText: '(optional)',
        prefix: HelpFormFieldNames.AiUpdateAddress,
        required: false,
      },
      true
    ),
  ],
};

const removeLocationReasonField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LocationAddOrUpdate, HelpFormFieldNames.LineOfBusiness],
  enableEvaluator: EvaluatorName.IS_REMOVE_LOCATION_NON_WC,
  conditionalChildren: [
    {
      primitive: 'TEXT_AREA',
      inputId: HelpFormFieldNames.RemoveLocationReason,
      nameOfFormControl: HelpFormFieldNames.RemoveLocationReason,
      labelText: 'Please provide an explanation for why the insured is removing this location',
      required: true,
    },
  ],
};

const lprAndBusinessClosedDocumentsField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.EffectiveDate, HelpFormFieldNames.CancellationReason],
  enableEvaluator: EvaluatorName.IS_BACKDATED_30_DAYS_AND_CLOSED,
  conditionalChildren: [
    fileUploadNode({
      nameOfFormControl: UIFormFieldNames.LprAndBusinessClosedDocuments,
      labelText: `Upload a signed <a href="${LOST_POLICY_RELEASE}" target="_blank">LPR (lost policy release)</a> and proof of closure (such as a signed statement from the insured stating date of closure).`,
      required: true,
      validators: [ValidatorName.UPLOADS_SUCCESSFUL],
    }),
  ],
};

const aiUpdateTypeField: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.AiAddOrUpdate,
  enableValue: 'update_an_additional_insured',
  conditionalChildren: [
    {
      primitive: 'SELECT',
      nameOfFormControl: HelpFormFieldNames.AiUpdateType,
      inputId: HelpFormFieldNames.AiUpdateType,
      labelText: 'Updated type',
      specifierText: '(optional)',
      options: ADDITIONAL_INSURED_TYPES,
      questionNote:
        'Not sure which type to use? <a href="https://attunehelp.zendesk.com/hc/en-us/articles/360044224431" target="_blank" data-amplitude-input-name="link-to-additional-insured-guide" >See our guide</a>',
    },
  ],
};

const aiUpdateBuildingField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.AiUpdateType, EXTRA_DATA_FIELD_NAME],
  enableEvaluator: EvaluatorName.IS_AI_PLACEMENT_BUILDING,
  conditionalChildren: [
    {
      primitive: 'TRADITIONAL_RADIO',
      nameOfFormControl: HelpFormFieldNames.AiUpdateBuilding,
      inputId: HelpFormFieldNames.AiUpdateBuilding,
      labelText: 'Updated building',
      options: {}, // overwritten by dynamicSource
      dynamicSource: 'additionalInsuredBuildings',
      required: true,
    },
  ],
};

const aiUpdateLocationField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.AiUpdateType, EXTRA_DATA_FIELD_NAME],
  enableEvaluator: EvaluatorName.IS_AI_PLACEMENT_LOCATION,
  conditionalChildren: [
    {
      primitive: 'TRADITIONAL_RADIO',
      nameOfFormControl: HelpFormFieldNames.AiUpdateLocation,
      inputId: HelpFormFieldNames.AiUpdateLocation,
      labelText: 'Updated location',
      options: {}, // overwritten by dynamicSource
      dynamicSource: 'additionalInsuredLocations',
      required: true,
    },
  ],
};

const lprAndBusinessSoldDocumentsField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.EffectiveDate, HelpFormFieldNames.CancellationReason],
  enableEvaluator: EvaluatorName.IS_BACKDATED_30_DAYS_AND_SOLD,
  conditionalChildren: [
    fileUploadNode({
      nameOfFormControl: UIFormFieldNames.LprAndBusinessSoldDocuments,
      labelText: `Upload a signed <a href="${LOST_POLICY_RELEASE}" target="_blank">LPR (lost policy release)</a> and proof of sale (such as the bill of sale confirming the exact sale date).`,
      required: true,
      validators: [ValidatorName.UPLOADS_SUCCESSFUL],
    }),
  ],
};

const lprAndCoverageReplacedDocumentsField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.EffectiveDate, HelpFormFieldNames.CancellationReason],
  enableEvaluator: EvaluatorName.IS_BACKDATED_30_DAYS_AND_COVERAGE_REPLACED,
  conditionalChildren: [
    fileUploadNode({
      nameOfFormControl: UIFormFieldNames.LprAndCoverageReplacedDocuments,
      labelText: `Upload a signed <a href="${LOST_POLICY_RELEASE}" target="_blank">LPR (lost policy release)</a> and proof that coverage has been replaced (such as the declaration page of the replacement policy).`,
      required: true,
      validators: [ValidatorName.UPLOADS_SUCCESSFUL],
    }),
  ],
};

const numberOfEmployeesField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LocationAddOrUpdate, HelpFormFieldNames.LineOfBusiness],
  enableEvaluator: EvaluatorName.IS_ADD_OR_UPDATE_LOCATION_NON_WC,
  conditionalChildren: [
    {
      primitive: 'NUMBER',
      inputId: HelpFormFieldNames.NumberOfEmployees,
      nameOfFormControl: HelpFormFieldNames.NumberOfEmployees,
      labelText: 'Number of employees',
      inputType: 'number',
      required: true,
    },
  ],
};

const annualRevenueField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LocationAddOrUpdate, HelpFormFieldNames.LineOfBusiness],
  enableEvaluator: EvaluatorName.IS_ADD_OR_UPDATE_LOCATION_NON_WC,
  conditionalChildren: [
    {
      primitive: 'MONEY_WITHOUT_DECIMAL',
      inputId: HelpFormFieldNames.AnnualRevenue,
      nameOfFormControl: HelpFormFieldNames.AnnualRevenue,
      labelText: 'Annual revenue',
      specifierText: '(only required for restaurants)',
      required: false,
    },
  ],
};

const aiInsurableInterestField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [
    HelpFormFieldNames.AiAddOrUpdate,
    HelpFormFieldNames.AiType,
    HelpFormFieldNames.AiUpdateType,
  ],
  enableEvaluator: EvaluatorName.NEEDS_AI_INSURABLE_INTEREST,
  conditionalChildren: [
    {
      primitive: 'TEXT_AREA',
      nameOfFormControl: HelpFormFieldNames.AiInsurableInterest,
      inputId: HelpFormFieldNames.AiInsurableInterest,
      labelText:
        'Please describe the insurable interest between the name insured and this additional insured',
    },
  ],
};

const annualPayrollField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LocationAddOrUpdate, HelpFormFieldNames.LineOfBusiness],
  enableEvaluator: EvaluatorName.IS_ADD_OR_UPDATE_LOCATION_NON_WC,
  conditionalChildren: [
    {
      primitive: 'MONEY_WITHOUT_DECIMAL',
      inputId: HelpFormFieldNames.AnnualPayroll,
      nameOfFormControl: HelpFormFieldNames.AnnualPayroll,
      labelText: 'Annual payroll',
      specifierText: '(only required for contractors)',
      required: false,
    },
  ],
};

const interestField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LocationAddOrUpdate, HelpFormFieldNames.LineOfBusiness],
  enableEvaluator: EvaluatorName.IS_ADD_OR_UPDATE_LOCATION_NON_WC,
  conditionalChildren: [
    {
      primitive: 'RADIO',
      nameOfFormControl: HelpFormFieldNames.Interest,
      inputId: HelpFormFieldNames.Interest,
      labelText: 'Interest',
      options: {
        owner: 'Owner',
        tenant: 'Tenant',
      },
      required: true,
    },
  ],
};

const aiLeasedEquipmentField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [
    HelpFormFieldNames.AiAddOrUpdate,
    HelpFormFieldNames.AiType,
    HelpFormFieldNames.AiUpdateType,
  ],
  enableEvaluator: EvaluatorName.IS_AI_LESSOR,
  conditionalChildren: [
    {
      primitive: 'TEXT_AREA',
      nameOfFormControl: HelpFormFieldNames.AiLeasedEquipment,
      inputId: HelpFormFieldNames.AiLeasedEquipment,
      labelText: 'Description of leased equipment',
    },
  ],
};

const buildingLimitField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LocationAddOrUpdate, HelpFormFieldNames.Interest],
  enableEvaluator: EvaluatorName.IS_ADD_OR_UPDATE_AND_OWNER_INTEREST,
  conditionalChildren: [
    {
      primitive: 'MONEY_WITHOUT_DECIMAL',
      inputId: HelpFormFieldNames.BuildingLimit,
      nameOfFormControl: HelpFormFieldNames.BuildingLimit,
      labelText: 'Building limit',
      required: true,
    },
  ],
};

const ownerBuildingPersonalPropertyLimitField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LocationAddOrUpdate, HelpFormFieldNames.Interest],
  enableEvaluator: EvaluatorName.IS_ADD_OR_UPDATE_AND_OWNER_INTEREST,
  conditionalChildren: [
    {
      primitive: 'MONEY_WITHOUT_DECIMAL',
      inputId: HelpFormFieldNames.BuildingPersonalPropertyLimitOwner,
      nameOfFormControl: HelpFormFieldNames.BuildingPersonalPropertyLimitOwner,
      labelText: 'Building Personal Property limit',
      subLabelText:
        'Property coverages, both Building and BPP, must be covered at 100% insured to value.',
      required: true,
    },
  ],
};

const aiParagraphAbcField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [
    HelpFormFieldNames.AiAddOrUpdate,
    HelpFormFieldNames.AiType,
    HelpFormFieldNames.AiUpdateType,
  ],
  enableEvaluator: EvaluatorName.IS_AI_LOSS_PAYABLE,
  conditionalChildren: [
    {
      primitive: 'SELECT',
      nameOfFormControl: HelpFormFieldNames.AiParagraphAbc,
      inputId: HelpFormFieldNames.AiParagraphAbc,
      labelText: 'Paragraph A, B, or C?',
      questionNote:
        'What are Paragraphs A, B, and C? <a href="https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/BOP+Loss+Payable+Clauses+(BP12031T).pdf" target="_blank" data-amplitude-input-name="link-to-loss-payable-clauses">Learn More</a>',
      options: {
        A: 'A',
        B: 'B',
        C: 'C',
      },
    },
  ],
};

const tenantBuildingPersonPropertyLimitField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LocationAddOrUpdate, HelpFormFieldNames.Interest],
  enableEvaluator: EvaluatorName.IS_ADD_OR_UPDATE_AND_TENANT_INTEREST,
  conditionalChildren: [
    {
      primitive: 'MONEY_WITHOUT_DECIMAL',
      inputId: HelpFormFieldNames.BuildingPersonalPropertyLimitTenant,
      nameOfFormControl: HelpFormFieldNames.BuildingPersonalPropertyLimitTenant,
      labelText: 'Building Personal Property limit',
      subLabelText:
        'Please allocate improvements and Betterments as BPP. BPP must be covered at 100% insured to value.',
      required: true,
    },
  ],
};

const numberOfStoriesField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LocationAddOrUpdate, HelpFormFieldNames.LineOfBusiness],
  enableEvaluator: EvaluatorName.IS_ADD_OR_UPDATE_LOCATION_NON_WC,
  conditionalChildren: [
    {
      primitive: 'SELECT',
      inputId: HelpFormFieldNames.NumberOfStories,
      nameOfFormControl: HelpFormFieldNames.NumberOfStories,
      labelText: 'Number of stories',
      options: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        more_than_6: 'More than 6',
      },
      required: true,
    },
  ],
};

const aiCompletedOperationsField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [
    HelpFormFieldNames.AiAddOrUpdate,
    HelpFormFieldNames.AiType,
    HelpFormFieldNames.AiUpdateType,
  ],
  enableEvaluator: EvaluatorName.IS_AI_COMPLETED_OPERATIONS,
  conditionalChildren: [
    {
      primitive: 'TEXT_AREA',
      nameOfFormControl: HelpFormFieldNames.AiCompletedOperations,
      inputId: HelpFormFieldNames.AiCompletedOperations,
      labelText: 'Description of completed operations',
    },
  ],
};

const constructionTypeField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LocationAddOrUpdate, HelpFormFieldNames.LineOfBusiness],
  enableEvaluator: EvaluatorName.IS_ADD_OR_UPDATE_LOCATION_NON_WC,
  conditionalChildren: [
    {
      primitive: 'SELECT',
      inputId: HelpFormFieldNames.ConstructionType,
      nameOfFormControl: HelpFormFieldNames.ConstructionType,
      labelText: 'Construction type',
      options: {
        frame_construction: 'Frame construction',
        jointed_masonry: 'Jointed masonry',
        'non-combustible': 'Non-combustible',
        'masonry_non-combustible': 'Masonry non-combustible',
        'modified_fire-resistive': 'Modified fire-resistive',
        'fire-resistive': 'Fire-resistive',
      },
      required: true,
    },
  ],
};

const squareFootageField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LocationAddOrUpdate, HelpFormFieldNames.LineOfBusiness],
  enableEvaluator: EvaluatorName.IS_ADD_OR_UPDATE_LOCATION_NON_WC,
  conditionalChildren: [
    {
      primitive: 'NUMBER',
      inputId: HelpFormFieldNames.SquareFootage,
      nameOfFormControl: HelpFormFieldNames.SquareFootage,
      labelText: 'Square footage occupied by insured',
      required: true,
    },
  ],
};

const aiVendorProductsField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [
    HelpFormFieldNames.AiAddOrUpdate,
    HelpFormFieldNames.AiType,
    HelpFormFieldNames.AiUpdateType,
  ],
  enableEvaluator: EvaluatorName.IS_AI_VENDORS,
  conditionalChildren: [
    {
      primitive: 'TEXT_AREA',
      nameOfFormControl: HelpFormFieldNames.AiVendorProducts,
      inputId: HelpFormFieldNames.AiVendorProducts,
      labelText: "Vendor's products",
    },
  ],
};

const yearBuiltField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LocationAddOrUpdate, HelpFormFieldNames.LineOfBusiness],
  enableEvaluator: EvaluatorName.IS_ADD_OR_UPDATE_LOCATION_NON_WC,
  conditionalChildren: [
    {
      primitive: 'NUMBER',
      inputId: HelpFormFieldNames.YearBuilt,
      nameOfFormControl: HelpFormFieldNames.YearBuilt,
      labelText: 'Year built',
      required: true,
    },
  ],
};

const roofUpdateField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LocationAddOrUpdate, HelpFormFieldNames.YearBuilt],
  enableEvaluator: EvaluatorName.IS_ADD_OR_UPDATE_AND_20_YEARS_OLD,
  conditionalChildren: [
    {
      primitive: 'RADIO',
      inputId: HelpFormFieldNames.RoofUpdate,
      nameOfFormControl: HelpFormFieldNames.RoofUpdate,
      labelText: 'Has roof been updated in the last 20 years?',
      options: {
        Yes: 'yes_roof_updated',
        No: 'no_roof_updated',
      },
      required: true,
    },
  ],
};

const addAiNkllDocsField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [
    HelpFormFieldNames.AiAddOrUpdate,
    HelpFormFieldNames.EffectiveDate,
    HelpFormFieldNames.AiType,
  ],
  enableEvaluator: EvaluatorName.IS_ADD_AI_AND_BACKDATED_AND_NEEDS_NKLL,
  conditionalChildren: [
    fileUploadNode({
      nameOfFormControl: UIFormFieldNames.AddAiNkllDocuments,
      labelText: `Please complete and upload a <a href="${NKLL_TEMPLATE_URL}" target="_blank">No Known Loss Letter (NKLL)</a>. An NKLL is required for additional insured endorsements when backdated (excluding BP 04 09, BP 04 10, BP 12 03, BP 12 31, Mortgage Holder).`,
      required: true,
    }),
  ],
};

const aiSupplementaryDocsField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [
    HelpFormFieldNames.AiAddOrUpdate,
    HelpFormFieldNames.EffectiveDate,
    HelpFormFieldNames.AiType,
  ],
  enableEvaluator: EvaluatorName.IS_AI_DOES_NOT_NEED_NKLL,
  conditionalChildren: [fileUploadField],
};

const electricPlumbingHvacUpdatedField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LocationAddOrUpdate, HelpFormFieldNames.YearBuilt],
  enableEvaluator: EvaluatorName.IS_ADD_OR_UPDATE_AND_20_YEARS_OLD,
  conditionalChildren: [
    {
      primitive: 'RADIO',
      inputId: HelpFormFieldNames.ElectricPlumbingHvacUpdated,
      nameOfFormControl: HelpFormFieldNames.ElectricPlumbingHvacUpdated,
      labelText: 'Has electrical, plumbing, and HVAC been updated?',
      options: {
        Yes: 'yes_elec_plumb_hvac',
        No: 'no_elec_plumb_hvac',
      },
      required: true,
    },
  ],
};

const automaticSprinklerField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LocationAddOrUpdate, HelpFormFieldNames.LineOfBusiness],
  enableEvaluator: EvaluatorName.IS_ADD_OR_UPDATE_LOCATION_NON_WC,
  conditionalChildren: [
    {
      primitive: 'RADIO',
      inputId: HelpFormFieldNames.AutomaticSprinklers,
      nameOfFormControl: HelpFormFieldNames.AutomaticSprinklers,
      labelText: 'Does this building have an automatic sprinkler system?',
      options: {
        Yes: 'yes_sprinkler',
        No: 'no_sprinkler',
      },
      required: true,
    },
  ],
};

const meansOfEgressField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [
    HelpFormFieldNames.LocationAddOrUpdate,
    HelpFormFieldNames.Interest,
    HelpFormFieldNames.NumberOfStories,
  ],
  enableEvaluator: EvaluatorName.IS_ADD_UPDATE_AND_TENANT_AND_4_STORIES,
  conditionalChildren: [
    {
      primitive: 'RADIO',
      inputId: HelpFormFieldNames.MeansOfEgress,
      nameOfFormControl: HelpFormFieldNames.MeansOfEgress,
      labelText: 'Does this building have two means of egress?',
      options: {
        Yes: 'yes_egress',
        No: 'no_egress',
      },
      required: true,
    },
  ],
};

const insuredStateField: MultiInputNode = {
  primitive: 'SELECT',
  inputId: HelpFormFieldNames.InsuredState,
  nameOfFormControl: HelpFormFieldNames.InsuredState,
  labelText: 'Insured State',
  options: US_STATES_DICT,
  required: true,
  cssClass: 'insured-state-field',
};

const updateLocationOldAddressField: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LocationAddOrUpdate,
  enableValue: 'update_an_existing_location',
  conditionalChildren: [
    addressNode({
      nameOfFormControl: HelpFormFieldNames.UpdateLocationOldAddress,
      labelText: 'Old location business address (to be removed)',
      prefix: HelpFormFieldNames.UpdateLocationOldAddress,
      required: true,
    }),
  ],
};

const removeLocationRemoveAddressField: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LocationAddOrUpdate,
  enableValue: 'remove_an_existing_location',
  conditionalChildren: [
    addressNode({
      nameOfFormControl: HelpFormFieldNames.RemoveAddress,
      labelText: 'Location business address (to be removed)',
      prefix: HelpFormFieldNames.RemoveAddress,
      required: true,
    }),
  ],
};

const addLocationAddressField: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LocationAddOrUpdate,
  enableValue: 'add_a_new_location',
  conditionalChildren: [
    addressNode({
      nameOfFormControl: HelpFormFieldNames.BusinessAddress,
      labelText: 'Location business address',
      prefix: HelpFormFieldNames.BusinessAddress,
      required: true,
    }),
  ],
};

const updateLocationNewAddressField: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LocationAddOrUpdate,
  enableValue: 'update_an_existing_location',
  conditionalChildren: [
    addressNode({
      nameOfFormControl: HelpFormFieldNames.UpdateLocationNewAddress,
      labelText: 'New location business address (to be added)',
      prefix: HelpFormFieldNames.UpdateLocationNewAddress,
      required: true,
    }),
  ],
};

const changeCoverageReasonField: TextAreaNode = {
  primitive: 'TEXT_AREA',
  nameOfFormControl: HelpFormFieldNames.ChangeCoverageReason,
  inputId: HelpFormFieldNames.ChangeCoverageReason,
  labelText: `Please explain why the insured is requesting this change.
              Be sure to describe what prompted the insured to make this
              change after coverage was originally bound.`,
  required: true,
};

const changeCoverageReasonFields: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.ChangeCoverageType, HelpFormFieldNames.ChangeCoverageAddRemove],
  enableEvaluator: EvaluatorName.HAS_CHANGE_COVERAGE_REASON,
  conditionalChildren: [changeCoverageReasonField],
};

const changeCoverageInsuredUpdatesFields: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: HelpFormFieldNames.ChangeCoverageType,
  enableEvaluator: EvaluatorName.HAS_CHANGE_COVERAGE_INSURED_UPDATES,
  conditionalChildren: [
    {
      primitive: 'RADIO',
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageInsuredUpdates,
      inputId: HelpFormFieldNames.ChangeCoverageInsuredUpdates,
      labelText:
        "Has there been any change in organizational structure, operation, or insured's scope of work?",
      options: {
        Yes: 'yes_ins_updates',
        No: 'no_ins_updates',
      },
      required: true,
    },
  ],
};

const changeCoverageUpdateFields: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: HelpFormFieldNames.ChangeCoverageType,
  enableEvaluator: EvaluatorName.IS_CHANGE_COVERAGE_UPDATE,
  conditionalChildren: [
    {
      primitive: 'TEXT',
      inputId: HelpFormFieldNames.ChangeCoverageUpdateType,
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageUpdateType,
      labelText: 'Coverage you would like to update',
      required: true,
    },
  ],
};

const changeCoverageBppBuildingLimitFields: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.ChangeCoverageType,
  enableValue: 'change_bpp_building_limit',
  conditionalChildren: [
    {
      primitive: 'MONEY_WITHOUT_DECIMAL',
      inputId: HelpFormFieldNames.ChangeCoverageBppLimit,
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageBppLimit,
      labelText: 'Updated BPP limit',
      required: false,
      specifierText: '(optional)',
    },
    {
      primitive: 'MONEY_WITHOUT_DECIMAL',
      inputId: HelpFormFieldNames.ChangeCoverageBuildingLimit,
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageBuildingLimit,
      labelText: 'Updated building limit',
      required: false,
      specifierText: '(optional)',
    },
    addressNode({
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageAddress,
      labelText: 'Address of building to be updated',
      prefix: HelpFormFieldNames.ChangeCoverageAddress,
      required: true,
    }),
    {
      primitive: 'RADIO',
      inputId: HelpFormFieldNames.ChangeCoverageFullyInsured,
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageFullyInsured,
      labelText: `Please note that property coverages,
                  both building and BPP, must be covered at 100% insured to value.
                  Do you confirm that the above requested updates reflect 100% insured to value?`,
      options: {
        Yes: 'yes_100_tiv',
        No: 'no_100_tiv',
      },
      required: true,
    },
  ],
};

const changeCoverageUpdateLimitFields: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.ChangeCoverageType,
  enableValue: 'update_limit',
  conditionalChildren: [
    {
      primitive: 'MONEY_WITHOUT_DECIMAL',
      inputId: HelpFormFieldNames.ChangeCoverageUpdateLimit,
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageUpdateLimit,
      labelText: 'Requested limit',
      required: true,
    },
  ],
};

const changeCoverageUpdateDeductibleFields: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.ChangeCoverageType,
  enableValue: 'update_deductible',
  conditionalChildren: [
    {
      primitive: 'MONEY_WITHOUT_DECIMAL',
      inputId: HelpFormFieldNames.ChangeCoverageUpdateDeductible,
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageUpdateDeductible,
      labelText: 'Requested deductible',
      questionNote: `Not sure which limits/deductibles we offer?
                     See our <a href="https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Attune+BOP+Plus+UW+Guidelines.pdf" target="_blank">
                     Accredited guidelines</a>
                     for more information.`,
      required: true,
    },
  ],
};

const changeCoverageAddRemoveFields: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.ChangeCoverageType,
  enableValue: 'add_remove_coverage',
  conditionalChildren: [
    {
      primitive: 'RADIO',
      inputId: HelpFormFieldNames.ChangeCoverageAddRemove,
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageAddRemove,
      labelText: 'Would you like to add a new coverage or remove an existing coverage?',
      options: {
        'Add new': 'add_coverage',
        'Remove existing': 'remove_coverage',
      },
      required: true,
    },
  ],
};

const changeCoverageAddDescriptionFields: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.ChangeCoverageAddRemove,
  enableValue: 'add_coverage',
  conditionalChildren: [
    {
      primitive: 'TEXT_AREA',
      inputId: HelpFormFieldNames.ChangeCoverageAddDescription,
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageAddDescription,
      labelText: `Please describe the coverage you would like to add. Be sure to include
                  details about any applicable limits or deductibles. If the coverage is
                  specific to a location, please indicate which location coverage should
                  be added to.`,
      required: true,
    },
  ],
};

const changeCoverageRemoveTypeFields: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.ChangeCoverageAddRemove,
  enableValue: 'remove_coverage',
  conditionalChildren: [
    {
      primitive: 'TEXT',
      inputId: HelpFormFieldNames.ChangeCoverageRemoveType,
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageRemoveType,
      labelText: 'Coverage to remove',
      required: true,
    },
  ],
};

const changeCoverageScheduleEquipmentFields: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.ChangeCoverageType,
  enableValue: 'schedule_equipment',
  conditionalChildren: [
    {
      primitive: 'RADIO',
      inputId: HelpFormFieldNames.ChangeCoverageInsuredContractor,
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageInsuredContractor,
      labelText: 'Is the insured a contractor?',
      options: {
        Yes: 'yes_ins_contractor',
        No: 'no_ins_contractor',
      },
      questionNote: `Please note that scheduling equipment is only available to contractors.
                     To cover equipment for other class codes, please add under BPP.`,
      required: true,
    },
  ],
};

const changeCoverageLiabilityLimitFields: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.ChangeCoverageTypeWc,
  enableValue: 'update_liability_limits',
  conditionalChildren: [
    {
      primitive: 'SELECT',
      inputId: HelpFormFieldNames.ChangeCoverageLiabilityLimit,
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageLiabilityLimit,
      labelText: 'Requested limits (Each Claim, Policy Limit, Each Employee)',
      options: EMP_LIABILITY_LIMITS_OPTIONS,
      required: true,
    },
  ],
};

const changeCoverageInsuredContractorFields: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.ChangeCoverageInsuredContractor,
  enableValue: 'yes_ins_contractor',
  conditionalChildren: [
    {
      primitive: 'RADIO',
      inputId: HelpFormFieldNames.ChangeCoverageScheduleApply,
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageScheduleApply,
      labelText:
        "Would you like to schedule contractors' tools and equipment coverage or apply contractors' tools and equipment blanket coverage?",
      options: {
        'Schedule coverage': 'schedule_coverage',
        'Apply blanket': 'apply_blanket',
      },
      required: true,
    },
  ],
};

const changeCoverageDoScheduleEquipmentFields: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.ChangeCoverageScheduleApply,
  enableValue: 'schedule_coverage',
  conditionalChildren: [
    {
      primitive: 'TEXT_AREA',
      inputId: HelpFormFieldNames.ChangeCoverageEquipmentList,
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageEquipmentList,
      labelText:
        'Please provide a list of equipment to be scheduled. Be sure to include a description and value for each piece of equipment.',
      required: true,
    },
    {
      primitive: 'RADIO',
      inputId: HelpFormFieldNames.ChangeCoverageItemSublimit,
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageItemSublimit,
      labelText: 'Please select a per-item sublimit',
      options: {
        $500: 'item_sublimit_500',
        $2000: 'item_sublimit_2000',
      },
      required: true,
    },
  ],
};

const changeCoverageDoApplyBlanketFields: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.ChangeCoverageScheduleApply,
  enableValue: 'apply_blanket',
  conditionalChildren: [
    {
      primitive: 'MONEY_WITHOUT_DECIMAL',
      inputId: HelpFormFieldNames.ChangeCoverageBlanketLimit,
      nameOfFormControl: HelpFormFieldNames.ChangeCoverageBlanketLimit,
      labelText: 'Please provide the blanket limit',
      required: true,
    },
  ],
};

const requestPaymentScheduleBopTip: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LineOfBusiness,
  enableValue: 'bop',
  conditionalChildren: [
    {
      primitive: 'DIALOG',
      nameOfFormControl: UIFormFieldNames.RequestPaymentScheduleBopTip,
      inputId: UIFormFieldNames.RequestPaymentScheduleBopTip,
      type: 'primary',
      content: `<p>Did you know that you can find invoice schedules and histories on this account's invoice page?</p>\
        <a class=dialog-text-link href="${HelpCenterZendeskArticles.AccountBillingHistory}" data-amplitude-input-name="tip-request-payment-schedule-bop" target="_blank">Learn how</a>`,
      cssClass: 'mb-3',
    },
  ],
};

const makePaymentBopTip: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LineOfBusiness,
  enableValue: 'bop',
  conditionalChildren: [
    {
      primitive: 'DIALOG',
      nameOfFormControl: UIFormFieldNames.MakePaymentBopTip,
      inputId: UIFormFieldNames.MakePaymentBopTip,
      type: 'primary',
      content: `<p>Did you know that you can make a payment directly through the invoice page?</p>\
        <a class=dialog-text-link href="${HelpCenterZendeskArticles.MakePaymentBop}" data-amplitude-input-name="tip-make-payment-bop" target="_blank">Learn how</a>`,
      cssClass: 'mb-3',
    },
  ],
};

const makePaymentWcTip: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LineOfBusiness,
  enableValue: 'wc',
  conditionalChildren: [
    {
      primitive: 'DIALOG',
      nameOfFormControl: UIFormFieldNames.MakePaymentWcTip,
      inputId: UIFormFieldNames.MakePaymentWcTip,
      type: 'primary',
      content: `<p>Did you know that insureds can make payments for their workers' compensation policies directly through EMPLOYERS®' insured portal, EACCESS?</p>\
        <a class=dialog-text-link href="${HelpCenterZendeskArticles.MakePaymentWc}" data-amplitude-input-name="tip-make-payment-wc" target="_blank">Learn how</a>`,
      cssClass: 'mb-3',
    },
  ],
};

const confirmPaymentBopTip: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LineOfBusiness,
  enableValue: 'bop',
  conditionalChildren: [
    {
      primitive: 'DIALOG',
      nameOfFormControl: UIFormFieldNames.ConfirmPaymentBopTip,
      inputId: UIFormFieldNames.ConfirmPaymentBopTip,
      type: 'primary',
      content: `<p>Did you know that you can check realtime payment status for BOP / XS policies without contacting us?</p>\
        <a class=dialog-text-link href="${HelpCenterZendeskArticles.AccountBillingHistory}" data-amplitude-input-name="tip-confirm-payment-bop" target="_blank">Learn how</a>`,
      cssClass: 'mb-3',
    },
  ],
};

const enrollAutopayBopTip: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LineOfBusiness,
  enableValue: 'bop',
  conditionalChildren: [
    {
      primitive: 'DIALOG',
      nameOfFormControl: UIFormFieldNames.EnrollAutopayBopTip,
      inputId: UIFormFieldNames.EnrollAutopayBopTip,
      type: 'primary',
      content: `<p>Did you know that you can enroll in autopay directly through the invoice page?</p>\
        <a class=dialog-text-link href="${HelpCenterZendeskArticles.EnrollAutopayBop}" data-amplitude-input-name="tip-enroll-autopay-bop" target="_blank">Learn how</a>`,
      cssClass: 'mb-3',
    },
  ],
};

const enrollAutopayWcTip: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LineOfBusiness,
  enableValue: 'wc',
  conditionalChildren: [
    {
      primitive: 'DIALOG',
      nameOfFormControl: UIFormFieldNames.EnrollAutopayWcTip,
      inputId: UIFormFieldNames.EnrollAutopayWcTip,
      type: 'primary',
      content: `<p>To learn more about how to enroll in autopay for a workers' compensation policy, \
      <a class=dialog-text-link href=${HelpCenterZendeskArticles.EnrollAutopayWc} data-amplitude-input-name="tip-enroll-autopay-wc" target="_blank">click here</a>\
      </p>`,
      cssClass: 'mb-3',
    },
  ],
};

const hiscoxSubmitTip: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LineOfBusiness,
  enableEvaluator: EvaluatorName.IS_GL_OR_PL,
  conditionalChildren: [
    {
      primitive: 'DIALOG',
      nameOfFormControl: UIFormFieldNames.HiscoxSubmitTip,
      inputId: UIFormFieldNames.HiscoxSubmitTip,
      type: 'primary',
      content: `<p>All servicing requests for Hiscox policies should be submitted directly through the Hiscox portal.</p>\
      <a class=dialog-text-link href="https://www.contacthiscox.com/partner/" data-amplitude-input-name="tip-hiscox-submit" target="_blank">Submit via Hiscox portal</a>`,
      cssClass: 'mb-3',
    },
  ],
};

const requestInvoiceWcTip: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LineOfBusiness,
  enableValue: 'wc',
  conditionalChildren: [
    {
      primitive: 'DIALOG',
      nameOfFormControl: UIFormFieldNames.RequestInvoiceWcTip,
      inputId: UIFormFieldNames.RequestInvoiceWcTip,
      type: 'primary',
      content: `<p>Did you know that EMPLOYERS® mails invoices directly to insureds?</p>\
        <a class=dialog-text-link href="${HelpCenterZendeskArticles.WcInvoices}" data-amplitude-input-name="tip-request-invoice-wc" target="_blank">Learn more here</a>`,
      cssClass: 'mb-3',
    },
  ],
};

const wosTypeField: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LineOfBusiness,
  enableValue: 'attune_work_comp',
  conditionalChildren: [
    {
      primitive: 'RADIO',
      inputId: HelpFormFieldNames.WosType,
      nameOfFormControl: HelpFormFieldNames.WosType,
      labelText: 'Blanket or Specific?',
      options: {
        Blanket: 'blanket',
        Specific: 'specific',
      },
      required: true,
    },
  ],
};

const otherEndorsementNkllDocsField: EvalConditional = getNkllDocsField();

const otherEndorsementSupplementaryDocsField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: HelpFormFieldNames.EffectiveDate,
  enableEvaluator: EvaluatorName.IS_NOT_BEFORE_TODAY,
  conditionalChildren: [fileUploadField],
};

// Change effective date field
const newEffectiveDateField = dateNode({
  nameOfFormControl: HelpFormFieldNames.NewEffectiveDate,
  labelText: 'New effective date',
  required: true,
});

const newEffectiveDateReasonField: TextAreaNode = {
  primitive: 'TEXT_AREA',
  nameOfFormControl: HelpFormFieldNames.NewEffectiveDateReason,
  inputId: HelpFormFieldNames.NewEffectiveDateReason,
  labelText: 'Reason for new effective date',
  required: true,
};

const newEffectiveDateBindRequestField: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: HelpFormFieldNames.NewEffectiveDate,
  enableEvaluator: EvaluatorName.IS_BEFORE_TODAY,
  conditionalChildren: [
    fileUploadNode({
      nameOfFormControl: UIFormFieldNames.ProofOfBindRequest,
      labelText: 'Please upload a proof of original bind request with the correct date',
      required: true,
    }),
  ],
};

const newEffectiveDateNkllField = getNkllDocsField({
  dependsOn: HelpFormFieldNames.NewEffectiveDate,
  required: true,
});

// Named insured fields
const namedInsuredChangeTypeField: MultiInputNode = {
  primitive: 'RADIO',
  nameOfFormControl: HelpFormFieldNames.NamedInsuredChangeType,
  inputId: HelpFormFieldNames.NamedInsuredChangeType,
  labelText: 'What would you like to do?',
  options: {
    'Update primary named insured': 'update_primary_named_insured',
    'Add a named insured': 'add_an_additional_named_insured',
  },
  required: true,
};

const namedInsuredAdditionalFields: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.NamedInsuredChangeType,
  enableValue: 'add_an_additional_named_insured',
  conditionalChildren: [
    {
      primitive: 'TEXT',
      nameOfFormControl: HelpFormFieldNames.NamedInsuredAdditionalName,
      inputId: HelpFormFieldNames.NamedInsuredAdditionalName,
      inputType: 'text',
      labelText: 'Name of insured',
      required: true,
    },
    addressNode({
      nameOfFormControl: HelpFormFieldNames.NamedInsuredAdditionalAddress,
      labelText: 'Named insured mailing address',
      prefix: HelpFormFieldNames.NamedInsuredAdditionalAddress,
      required: true,
    }),
    {
      primitive: 'EVAL-CONDITIONAL',
      dependsOn: [HelpFormFieldNames.LineOfBusiness, HelpFormFieldNames.NamedInsuredChangeType],
      enableEvaluator: EvaluatorName.IS_ATTUNE_WC_ADD_NI,
      conditionalChildren: [
        {
          primitive: 'FEIN',
          nameOfFormControl: HelpFormFieldNames.NamedInsuredAdditionalFein,
          inputId: HelpFormFieldNames.NamedInsuredAdditionalFein,
          labelText: 'Named insured FEIN',
          required: true,
        },
      ],
    },
    {
      primitive: 'RADIO',
      nameOfFormControl: HelpFormFieldNames.NamedInsuredAdditionalCommonOwnership,
      inputId: HelpFormFieldNames.NamedInsuredAdditionalCommonOwnership,
      labelText:
        'Does the named insured entity share at least 51% common ownership with the current named insured?',
      options: {
        Yes: 'yes_common_ownership',
        No: 'no_common_ownership',
      },
      required: true,
    },
    {
      primitive: 'TEXT_AREA',
      nameOfFormControl: HelpFormFieldNames.NamedInsuredAdditionalOperations,
      inputId: HelpFormFieldNames.NamedInsuredAdditionalOperations,
      labelText: "What are the named insured's operations?",
      required: true,
    },
  ],
};

const namedInsuredPrimaryFields: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.NamedInsuredChangeType,
  enableValue: 'update_primary_named_insured',
  conditionalChildren: [
    {
      primitive: 'TEXT',
      nameOfFormControl: HelpFormFieldNames.NamedInsuredPrimaryName,
      inputId: HelpFormFieldNames.NamedInsuredPrimaryName,
      inputType: 'text',
      labelText: 'Name of primary named insured',
      required: true,
    },
    addressNode({
      nameOfFormControl: HelpFormFieldNames.NamedInsuredPrimaryAddress,
      labelText: 'Primary named insured mailing address',
      prefix: HelpFormFieldNames.NamedInsuredPrimaryAddress,
      required: true,
    }),
  ],
};

const namedInsuredWcChangeOwner: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: [HelpFormFieldNames.LineOfBusiness, HelpFormFieldNames.NamedInsuredChangeType],
  enableEvaluator: EvaluatorName.IS_ATTUNE_WC_UPDATE_NI,
  conditionalChildren: [
    {
      primitive: 'RADIO',
      nameOfFormControl: HelpFormFieldNames.NamedInsuredChangeInOwnershipWc,
      inputId: HelpFormFieldNames.NamedInsuredChangeInOwnershipWc,
      labelText: 'Has there been any change in ownership, Experience Modification, or FEIN?',
      options: {
        Yes: 'yes_ni_wc_owner_changes',
        No: 'no_ni_wc_owner_changes',
      },
      required: true,
    },
    {
      primitive: 'EVAL-CONDITIONAL',
      dependsOn: [
        HelpFormFieldNames.LineOfBusiness,
        HelpFormFieldNames.NamedInsuredChangeType,
        HelpFormFieldNames.NamedInsuredChangeInOwnershipWc,
      ],
      enableEvaluator: EvaluatorName.IS_ATTUNE_WC_UPDATE_NI_OWNER_CHANGES,
      conditionalChildren: [
        {
          primitive: 'CHECKBOX',
          inputId: UIFormFieldNames.NamedInsuredChangeOwnerChecked,
          nameOfFormControl: UIFormFieldNames.NamedInsuredChangeOwnerChecked,
          labelText: 'Change in ownership',
        },
        {
          primitive: 'CHECKBOX',
          inputId: UIFormFieldNames.NamedInsuredChangeEModChecked,
          nameOfFormControl: UIFormFieldNames.NamedInsuredChangeEModChecked,
          labelText: 'Experience Modification',
        },
        {
          primitive: 'CHECKBOX',
          inputId: UIFormFieldNames.NamedInsuredChangeFeinChecked,
          nameOfFormControl: UIFormFieldNames.NamedInsuredChangeFeinChecked,
          labelText: 'FEIN',
        },
        {
          primitive: 'EVAL-CONDITIONAL',
          dependsOn: [
            HelpFormFieldNames.LineOfBusiness,
            HelpFormFieldNames.NamedInsuredChangeType,
            HelpFormFieldNames.NamedInsuredChangeInOwnershipWc,
            UIFormFieldNames.NamedInsuredChangeEModChecked,
          ],
          enableEvaluator: EvaluatorName.IS_ATTUNE_WC_UPDATE_NI_OWNER_CHANGES_EMOD,
          conditionalChildren: [
            {
              primitive: 'TEXT',
              nameOfFormControl: HelpFormFieldNames.NamedInsuredChangeEMod,
              inputId: HelpFormFieldNames.NamedInsuredChangeEMod,
              labelText: 'Updated E-Mod',
              required: true,
              validators: [ValidatorName.VALID_E_MOD],
              errorText: 'Please enter a valid experience modification rating factor (ex: 1.30)',
            },
          ],
        },
        {
          primitive: 'EVAL-CONDITIONAL',
          dependsOn: [
            HelpFormFieldNames.LineOfBusiness,
            HelpFormFieldNames.NamedInsuredChangeType,
            HelpFormFieldNames.NamedInsuredChangeInOwnershipWc,
            UIFormFieldNames.NamedInsuredChangeFeinChecked,
          ],
          enableEvaluator: EvaluatorName.IS_ATTUNE_WC_UPDATE_NI_OWNER_CHANGES_FEIN,
          conditionalChildren: [
            {
              primitive: 'FEIN',
              nameOfFormControl: HelpFormFieldNames.NamedInsuredChangeFein,
              inputId: HelpFormFieldNames.NamedInsuredChangeFein,
              labelText: 'Updated FEIN',
              required: true,
            },
          ],
        },
        {
          primitive: 'EVAL-CONDITIONAL',
          dependsOn: [
            HelpFormFieldNames.LineOfBusiness,
            HelpFormFieldNames.NamedInsuredChangeType,
            HelpFormFieldNames.NamedInsuredChangeInOwnershipWc,
            HelpFormFieldNames.InsuredState,
            UIFormFieldNames.NamedInsuredChangeOwnerChecked,
            UIFormFieldNames.NamedInsuredChangeFeinChecked,
          ],
          enableEvaluator: EvaluatorName.INCLUDE_ERM_14_UPLOAD,
          conditionalChildren: [
            fileUploadNode({
              nameOfFormControl: UIFormFieldNames.WcErm14Form,
              labelText:
                'Please submit an <a href="https://www.ncci.com/Articles/Pages/UW_ERM14_Form.aspx" target="_blank">ERM-14 form to the NCCI</a> and upload it below',
              required: true,
            }),
          ],
        },
        {
          primitive: 'EVAL-CONDITIONAL',
          dependsOn: [
            HelpFormFieldNames.LineOfBusiness,
            HelpFormFieldNames.NamedInsuredChangeType,
            HelpFormFieldNames.NamedInsuredChangeInOwnershipWc,
            HelpFormFieldNames.InsuredState,
            UIFormFieldNames.NamedInsuredChangeOwnerChecked,
            UIFormFieldNames.NamedInsuredChangeFeinChecked,
          ],
          enableEvaluator: EvaluatorName.INCLUDE_ERM_14_UPLOAD_MI,
          conditionalChildren: [
            fileUploadNode({
              nameOfFormControl: UIFormFieldNames.WcErm14FormMi,
              labelText:
                'Please submit an <a href="https://caom.com/Portals/0/ERM14Form.pdf" target="_blank">ERM-14 form</a> to service@caom.com and upload it below',
              required: true,
            }),
          ],
        },
        {
          primitive: 'EVAL-CONDITIONAL',
          dependsOn: [
            HelpFormFieldNames.LineOfBusiness,
            HelpFormFieldNames.NamedInsuredChangeType,
            HelpFormFieldNames.NamedInsuredChangeInOwnershipWc,
            HelpFormFieldNames.InsuredState,
            UIFormFieldNames.NamedInsuredChangeOwnerChecked,
            UIFormFieldNames.NamedInsuredChangeFeinChecked,
          ],
          enableEvaluator: EvaluatorName.INCLUDE_ERM_14_UPLOAD_PA,
          conditionalChildren: [
            fileUploadNode({
              nameOfFormControl: UIFormFieldNames.WcErm14FormPa,
              labelText:
                'Please submit an <a href="https://www.pcrb.com/industry-resources/forms" target="_blank">ERM-14 form</a> to centralsupport@pcrb.com and upload it below',
              required: true,
            }),
          ],
        },
      ],
    },
  ],
};

const namedInsuredNonWc: EvalConditional = {
  primitive: 'EVAL-CONDITIONAL',
  dependsOn: HelpFormFieldNames.LineOfBusiness,
  enableEvaluator: EvaluatorName.IS_NOT_ATTUNE_WC,
  conditionalChildren: [
    {
      primitive: 'TEXT_AREA',
      nameOfFormControl: HelpFormFieldNames.NamedInsuredExplanation,
      inputId: HelpFormFieldNames.NamedInsuredExplanation,
      labelText: 'Please explain why the insured has requested this endorsement.',
      required: true,
    },
    {
      primitive: 'RADIO',
      nameOfFormControl: HelpFormFieldNames.NamedInsuredChangeInOperation,
      inputId: HelpFormFieldNames.NamedInsuredChangeInOperation,
      labelText: 'Has there been a change in ownership, structure, or operations?',
      options: {
        Yes: 'yes_ni_owner_changes',
        No: 'no_ni_owner_changes',
      },
      required: true,
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: HelpFormFieldNames.NamedInsuredChangeInOperation,
      enableValue: 'yes_ni_owner_changes',
      conditionalChildren: [
        {
          primitive: 'TEXT_AREA',
          nameOfFormControl: HelpFormFieldNames.NamedInsuredChangeDescription,
          inputId: HelpFormFieldNames.NamedInsuredChangeDescription,
          labelText: 'Please describe the changes in operations and/or ownership structure.',
          required: true,
        },
      ],
    },
  ],
};

// Update sales and payroll (exposures) fields

const exposuresProjectedSalesField: TextNode = {
  primitive: 'MONEY_WITHOUT_DECIMAL',
  inputId: HelpFormFieldNames.ExposuresProjectedSales,
  nameOfFormControl: HelpFormFieldNames.ExposuresProjectedSales,
  labelText: 'Updated projected sales',
  specifierText: '(optional)',
  required: false,
};

const exposuresPayrollField: TextNode = {
  primitive: 'MONEY_WITHOUT_DECIMAL',
  inputId: HelpFormFieldNames.ExposuresPayroll,
  nameOfFormControl: HelpFormFieldNames.ExposuresPayroll,
  labelText: 'Updated payroll',
  specifierText: '(optional)',
  required: false,
};

const exposuresBusinessTypeField: MultiInputNode = {
  primitive: 'RADIO',
  nameOfFormControl: HelpFormFieldNames.ExposuresBusinessType,
  inputId: HelpFormFieldNames.ExposuresBusinessType,
  labelText: 'Please select the business type',
  options: {
    Restaurant: 'business_type_restaurant',
    Contractor: 'business_type_contractor',
    Other: 'business_type_other',
  },
  required: true,
};

const exposuresSalesLessThanProjectedField: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.ExposuresBusinessType,
  enableValue: 'business_type_restaurant',
  conditionalChildren: [
    {
      primitive: 'RADIO',
      nameOfFormControl: HelpFormFieldNames.ExposuresSalesLessThanProjected,
      inputId: HelpFormFieldNames.ExposuresSalesLessThanProjected,
      labelText: 'Is the updated projected sales less than 65% of the original?',
      options: {
        Yes: 'yes_sales_less_than_expected',
        No: 'no_sales_less_than_expected',
      },
      required: true,
    },
  ],
};

const exposuresTaxDocsFields: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.ExposuresSalesLessThanProjected,
  enableValue: 'yes_sales_less_than_expected',
  conditionalChildren: [
    fileUploadNode({
      nameOfFormControl: UIFormFieldNames.ExposuresTaxReturns,
      labelText:
        'Please upload either the last 2 quarters of sales tax returns, or the last 12 months of tax documents.',
      required: true,
    }),
  ],
};

const exposuresPayrollDecreasingField: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.ExposuresBusinessType,
  enableValue: 'business_type_contractor',
  conditionalChildren: [
    {
      primitive: 'RADIO',
      nameOfFormControl: HelpFormFieldNames.ExposuresPayrollDecreasing,
      inputId: HelpFormFieldNames.ExposuresPayrollDecreasing,
      labelText: 'Is the updated payroll less than the original?',
      options: {
        Yes: 'yes_payroll_decreasing',
        No: 'no_payroll_decreasing',
      },
      required: true,
    },
  ],
};

const exposuresNewPayrollConfirmationField: ValueConditional = {
  primitive: 'VALUE-CONDITIONAL',
  dependsOn: HelpFormFieldNames.ExposuresPayrollDecreasing,
  enableValue: 'yes_payroll_decreasing',
  conditionalChildren: [
    fileUploadNode({
      nameOfFormControl: UIFormFieldNames.ExposuresNewPayrollConfirmation,
      labelText: 'Please upload a signed letter confirming the new payroll amount',
      required: true,
    }),
  ],
};

// End Form DSL field configs

// Form DSL form configs

export const DEFAULT_FORM_CONFIG: FormDslNode[] = [
  accountField,
  lineOfBusinessField,
  hiscoxSubmitTip,
  policyField,
  policyValidationMessage,
  fileUploadField,
];

const defaultFormWithStateRequired: FormDslNode[] = [
  accountField,
  lineOfBusinessField,
  insuredStateField,
  hiscoxSubmitTip,
  policyField,
  policyValidationMessage,
  fileUploadField,
];

// Used for tickets unrelated to accounts/policies
const generalFormConfig: FormDslNode[] = [fileUploadField];

// Form DSL form configs -- Agency
const updateComBankConfig: FormDslNode[] = [updateCommissionBankDialogField];

// Form DSL form configs -- Billing / Contacts
const updateInsuredEmailConfig: FormDslNode[] = [
  accountRequiredField,
  { ...insuredEmailField, labelText: 'New insured billing email address' },
];

// Form DSL form configs -- Billing / Refunds
const updateRefundBankConfig: FormDslNode[] = [
  updateRefundBankDialogField,
  checkMailingAddressField,
];

// Form DSL form configs -- Billing / Request Payment
const requestPaymentFormConfig: FormDslNode[] = [
  accountField,
  lineOfBusinessField,
  insuredStateField,
  hiscoxSubmitTip,
  policyField,
  policyValidationMessage,
  requestPaymentScheduleBopTip,
  fileUploadField,
];

// Form DSL form configs -- Billing / Make Payment
const makePaymentFormConfig: FormDslNode[] = [
  accountField,
  lineOfBusinessField,
  insuredStateField,
  hiscoxSubmitTip,
  policyField,
  policyValidationMessage,
  makePaymentBopTip,
  makePaymentWcTip,
  fileUploadField,
];

// Form DSL form configs -- Billing / Confirm Payment
const confirmPaymentFormConfig: FormDslNode[] = [
  accountField,
  lineOfBusinessField,
  insuredStateField,
  hiscoxSubmitTip,
  policyField,
  policyValidationMessage,
  confirmPaymentBopTip,
  fileUploadField,
];

// Form DSL form configs -- Billing / Enroll Autopay
const enrollAutopayFormConfig: FormDslNode[] = [
  accountField,
  lineOfBusinessField,
  hiscoxSubmitTip,
  policyField,
  policyValidationMessage,
  enrollAutopayBopTip,
  enrollAutopayWcTip,
  fileUploadField,
];

// Form DSL form configs -- Billing / Request Invoice
const requestInvoiceFormConfig: FormDslNode[] = [
  accountField,
  lineOfBusinessField,
  insuredStateField,
  hiscoxSubmitTip,
  policyField,
  policyValidationMessage,
  requestInvoiceWcTip,
  fileUploadField,
];

// Form DSL form configs -- Insured Account
const requestBorConfig: FormDslNode[] = [
  accountField,
  lineOfBusinessField,
  insuredStateField,
  hiscoxSubmitTip,
  policyField,
  policyValidationMessage,
  borDocumentsField,
];

// Form DSL form configs -- Policy / Cancellations
const cancellationFormConfig: FormDslNode[] = [
  cancelRequestEffectiveDateField,
  accountField,
  lineOfBusinessField,
  insuredStateField,
  hiscoxSubmitTip,
  policyRequiredField,
  policyValidationMessage,
  cancellationPolicyType,
  cancellationReasonField,
  cancellationReasonOtherField,
  lprDocumentsField,
  lprAndBusinessClosedDocumentsField,
  lprAndBusinessSoldDocumentsField,
  lprAndCoverageReplacedDocumentsField,
  cancellationAcknowledgementField,
];

// Form DSL form configs -- Policy / Reinstatements
const requestReinstatementFormConfig: FormDslNode[] = [
  cancellationEffectiveDateField,
  accountField,
  lineOfBusinessField,
  insuredStateField,
  hiscoxSubmitTip,
  policyRequiredField,
  policyValidationMessage,
  meetsEligibilityGuidelinesField,
  reinstatementNkllDocsField,
];

// Form DSL form configs -- Policy / Endorsements
const additionalInsuredFormConfig: FormDslNode[] = [
  effectiveDateField,
  accountField,
  lineOfBusinessField,
  hiscoxSubmitTip,
  policyRequiredField,
  policyValidationMessage,
  mismatchedAccountPolicyWarning,
  aiAddOrUpdateField,
  aiNonePresentWarning,
  aiNameField,
  aiNameToUpdateField,
  aiIdToUpdateField,
  aiUpdateNameField,
  aiUpdateAddressField,
  aiUpdateTypeField,
  aiUpdateBuildingField,
  aiUpdateLocationField,
  aiAddressField,
  aiTypeField,
  aiBuildingField,
  aiLocationField,
  aiInsurableInterestField,
  aiLocationOfCoveredOpsField,
  aiLeasedEquipmentField,
  aiParagraphAbcField,
  aiCompletedOperationsField,
  aiVendorProductsField,
  aiNkllDocsField,
  addAiNkllDocsField,
  multipleRequestTip,
  aiSupplementaryDocsField,
];

// Form DSL form configs -- Policy / Request COI
const requestCOIConfig: FormDslNode[] = [
  accountRequiredField,
  insuredStateField,
  mismatchedUserAccountWarning,
  lineOfBusinessField,
  policyField,
  fileUploadField,
];

const addWaivorOfSubrogationFormConfig: FormDslNode[] = [
  effectiveDateField,
  accountField,
  lineOfBusinessField,
  insuredStateField,
  hiscoxSubmitTip,
  policyRequiredField,
  policyValidationMessage,
  wosNonWc,
  wosTypeField,
  wosSpecific,
  otherEndorsementNkllDocsField,
  multipleRequestTip,
  fileUploadField,
];

const changeCoverageConfig: FormDslNode[] = [
  effectiveDateField,
  accountField,
  lineOfBusinessField,
  insuredStateField,
  hiscoxSubmitTip,
  policyRequiredField,
  policyValidationMessage,
  changeCoverageTypeField,
  changeCoverageTypeWcField,
  changeCoverageAddRemoveFields,
  changeCoverageAddDescriptionFields,
  changeCoverageRemoveTypeFields,
  changeCoverageBppBuildingLimitFields,
  changeCoverageUpdateFields,
  changeCoverageUpdateLimitFields,
  changeCoverageUpdateDeductibleFields,
  changeCoverageScheduleEquipmentFields,
  changeCoverageLiabilityLimitFields,
  changeCoverageInsuredContractorFields,
  changeCoverageDoScheduleEquipmentFields,
  changeCoverageDoApplyBlanketFields,
  changeCoverageReasonFields,
  changeCoverageInsuredUpdatesFields,
  nkllDocsField,
  updateDeductibleBackdatedNkllDocsField,
  multipleRequestTip,
  supplementaryDocsField,
];

const changeEffectiveDateFormConfig: FormDslNode[] = [
  accountField,
  lineOfBusinessField,
  insuredStateField,
  policyRequiredField,
  policyValidationMessage,
  newEffectiveDateField,
  newEffectiveDateReasonField,
  newEffectiveDateBindRequestField,
  newEffectiveDateNkllField,
  multipleRequestTip,
  fileUploadField,
];
const updateBusinessInfoConfig: FormDslNode[] = [
  effectiveDateField,
  accountField,
  lineOfBusinessField,
  insuredStateField,
  hiscoxSubmitTip,
  policyRequiredField,
  policyValidationMessage,
  dbaField,
  businessMailingAddressField,
  multipleRequestTip,
  fileUploadField,
];

const updateSalesPayrollFormConfig: FormDslNode[] = [
  effectiveDateField,
  accountField,
  lineOfBusinessField,
  insuredStateField,
  policyRequiredField,
  policyValidationMessage,
  exposuresProjectedSalesField,
  exposuresPayrollField,
  exposuresBusinessTypeField,
  exposuresSalesLessThanProjectedField,
  exposuresTaxDocsFields,
  exposuresPayrollDecreasingField,
  exposuresNewPayrollConfirmationField,
  otherEndorsementNkllDocsField,
  multipleRequestTip,
  fileUploadField,
];

const namedInsuredFormConfig: FormDslNode[] = [
  effectiveDateField,
  accountField,
  lineOfBusinessField,
  insuredStateField,
  policyRequiredField,
  policyValidationMessage,
  namedInsuredChangeTypeField,
  namedInsuredAdditionalFields,
  namedInsuredPrimaryFields,
  namedInsuredWcChangeOwner,
  namedInsuredNonWc,
  otherEndorsementNkllDocsField,
  multipleRequestTip,
  fileUploadField,
];

const locationConfig: FormDslNode[] = [
  // Fields shown for all types of location change
  effectiveDateField,
  locationAddOrUpdateField,
  accountField,
  lineOfBusinessField,
  insuredStateField,
  hiscoxSubmitTip,
  policyRequiredField,
  policyValidationMessage,
  // Address fields
  addLocationAddressField,
  removeLocationRemoveAddressField,
  updateLocationOldAddressField,
  updateLocationNewAddressField,
  // Add/Update fields
  numberOfEmployeesField,
  annualRevenueField,
  annualPayrollField,
  interestField,
  tenantBuildingPersonPropertyLimitField,
  ownerBuildingPersonalPropertyLimitField,
  buildingLimitField,
  constructionTypeField,
  squareFootageField,
  numberOfStoriesField,
  yearBuiltField,
  roofUpdateField,
  electricPlumbingHvacUpdatedField,
  automaticSprinklerField,
  meansOfEgressField,
  addLocationReasonField,
  updateLocationReasonField,
  removeLocationReasonField,
  changeInOperationField,
  multipleRequestTip,
  locationFileUploadField,
  locationNkllDocsField,
];

const editRenewalConfig: FormDslNode[] = [
  effectiveDateField,
  accountField,
  lineOfBusinessField,
  hiscoxSubmitTip,
  policyRequiredField,
  policyValidationMessage,
  descriptionOfChangeField,
  fileUploadField,
];

const otherEndorsementConfig: FormDslNode[] = [
  effectiveDateField,
  accountField,
  lineOfBusinessField,
  insuredStateField,
  hiscoxSubmitTip,
  policyField,
  policyValidationMessage,
  otherEndorsementNkllDocsField,
  otherEndorsementSupplementaryDocsField,
];

// Form DSL form configs -- Quote
const defaultQuoteConfig: FormDslNode[] = [accountField, quoteNumberField, fileUploadField];
const defaultQuoteConfigWithState: FormDslNode[] = [
  accountField,
  quoteNumberField,
  insuredStateField,
  fileUploadField,
];

const addBuildingConfig: FormDslNode[] = [
  accountField,
  quoteNumberField,
  newBusinessAppDocsField,
  multipleRequestTip,
];

const bindQuoteConfig: FormDslNode[] = [
  effectiveDateField,
  accountRequiredField,
  quoteNumberRequiredField,
  insuredStateField,
  insuredEmailField,
  paymentPlanField,
  bindQuoteNkllDocsField,
  fileUploadField,
];

const bindCpspQuoteConfig: FormDslNode[] = [
  effectiveDateField,
  accountRequiredField,
  quoteNumberMixedRequiredField,
  {
    ...insuredEmailField,
    subLabelText:
      'Once the quote is bound, a Liberty Mutual invoice will be sent directly to the insured at this email address so they may submit their payment information',
  },
  fileUploadField,
];

const changeQuoteConfig: FormDslNode[] = [
  accountRequiredField,
  quoteNumberRequiredField,
  descriptionOfChangeField,
  fileUploadField,
];

const quoteReviewConfig: FormDslNode[] = [
  accountRequiredField,
  quoteNumberRequiredField,
  insuredStateField,
  declineReasonField,
  fileUploadField,
];

const bindBlockedQuoteConfig: FormDslNode[] = [
  effectiveDateField,
  accountField,
  quoteNumberRequiredField,
  insuredStateField,
  insuredEmailField,
  paymentPlanField,
  splitPPCField,
  employeesPerLocationField,
];

const assistLroConfig: FormDslNode[] = [accountField, quoteNumberField, lroSupplementaryDocsField];

// Form DSL form configs -- Commission
const requestCommissionFormConfig: FormDslNode[] = [
  commissionStatementDialogField,
  statementDateField,
  amountReceivedField,
  fileUploadField,
];

const requestIvansFormConfig: FormDslNode[] = [producerCodeField, userIdField, accountYNumberField];

const newIvansFormConfig: FormDslNode[] = [
  producerCodeField,
  agencyNameField,
  agencyPhoneNumberField,
  agencyPhysicalAddressField,
  agencyMailingAddressDifferent,
  agencyMailingAddressField,
  agencyContactPersonFields,
  agencyContactPersonPhoneNumberField,
  agencyContactPersonEmailField,
  agencyManagementSystemFields,
];

const resolveQuoteContingency: FormDslNode[] = [
  accountField,
  quoteNumberMixedRequiredField,
  lineOfBusinessField,
  supplementaryDocsField,
];
// End Form DSL Configs

// Zendesk API IDs
export enum HelpFormCategoryNames {
  Agency = 'Agency',
  Autopay = 'Autopay',
  Billing = 'Billing',
  Cancellations = 'Cancellations',
  Claims = 'Claims',
  Commission = 'Commission',
  Contacts = 'Contacts',
  ConfirmEligibility = 'Confirm eligibility',
  Documents = 'Documents',
  Eligibility = 'Eligibility',
  Endorsements = 'Endorsements',
  Feedback = 'Feedback',
  InsuredAccount = 'Insured Account',
  Payments = 'Payments',
  Policy = 'Policy',
  Other = 'Other',
  Quote = 'Quote',
  Refunds = 'Refunds',
  Reinstatements = 'Reinstatements',
  Renewals = 'Renewals',
  TechnicalSupport = 'Technical support',
}

// Zendesk SANDBOX environment
export const UI_FORM_NAME_TO_ZENDESK_FORM_ID_SANDBOX: {
  [key in HelpFormCategoryNames]?: number;
} = {
  [HelpFormCategoryNames.Agency]: 360001319691,
  [HelpFormCategoryNames.Billing]: 360001319711,
  [HelpFormCategoryNames.Claims]: 360001308632,
  [HelpFormCategoryNames.Documents]: 360001319771,
  [HelpFormCategoryNames.Feedback]: 360001319791,
  [HelpFormCategoryNames.InsuredAccount]: 360001319731,
  [HelpFormCategoryNames.Policy]: 360001319651,
  [HelpFormCategoryNames.Other]: 4411857364635,
  [HelpFormCategoryNames.Quote]: 360001319751,
  [HelpFormCategoryNames.TechnicalSupport]: 360001308652,
};

export const INBOUND_INQUIRY_TYPE_FIELD_ID_SANDBOX = 360040320671;

export const MESSAGE_FIELD_ID_SANDBOX = 360040622191;

export const UI_FIELD_NAME_TO_ZENDESK_FIELD_ID_SANDBOX: {
  [key in HelpFormFieldNames]: number;
} = {
  [HelpFormFieldNames.Account]: 360020406531,
  [HelpFormFieldNames.AccountYNumber]: 1260823253870,
  [HelpFormFieldNames.AgencyContactPersonFirstName]: 6660154958875,
  [HelpFormFieldNames.AgencyContactPersonLastName]: 6660129660187,
  [HelpFormFieldNames.AgencyContactPersonPhoneNumber]: 6660146177691,
  [HelpFormFieldNames.AgencyContactPersonEmail]: 6660177976603,
  [HelpFormFieldNames.AgencyMailingAddress]: 6660320736027,
  [HelpFormFieldNames.AgencyManagementSystem]: 6662130130843,
  [HelpFormFieldNames.AgencyManagementSystemVersion]: 6662722793115,
  [HelpFormFieldNames.AgencyName]: 6613903655323,
  [HelpFormFieldNames.AgencyPhoneNumber]: 6628123145243,
  [HelpFormFieldNames.AgencyPhysicalAddress]: 6628638913051,
  [HelpFormFieldNames.AddLocationReason]: 1260827742389,
  [HelpFormFieldNames.AiAddOrUpdate]: 360040561011,
  [HelpFormFieldNames.AiName]: 360040439551,
  [HelpFormFieldNames.AiIdToUpdate]: 7761221661467,
  [HelpFormFieldNames.AiNameToUpdate]: 1260828455090,
  [HelpFormFieldNames.AiUpdateName]: 1260828455110,
  [HelpFormFieldNames.AiUpdateAddress]: 1900007999504,
  [HelpFormFieldNames.AiUpdateType]: 1260828357609,
  [HelpFormFieldNames.AiUpdateBuilding]: 9163251342875,
  [HelpFormFieldNames.AiUpdateLocation]: 9163143209243,
  [HelpFormFieldNames.AiInsurableInterest]: 1260828356969,
  [HelpFormFieldNames.AiLeasedEquipment]: 1900007999064,
  [HelpFormFieldNames.AiParagraphAbc]: 1900007999084,
  [HelpFormFieldNames.AiCompletedOperations]: 1900007999104,
  [HelpFormFieldNames.AiVendorProducts]: 1260828454910,
  [HelpFormFieldNames.AiType]: 360020961912,
  [HelpFormFieldNames.AiBuilding]: 9163630795675,
  [HelpFormFieldNames.AiLocation]: 9163555173659,
  [HelpFormFieldNames.AmountReceived]: 360042446951,
  [HelpFormFieldNames.AnnualRevenue]: 1260828271849,
  [HelpFormFieldNames.AnnualPayroll]: 1260828271829,
  [HelpFormFieldNames.AutomaticSprinklers]: 1260827762609,
  [HelpFormFieldNames.BusinessAddress]: 360021490912,
  [HelpFormFieldNames.BuildingPersonalPropertyLimitOwner]: 1260828271889,
  [HelpFormFieldNames.BuildingPersonalPropertyLimitTenant]: 1260828271909,
  [HelpFormFieldNames.BuildingLimit]: 1260828271869,
  [HelpFormFieldNames.CancellationDate]: 360034593452,
  [HelpFormFieldNames.CancellationPolicyType]: 1260828453810,
  [HelpFormFieldNames.CancellationReason]: 360034574312,
  [HelpFormFieldNames.CancellationReasonOther]: 1260828356749,
  [HelpFormFieldNames.ChangeCoverageAddDescription]: 1260831629589,
  [HelpFormFieldNames.ChangeCoverageAddRemove]: 1260830870430,
  [HelpFormFieldNames.ChangeCoverageAddress]: 1900010210084,
  [HelpFormFieldNames.ChangeCoverageBlanketLimit]: 1260831634849,
  [HelpFormFieldNames.ChangeCoverageBppLimit]: 1900010210264,
  [HelpFormFieldNames.ChangeCoverageBuildingLimit]: 1900010210324,
  [HelpFormFieldNames.ChangeCoverageEquipmentList]: 1260830874230,
  [HelpFormFieldNames.ChangeCoverageFullyInsured]: 1260830787510,
  [HelpFormFieldNames.ChangeCoverageInsuredContractor]: 1260831634889,
  [HelpFormFieldNames.ChangeCoverageInsuredUpdates]: 1260830787690,
  [HelpFormFieldNames.ChangeCoverageItemSublimit]: 1900010282164,
  [HelpFormFieldNames.ChangeCoverageLiabilityLimit]: 25551094862491,
  [HelpFormFieldNames.ChangeCoverageReason]: 1900010211064,
  [HelpFormFieldNames.ChangeCoverageRemoveType]: 1260830869430,
  [HelpFormFieldNames.ChangeCoverageScheduleApply]: 1900010282344,
  [HelpFormFieldNames.ChangeCoverageType]: 360040497992,
  [HelpFormFieldNames.ChangeCoverageTypeWc]: 360040497992,
  [HelpFormFieldNames.ChangeCoverageUpdateDeductible]: 1260830860390,
  [HelpFormFieldNames.ChangeCoverageUpdateLimit]: 1260830859530,
  [HelpFormFieldNames.ChangeCoverageUpdateType]: 1900010273284,
  [HelpFormFieldNames.ChangeInOperation]: 360040442891,
  [HelpFormFieldNames.CheckMailingAddress]: 360043003612,
  [HelpFormFieldNames.ClericalError]: 360040500452,
  [HelpFormFieldNames.ConstructionType]: 1900007356364,
  [HelpFormFieldNames.Dba]: 360040496252,
  [HelpFormFieldNames.DeclineReason]: 360041283231,
  [HelpFormFieldNames.DescriptionOfChange]: 360040679252,
  [HelpFormFieldNames.EffectiveDate]: 360034613772,
  [HelpFormFieldNames.ElectricPlumbingHvacUpdated]: 1260827870090,
  [HelpFormFieldNames.ExposuresProjectedSales]: 8005886898459,
  [HelpFormFieldNames.ExposuresPayroll]: 8006112402843,
  [HelpFormFieldNames.ExposuresBusinessType]: 8019249670043,
  [HelpFormFieldNames.ExposuresSalesLessThanProjected]: 8019384321691,
  [HelpFormFieldNames.ExposuresPayrollDecreasing]: 8019343500443,
  [HelpFormFieldNames.InsuredEmail]: 360031929912,
  [HelpFormFieldNames.InsuredState]: 19127128958107,
  [HelpFormFieldNames.Interest]: 1900007027104,
  [HelpFormFieldNames.LineOfBusiness]: 360040456231,
  [HelpFormFieldNames.LocationAddOrUpdate]: 360040442871,
  [HelpFormFieldNames.LocationOfCoveredOps]: 18592876510875,
  [HelpFormFieldNames.MailingAddress]: 360021491392,
  [HelpFormFieldNames.MeansOfEgress]: 1900007356144,
  [HelpFormFieldNames.MeetsEligibilityGuidelines]: 360040440491,
  [HelpFormFieldNames.NamedInsuredAdditionalName]: 8004898125851,
  [HelpFormFieldNames.NamedInsuredAdditionalFein]: 25277189221275,
  [HelpFormFieldNames.NamedInsuredAdditionalOperations]: 8256345818011,
  [HelpFormFieldNames.NamedInsuredAdditionalCommonOwnership]: 8256351378203,
  [HelpFormFieldNames.NamedInsuredPrimaryName]: 8005215069723,
  [HelpFormFieldNames.NamedInsuredChangeType]: 8019377251355,
  [HelpFormFieldNames.NamedInsuredExplanation]: 8006924100507,
  [HelpFormFieldNames.NamedInsuredChangeInOperation]: 8019258939419,
  [HelpFormFieldNames.NamedInsuredChangeInOwnershipWc]: 25277670223003,
  [HelpFormFieldNames.NamedInsuredChangeEMod]: 25288932809883,
  [HelpFormFieldNames.NamedInsuredChangeFein]: 25288917915291,
  [HelpFormFieldNames.NamedInsuredChangeDescription]: 8005814673691,
  [HelpFormFieldNames.NewEffectiveDate]: 8004136465179,
  [HelpFormFieldNames.NewEffectiveDateReason]: 8004202470171,
  [HelpFormFieldNames.NumberOfEmployees]: 360041670391,
  [HelpFormFieldNames.NumberOfStories]: 1260827744969,
  [HelpFormFieldNames.EmployeesPerLocation]: 360041675631,
  [HelpFormFieldNames.PaymentPlan]: 360041672111,
  [HelpFormFieldNames.Policy]: 360020446231,
  [HelpFormFieldNames.ProducerCode]: 360032409132,
  [HelpFormFieldNames.RoofUpdate]: 1260827762369,
  [HelpFormFieldNames.RemoveAddress]: 1260827844449,
  [HelpFormFieldNames.RemoveLocationReason]: 1900007531704,
  [HelpFormFieldNames.SplitPPC]: 360041670371,
  [HelpFormFieldNames.SquareFootage]: 1260827746849,
  [HelpFormFieldNames.StatementDate]: 360042446911,
  [HelpFormFieldNames.UpdateLocationOldAddress]: 1900007531524,
  [HelpFormFieldNames.UpdateLocationNewAddress]: 1260828048070,
  [HelpFormFieldNames.UpdateLocationReason]: 1900007531684,
  [HelpFormFieldNames.UserId]: 4433051156763,
  [HelpFormFieldNames.QuoteNumber]: 360041377352,
  [HelpFormFieldNames.WosDescription]: 24882848058139,
  [HelpFormFieldNames.WosName]: 360040440471,
  [HelpFormFieldNames.WosNameRequired]: 360040440471,
  [HelpFormFieldNames.WosType]: 24882631541915,
  [HelpFormFieldNames.YearBuilt]: 1900007353464,
  // The below fields correspond to groups of Zendesk fields, not individual fields
  [HelpFormFieldNames.AiAddress]: 0,
  [HelpFormFieldNames.AiUpdatedAddress]: 0,
  [HelpFormFieldNames.BusinessMailingAddress]: 0,
  [HelpFormFieldNames.NamedInsuredAdditionalAddress]: 0,
  [HelpFormFieldNames.NamedInsuredPrimaryAddress]: 0,
  [HelpFormFieldNames.WosAddress]: 0,
};
// End Zendesk SANDBOX environment

// Zendesk PRODUCTION environment
export const UI_FORM_NAME_TO_ZENDESK_FORM_ID_PRODUCTION: {
  [key in HelpFormCategoryNames]?: number;
} = {
  [HelpFormCategoryNames.Agency]: 360001326591,
  [HelpFormCategoryNames.Billing]: 360001314852,
  [HelpFormCategoryNames.Claims]: 360001314912,
  [HelpFormCategoryNames.Documents]: 360001314932,
  [HelpFormCategoryNames.Feedback]: 360001326631,
  [HelpFormCategoryNames.InsuredAccount]: 360001314872,
  [HelpFormCategoryNames.Policy]: 360001326611,
  [HelpFormCategoryNames.Other]: 4411810410779,
  [HelpFormCategoryNames.Quote]: 360001314892,
  [HelpFormCategoryNames.TechnicalSupport]: 360001314952,
};

export const INBOUND_INQUIRY_TYPE_FIELD_ID_PRODUCTION = 360040534471;

export const MESSAGE_FIELD_ID_PRODUCTION = 360040622231;

export const UI_ADDRESS_NAME_TO_ZENDESK_FIELD_ID_PRODUCTION: {
  [key in HelpFormFieldNames]?: { [T in keyof Required<Address>]: number };
} = {
  [HelpFormFieldNames.AiAddress]: {
    addressLine1: 4426094294939,
    addressLine2: 4426080471707,
    city: 4426088194587,
    state: 4426080874779,
    zip: 4426098439323,
  },
  [HelpFormFieldNames.AiUpdatedAddress]: {
    addressLine1: 4503656300955,
    addressLine2: 4503664218139,
    city: 4503677765915,
    state: 4503657172507,
    zip: 4426098439323,
  },
  [HelpFormFieldNames.BusinessMailingAddress]: {
    addressLine1: 7292115942427,
    addressLine2: 7292099296155,
    city: 7292136884379,
    state: 7292121589147,
    zip: 7292139190427,
  },
  [HelpFormFieldNames.NamedInsuredAdditionalAddress]: {
    addressLine1: 8004994804507,
    addressLine2: 8005060637979,
    city: 8005068007963,
    state: 8005101305115,
    zip: 8005090902299,
  },
  [HelpFormFieldNames.NamedInsuredPrimaryAddress]: {
    addressLine1: 8005338052763,
    addressLine2: 8005399722011,
    city: 8005374421787,
    state: 8005429664155,
    zip: 8005481522587,
  },
  [HelpFormFieldNames.WosAddress]: {
    addressLine1: 24955469357083,
    addressLine2: 24955497080219,
    city: 24955498401051,
    state: 24955492914459,
    zip: 24955541110171,
  },
};

export const UI_ADDRESS_NAME_TO_ZENDESK_FIELD_ID_SANDBOX: {
  [key in HelpFormFieldNames]?: { [T in keyof Required<Address>]: number };
} = {
  [HelpFormFieldNames.AiAddress]: {
    addressLine1: 4426099612571,
    addressLine2: 4426099935131,
    city: 4426097360923,
    state: 4426100578715,
    zip: 4426100858523,
  },
  [HelpFormFieldNames.AiUpdatedAddress]: {
    addressLine1: 4503673395099,
    addressLine2: 4503701322011,
    city: 4503674386971,
    state: 4503675441947,
    zip: 4503687939483,
  },
  [HelpFormFieldNames.BusinessMailingAddress]: {
    addressLine1: 7242605642139,
    addressLine2: 7242691586587,
    city: 7242663674395,
    state: 7242717493531,
    zip: 7242648044059,
  },
  [HelpFormFieldNames.NamedInsuredAdditionalAddress]: {
    addressLine1: 8005072991771,
    addressLine2: 8005042324763,
    city: 8005063487771,
    state: 8005116964763,
    zip: 8005103989147,
  },
  [HelpFormFieldNames.NamedInsuredPrimaryAddress]: {
    addressLine1: 8005329695899,
    addressLine2: 8005394194203,
    city: 8005435534107,
    state: 8005429428507,
    zip: 8005467523995,
  },
  [HelpFormFieldNames.WosAddress]: {
    addressLine1: 24955400063003,
    addressLine2: 24955425068443,
    city: 24955443820827,
    state: 24955455115931,
    zip: 24955456158107,
  },
};

export const UI_FIELD_NAME_TO_ZENDESK_FIELD_ID_PRODUCTION: {
  [key in HelpFormFieldNames]: number;
} = {
  [HelpFormFieldNames.Account]: 360001973232,
  [HelpFormFieldNames.AccountYNumber]: 1900002791024,
  [HelpFormFieldNames.AddLocationReason]: 1900007335104,
  [HelpFormFieldNames.AgencyContactPersonFirstName]: 6660880214939,
  [HelpFormFieldNames.AgencyContactPersonLastName]: 6660912607259,
  [HelpFormFieldNames.AgencyContactPersonPhoneNumber]: 6660933845019,
  [HelpFormFieldNames.AgencyContactPersonEmail]: 6660951505819,
  [HelpFormFieldNames.AgencyMailingAddress]: 6660948535451,
  [HelpFormFieldNames.AgencyManagementSystem]: 6662769906459,
  [HelpFormFieldNames.AgencyManagementSystemVersion]: 6662787546267,
  [HelpFormFieldNames.AgencyName]: 6660956203675,
  [HelpFormFieldNames.AgencyPhoneNumber]: 6660968862107,
  [HelpFormFieldNames.AgencyPhysicalAddress]: 6660947328283,
  [HelpFormFieldNames.AiAddOrUpdate]: 360040561071,
  [HelpFormFieldNames.AiName]: 360039228251,
  [HelpFormFieldNames.AiIdToUpdate]: 7761196385307,
  [HelpFormFieldNames.AiNameToUpdate]: 1260828455850,
  [HelpFormFieldNames.AiUpdateName]: 1260828455870,
  [HelpFormFieldNames.AiUpdateAddress]: 1260828357789,
  [HelpFormFieldNames.AiUpdateType]: 1900007999904,
  [HelpFormFieldNames.AiUpdateBuilding]: 9423872475803,
  [HelpFormFieldNames.AiUpdateLocation]: 9423860160411,
  [HelpFormFieldNames.AiInsurableInterest]: 1900007998524,
  [HelpFormFieldNames.AiLeasedEquipment]: 1260828453970,
  [HelpFormFieldNames.AiParagraphAbc]: 1260828356789,
  [HelpFormFieldNames.AiCompletedOperations]: 1260828454290,
  [HelpFormFieldNames.AiVendorProducts]: 1260828454310,
  [HelpFormFieldNames.AiType]: 360039294972,
  [HelpFormFieldNames.AiBuilding]: 9423862620955,
  [HelpFormFieldNames.AiLocation]: 9423879245211,
  [HelpFormFieldNames.AmountReceived]: 360042439132,
  [HelpFormFieldNames.AnnualRevenue]: 1260828361950,
  [HelpFormFieldNames.AnnualPayroll]: 1260828361930,
  [HelpFormFieldNames.AutomaticSprinklers]: 1260827870490,
  [HelpFormFieldNames.BusinessAddress]: 360039228411,
  [HelpFormFieldNames.BuildingPersonalPropertyLimitOwner]: 1260828362010,
  [HelpFormFieldNames.BuildingPersonalPropertyLimitTenant]: 1260828272129,
  [HelpFormFieldNames.BuildingLimit]: 1260828361990,
  [HelpFormFieldNames.CancellationDate]: 360034770171,
  [HelpFormFieldNames.CancellationPolicyType]: 1260828453310,
  [HelpFormFieldNames.CancellationReason]: 360034784912,
  [HelpFormFieldNames.CancellationReasonOther]: 1260828453330,
  [HelpFormFieldNames.ChangeCoverageAddDescription]: 1260830481949,
  [HelpFormFieldNames.ChangeCoverageAddRemove]: 1900010107764,
  [HelpFormFieldNames.ChangeCoverageAddress]: 1260830477849,
  [HelpFormFieldNames.ChangeCoverageBlanketLimit]: 1260830489269,
  [HelpFormFieldNames.ChangeCoverageBppLimit]: 1260830477809,
  [HelpFormFieldNames.ChangeCoverageBuildingLimit]: 1260830691410,
  [HelpFormFieldNames.ChangeCoverageEquipmentList]: 1900010114864,
  [HelpFormFieldNames.ChangeCoverageFullyInsured]: 1900010104604,
  [HelpFormFieldNames.ChangeCoverageInsuredContractor]: 1260830488769,
  [HelpFormFieldNames.ChangeCoverageInsuredUpdates]: 1900010207884,
  [HelpFormFieldNames.ChangeCoverageItemSublimit]: 1900010115184,
  [HelpFormFieldNames.ChangeCoverageLiabilityLimit]: 25551187627931,
  [HelpFormFieldNames.ChangeCoverageReason]: 1260830692030,
  [HelpFormFieldNames.ChangeCoverageRemoveType]: 1900010114384,
  [HelpFormFieldNames.ChangeCoverageScheduleApply]: 1260830488829,
  [HelpFormFieldNames.ChangeCoverageType]: 360039228951,
  [HelpFormFieldNames.ChangeCoverageTypeWc]: 360039228951,
  [HelpFormFieldNames.ChangeCoverageUpdateDeductible]: 1900010107384,
  [HelpFormFieldNames.ChangeCoverageUpdateLimit]: 1900010107204,
  [HelpFormFieldNames.ChangeCoverageUpdateType]: 1260830694110,
  [HelpFormFieldNames.ChangeInOperation]: 360040443131,
  [HelpFormFieldNames.CheckMailingAddress]: 360043003632,
  [HelpFormFieldNames.ClericalError]: 360040443151,
  [HelpFormFieldNames.ConstructionType]: 1260827764449,
  [HelpFormFieldNames.Dba]: 360039228591,
  [HelpFormFieldNames.DeclineReason]: 360034765751,
  [HelpFormFieldNames.DescriptionOfChange]: 360040679712,
  [HelpFormFieldNames.EffectiveDate]: 360031928752,
  [HelpFormFieldNames.ElectricPlumbingHvacUpdated]: 1260827870250,
  [HelpFormFieldNames.ExposuresProjectedSales]: 8005884795419,
  [HelpFormFieldNames.ExposuresPayroll]: 8006112578203,
  [HelpFormFieldNames.ExposuresBusinessType]: 8019571669019,
  [HelpFormFieldNames.ExposuresSalesLessThanProjected]: 8019550292891,
  [HelpFormFieldNames.ExposuresPayrollDecreasing]: 8019579564187,
  [HelpFormFieldNames.InsuredEmail]: 360031987591,
  [HelpFormFieldNames.InsuredState]: 19127074419355,
  [HelpFormFieldNames.Interest]: 1900007336324,
  [HelpFormFieldNames.LineOfBusiness]: 360028725831,
  [HelpFormFieldNames.LocationAddOrUpdate]: 360040443091,
  [HelpFormFieldNames.LocationOfCoveredOps]: 18592812730523,
  [HelpFormFieldNames.MailingAddress]: 360039228571,
  [HelpFormFieldNames.MeetsEligibilityGuidelines]: 360034784892,
  [HelpFormFieldNames.MeansOfEgress]: 1260827871470,
  [HelpFormFieldNames.NamedInsuredAdditionalName]: 8004915621147,
  [HelpFormFieldNames.NamedInsuredAdditionalFein]: 25277239891611,
  [HelpFormFieldNames.NamedInsuredAdditionalOperations]: 8256342674843,
  [HelpFormFieldNames.NamedInsuredAdditionalCommonOwnership]: 8256382092187,
  [HelpFormFieldNames.NamedInsuredPrimaryName]: 8005130604699,
  [HelpFormFieldNames.NamedInsuredChangeType]: 8019528296347,
  [HelpFormFieldNames.NamedInsuredExplanation]: 8006923482395,
  [HelpFormFieldNames.NamedInsuredChangeInOperation]: 8019555009563,
  [HelpFormFieldNames.NamedInsuredChangeInOwnershipWc]: 25277675330459,
  [HelpFormFieldNames.NamedInsuredChangeEMod]: 25288933061787,
  [HelpFormFieldNames.NamedInsuredChangeFein]: 25288942724123,
  [HelpFormFieldNames.NamedInsuredChangeDescription]: 8005840168219,
  [HelpFormFieldNames.NewEffectiveDate]: 8004136465179,
  [HelpFormFieldNames.NewEffectiveDateReason]: 8004189106203,
  [HelpFormFieldNames.NumberOfEmployees]: 360040104612,
  [HelpFormFieldNames.NumberOfStories]: 1260827745189,
  [HelpFormFieldNames.EmployeesPerLocation]: 360041709532,
  [HelpFormFieldNames.PaymentPlan]: 360031986831,
  [HelpFormFieldNames.ProducerCode]: 360041959631,
  [HelpFormFieldNames.Policy]: 360030415011,
  [HelpFormFieldNames.RoofUpdate]: 1260827762469,
  [HelpFormFieldNames.RemoveAddress]: 1260827844829,
  [HelpFormFieldNames.RemoveLocationReason]: 1900007531724,
  [HelpFormFieldNames.SplitPPC]: 360041669591,
  [HelpFormFieldNames.SquareFootage]: 1260827868470,
  [HelpFormFieldNames.StatementDate]: 360042439112,
  [HelpFormFieldNames.UpdateLocationOldAddress]: 1260828048030,
  [HelpFormFieldNames.UpdateLocationNewAddress]: 1260828924450,
  [HelpFormFieldNames.UpdateLocationReason]: 1900007531804,
  [HelpFormFieldNames.UserId]: 4449848994459,
  [HelpFormFieldNames.QuoteNumber]: 360041331571,
  [HelpFormFieldNames.WosDescription]: 24882864382747,
  [HelpFormFieldNames.WosName]: 360039295512,
  [HelpFormFieldNames.WosNameRequired]: 360039295512,
  [HelpFormFieldNames.WosType]: 24882708200603,
  [HelpFormFieldNames.YearBuilt]: 1260827762069,
  // The below fields correspond to groups of Zendesk fields, not individual fields
  [HelpFormFieldNames.AiAddress]: 0,
  [HelpFormFieldNames.AiUpdatedAddress]: 0,
  [HelpFormFieldNames.BusinessMailingAddress]: 0,
  [HelpFormFieldNames.NamedInsuredPrimaryAddress]: 0,
  [HelpFormFieldNames.NamedInsuredAdditionalAddress]: 0,
  [HelpFormFieldNames.WosAddress]: 0,
};
// End Zendesk PRODUCTION environment

export enum HelpFormInquiryType {
  AccessAccount = 'technical_support__unable_to_access_an_account',
  AddBuilding = 'quote__add_building',
  AdditionalInsured = 'policy__endorsements__add_/_update_additional_insured',
  AddWaiver = 'policy__endorsements__add_waiver_of_subrogation',
  AssistClass = 'insured_account__eligibility__assist_with_classification',
  AssistEligibility = 'quote__confirm_eligibility__assist_with_class_code',
  AssistGuidelines = 'quote__confirm_eligibility__assist_with_appetite_/_guidelines',
  AssistLRO = 'quote__confirm_eligibility__assist_with_lro',
  AutopayStatus = 'billing__autopay__check_autopay_status',
  BindQuote = 'quote__bind_a_quote',
  BindCpspQuote = 'quote__bind_a_quote__liberty_mutual_cpsp',
  BindBlockedQuote = 'quote__bind_a_quote',
  CantLogin = 'technical_support__can_t_login',
  CantQuoteOrBind = 'technical_support__unable_to_quote_/_bind',
  ChangeAutopay = 'billing__autopay__change_autopay_payment_method',
  ChangeCoverage = 'policy__endorsements__change_coverage',
  ChangeEffectiveDate = 'policy__endorsements__change_effective_date',
  ChangePayment = 'billing__payments__change_payment_plan',
  ChangeQuote = 'quote__change_coverage',
  ConfirmCancel = 'policy__cancellations__confirm_cancellation',
  ConfirmEligibility = 'insured_account__eligibility__confirm_eligibility',
  ConfirmNKLL = 'policy__reinstatements__confirm_dates_for_nkll',
  ConfirmPayment = 'billing__payments__confirm_payment_was_made',
  ConfirmReceipt = 'billing__payments__confirm_receipt_of_check',
  ConfirmRefund = 'billing__refunds__confirm_refund',
  ConfirmReinstatement = 'policy__reinstatements__confirm_reinstatement_premium',
  DisputeCancel = 'policy__cancellations__dispute_underwriting_cancellation',
  EditRenewal = 'policy__renewals__edit_renewal_quote',
  EnrollAutopay = 'billing__autopay__enroll_in_autopay',
  GetRefund = 'billing__refunds__get_a_refund',
  GiveFeedback = 'feedback__give_feedback',
  InquireCoverage = 'quote__inquire_about_a_coverage',
  InsuredEmail = 'billing__contacts__update_insured_email',
  RequestIvans = 'agency__ivans_download',
  NewIvans = 'agency__new_ivans',
  Location = 'policy__endorsements__add_/_update_location',
  MakePayment = 'billing__payments__make_a_payment',
  MakeReinstatement = 'policy__reinstatements__make_reinstatement_payment',
  NamedInsured = 'policy__endorsements__update_named_insured',
  Other = 'other_other_other',
  OtherAgency = 'agency__other',
  OtherAutopay = 'billing__autopay__other',
  OtherBilling = 'billing__other',
  OtherCancel = 'policy__cancellations__other',
  OtherClaims = 'claims__other',
  OtherCommission = 'agency__commission__other',
  OtherDocuments = 'documents__other',
  OtherEligibility = 'insured_account__eligibility__other',
  OtherEndorsement = 'policy__endorsements__other',
  OtherFeedback = 'feedback__other',
  OtherInsured = 'insured_account__other',
  OtherPayment = 'billing__payments__other',
  OtherPolicy = 'policy__other',
  OtherPortal = 'agency__user_management__other',
  OtherQuote = 'quote__other',
  OtherRefund = 'billing__refunds__other',
  OtherReinstatement = 'policy__reinstatements__other',
  OtherRenewal = 'policy__renewals__other',
  OtherTechSupport = 'technical_support__other',
  PaymentInstructions = 'billing__payments__check_payment_instructions',
  RemoveAccess = 'agency__remove_access_for_a_broker',
  ReportClaim = 'claims__report_a_claim',
  RequestAppt = 'agency__request_an_appointment',
  RequestBOR = 'insured_account__request_broker_of_record_change',
  RequestCancel = 'policy__cancellations__request_cancellation',
  RequestCOI = 'documents__request_certificate_of_insurance',
  RequestCommission = 'agency__commission__request_commission_statement',
  RequestCredentials = 'agency__request_portal_credentials',
  RequestDocuments = 'documents__request_policy_document',
  RequestFeature = 'feedback__request_a_feature',
  RequestForms = 'documents__request_policy_forms_/_enhancements',
  RequestInvoice = 'billing__request_invoice',
  RequestLR = 'policy__request_loss_runs',
  RequestLRDocs = 'documents__request_loss_runs',
  RequestPayment = 'billing__request_payment_schedule_/_history',
  RequestQuote = 'documents__request_quote_letter',
  RequestReinstatement = 'policy__reinstatements__request_reinstatement',
  RequestRenewal = 'policy__renewals__request_renewal_quote',
  RequestReview = 'quote__request_quote_review_',
  ResetPassword = 'agency__reset_password',
  ResolveQuoteContingency = 'quote__resolve_contingency',
  UnenrollAutopay = 'billing__autopay__unenroll_from_autopay',
  UpdateAccount = 'insured_account__update_account_information',
  UpdateAddress = 'billing__refunds__update_mailing_address',
  UpdateBusiness = 'policy__endorsements__update_business_information',
  UpdateComBank = 'agency__commission__update_banking_info',
  UpdateEmail = 'agency__commission__update_recipient_email_address',
  UpdateSalesPayroll = 'policy__endorsements__update_sales_and_payroll',
  UpdateInfo = 'agency__update_agency_information',
  UpdateRefundBank = 'billing__refunds__update_banking_information',
}
// End Zendesk API IDs

// Deflect modal configs
export interface DeflectModalConfig {
  title: string;
  bodyText?: string;
  bodyImageUrl?: string;
  bodyImageAlt?: string;
  abortButtonText?: string;
  proceedButtonText?: string;
  abortRedirectPath?: string[];
}

const sseDeflectConfig: DeflectModalConfig = {
  title: 'Why wait? This endorsement is available self service.',
  bodyImageUrl: 'assets/img/sse_example.png',
  bodyImageAlt:
    'To endorse a policy, use the "Endorse Policy" button on that policy\'s Details pane',
};
// End Deflect modal configs

// UI form info
export enum HelpFormTitle {
  AccessAccount = 'Unable to access an account',
  AddBuilding = 'Add building',
  AdditionalInsured = 'Add / update additional insured',
  AddWaiver = 'Add waiver of subrogation',
  AssistClass = 'Assist with classification',
  AssistEligibility = 'Assist with class code',
  AssistGuidelines = 'Assist with appetite / guidelines',
  AssistLRO = 'Assist with LRO',
  AutopayStatus = 'Check autopay status',
  BindQuote = 'Bind a quote',
  BindCpspQuote = 'Bind a quote',
  BindBlockedQuote = 'Bind a quote',
  CantLogin = "Can't login",
  CantQuoteOrBind = 'Unable to quote / bind',
  ChangeAutopay = 'Change autopay payment method',
  ChangeCoverage = 'Change coverage',
  ChangeEffectiveDate = 'Change effective date',
  ChangePayment = 'Change payment plan',
  ChangeQuote = 'Change coverage',
  ConfirmCancel = 'Confirm cancellation',
  ConfirmEligibility = 'Confirm eligibility',
  ConfirmNKLL = 'Confirm dates for NKLL',
  ConfirmPayment = 'Confirm payment was made',
  ConfirmReceipt = 'Confirm receipt of check',
  ConfirmRefund = 'Confirm a refund',
  ConfirmReinstatement = 'Confirm reinstatement premium',
  DisputeCancel = 'Dispute underwriting cancellation',
  EditRenewal = 'Edit renewal quote',
  EnrollAutopay = 'Enroll in autopay',
  GetRefund = 'Get a refund',
  GiveFeedback = 'Give feedback',
  InquireCoverage = 'Inquire about a coverage',
  InsuredEmail = 'Update insured email',
  RequestIvans = 'Request IVANS Download',
  NewIvans = 'Setup New IVANS Account',
  Location = 'Add / update location',
  MakePayment = 'Make a payment',
  MakeReinstatement = 'Make reinstatement payment',
  NamedInsured = 'Add / update named insured',
  Other = 'Other',
  OtherAgency = 'Other',
  OtherAutopay = 'Other',
  OtherCancel = 'Other',
  OtherClaims = 'Other',
  OtherCommission = 'Other',
  OtherBilling = 'Other',
  OtherDocuments = 'Other',
  OtherEligibility = 'Other',
  OtherEndorsement = 'Other',
  OtherFeedback = 'Other',
  OtherInsured = 'Other',
  OtherPayment = 'Other',
  OtherPortal = 'Other',
  OtherQuote = 'Other',
  OtherPolicy = 'Other',
  OtherRefund = 'Other',
  OtherReinstatement = 'Other',
  OtherRenewal = 'Other',
  OtherTechSupport = 'Other',
  PaymentInstructions = 'Check payment instructions',
  RemoveAccess = 'Remove access for a broker',
  RequestAppt = 'Request an appointment',
  RequestBOR = 'Request broker of record change',
  RequestCancel = 'Request cancellation',
  RequestCommission = 'Request commission statement',
  RequestCOI = 'Request certificate of insurance',
  RequestCredentials = 'Request portal credentials',
  RequestDocuments = 'Request policy documents',
  RequestFeature = 'Request feature',
  RequestForms = 'Request policy forms / enhancements',
  RequestInvoice = 'Request invoice',
  RequestLR = 'Request loss runs',
  RequestLRDocs = 'Request loss runs',
  RequestQuote = 'Request quote letter',
  RequestReinstatement = 'Request reinstatement',
  RequestRenewal = 'Request renewal quote',
  RequestReview = 'Request quote review',
  ReportClaim = 'Report a claim',
  ResetPassword = 'Reset password',
  RequestPayment = 'Request payment schedule / history',
  ResolveQuoteContingency = 'Resolve quote contingency',
  UnenrollAutopay = 'Unenroll from autopay',
  UpdateAccount = 'Update account information',
  UpdateAddress = 'Update mailing address',
  UpdateBusiness = 'Update business information',
  UpdateEmail = 'Update recipient email address',
  UpdateSalesPayroll = 'Update sales and payroll',
  UpdateInfo = 'Update agency information',
  UpdateComBank = 'Update banking information',
  UpdateRefundBank = 'Update banking information',
}

interface FillableForm {
  type: 'fillableForm';
  formTitle: HelpFormTitle;
  path: HelpFormCategoryNames[];
  inquiryType: HelpFormInquiryType;
  formConfig?: FormDslNode[]; // default to Generic form
  authedDeflectConfig?: DeflectModalConfig;
  unauthedDeflectConfig?: DeflectModalConfig;
  validators?: SimpleValidator[];
}

interface InfoForm {
  type: 'infoForm';
  formTitle: HelpFormTitle;
  path: HelpFormCategoryNames[];
  inquiryType: HelpFormInquiryType;
  formConfig?: FormDslNode[];
}

export type SlugFormMapping = {
  [key in HelpFormSlug]: FillableForm | InfoForm;
};

export const formInfoBySlug: SlugFormMapping = {
  [HelpFormSlug.AccessAccount]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.AccessAccount,
    path: [HelpFormCategoryNames.TechnicalSupport],
    inquiryType: HelpFormInquiryType.AccessAccount,
  },
  [HelpFormSlug.AddBuilding]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.AddBuilding,
    path: [HelpFormCategoryNames.Quote],
    inquiryType: HelpFormInquiryType.AddBuilding,
    formConfig: addBuildingConfig,
  },
  [HelpFormSlug.AdditionalInsured]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.AdditionalInsured,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Endorsements],
    inquiryType: HelpFormInquiryType.AdditionalInsured,
    formConfig: additionalInsuredFormConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.AddWaiver]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.AddWaiver,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Endorsements],
    inquiryType: HelpFormInquiryType.AddWaiver,
    formConfig: addWaivorOfSubrogationFormConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.RequestIvans]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RequestIvans,
    path: [HelpFormCategoryNames.Agency],
    inquiryType: HelpFormInquiryType.RequestIvans,
    formConfig: requestIvansFormConfig,
  },
  [HelpFormSlug.NewIvans]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.NewIvans,
    path: [HelpFormCategoryNames.Agency],
    inquiryType: HelpFormInquiryType.NewIvans,
    formConfig: newIvansFormConfig,
  },
  [HelpFormSlug.AssistClass]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.AssistClass,
    path: [HelpFormCategoryNames.InsuredAccount, HelpFormCategoryNames.Eligibility],
    inquiryType: HelpFormInquiryType.AssistClass,
  },
  [HelpFormSlug.AssistEligibility]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.AssistEligibility,
    path: [HelpFormCategoryNames.Quote, HelpFormCategoryNames.ConfirmEligibility],
    inquiryType: HelpFormInquiryType.AssistEligibility,
    formConfig: defaultQuoteConfigWithState,
  },
  [HelpFormSlug.AssistGuidelines]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.AssistGuidelines,
    path: [HelpFormCategoryNames.Quote, HelpFormCategoryNames.ConfirmEligibility],
    inquiryType: HelpFormInquiryType.AssistGuidelines,
    formConfig: defaultQuoteConfig,
  },
  [HelpFormSlug.AssistLRO]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.AssistLRO,
    path: [HelpFormCategoryNames.Quote, HelpFormCategoryNames.ConfirmEligibility],
    inquiryType: HelpFormInquiryType.AssistLRO,
    formConfig: assistLroConfig,
  },
  [HelpFormSlug.AutopayStatus]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.AutopayStatus,
    path: [HelpFormCategoryNames.Billing, HelpFormCategoryNames.Autopay],
    inquiryType: HelpFormInquiryType.AutopayStatus,
  },
  [HelpFormSlug.BindQuote]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.BindQuote,
    path: [HelpFormCategoryNames.Quote],
    inquiryType: HelpFormInquiryType.BindQuote,
    formConfig: bindQuoteConfig,
  },
  [HelpFormSlug.BindCpspQuote]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.BindCpspQuote,
    path: [HelpFormCategoryNames.Quote],
    inquiryType: HelpFormInquiryType.BindCpspQuote,
    formConfig: bindCpspQuoteConfig,
  },
  [HelpFormSlug.BindBlockedQuote]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.BindBlockedQuote,
    path: [HelpFormCategoryNames.Quote],
    inquiryType: HelpFormInquiryType.BindBlockedQuote,
    formConfig: bindBlockedQuoteConfig,
  },
  [HelpFormSlug.CantLogin]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.CantLogin,
    path: [HelpFormCategoryNames.TechnicalSupport],
    inquiryType: HelpFormInquiryType.CantLogin,
    formConfig: generalFormConfig,
  },
  [HelpFormSlug.CantQuoteOrBind]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.CantQuoteOrBind,
    path: [HelpFormCategoryNames.TechnicalSupport],
    inquiryType: HelpFormInquiryType.CantQuoteOrBind,
  },
  [HelpFormSlug.ChangeAutopay]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.ChangeAutopay,
    path: [HelpFormCategoryNames.Billing, HelpFormCategoryNames.Autopay],
    inquiryType: HelpFormInquiryType.ChangeAutopay,
  },
  [HelpFormSlug.ChangeCoverage]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.ChangeCoverage,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Endorsements],
    inquiryType: HelpFormInquiryType.ChangeCoverage,
    formConfig: changeCoverageConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.ChangeEffectiveDate]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.ChangeEffectiveDate,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Endorsements],
    inquiryType: HelpFormInquiryType.ChangeEffectiveDate,
    formConfig: changeEffectiveDateFormConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.ChangePayment]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.ChangePayment,
    path: [HelpFormCategoryNames.Billing, HelpFormCategoryNames.Payments],
    inquiryType: HelpFormInquiryType.ChangePayment,
    formConfig: defaultFormWithStateRequired,
  },
  [HelpFormSlug.ChangeQuote]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.ChangeQuote,
    path: [HelpFormCategoryNames.Quote],
    inquiryType: HelpFormInquiryType.ChangeQuote,
    formConfig: changeQuoteConfig,
  },
  [HelpFormSlug.ConfirmCancel]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.ConfirmCancel,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Cancellations],
    inquiryType: HelpFormInquiryType.ConfirmCancel,
  },
  [HelpFormSlug.ConfirmEligibility]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.ConfirmEligibility,
    path: [HelpFormCategoryNames.InsuredAccount, HelpFormCategoryNames.Eligibility],
    inquiryType: HelpFormInquiryType.ConfirmEligibility,
  },
  [HelpFormSlug.ConfirmNKLL]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.ConfirmNKLL,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Reinstatements],
    inquiryType: HelpFormInquiryType.ConfirmNKLL,
  },
  [HelpFormSlug.ConfirmPayment]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.ConfirmPayment,
    path: [HelpFormCategoryNames.Billing, HelpFormCategoryNames.Payments],
    inquiryType: HelpFormInquiryType.ConfirmPayment,
    formConfig: confirmPaymentFormConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.ConfirmReceipt]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.ConfirmReceipt,
    path: [HelpFormCategoryNames.Billing, HelpFormCategoryNames.Payments],
    inquiryType: HelpFormInquiryType.ConfirmReceipt,
  },
  [HelpFormSlug.ConfirmRefund]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.ConfirmRefund,
    path: [HelpFormCategoryNames.Billing, HelpFormCategoryNames.Refunds],
    inquiryType: HelpFormInquiryType.ConfirmRefund,
    formConfig: defaultFormWithStateRequired,
  },
  [HelpFormSlug.ConfirmReinstatement]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.ConfirmReinstatement,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Reinstatements],
    inquiryType: HelpFormInquiryType.ConfirmReinstatement,
  },
  [HelpFormSlug.DisputeCancel]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.DisputeCancel,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Cancellations],
    inquiryType: HelpFormInquiryType.DisputeCancel,
  },
  [HelpFormSlug.EditRenewal]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.EditRenewal,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Renewals],
    inquiryType: HelpFormInquiryType.EditRenewal,
    formConfig: editRenewalConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.EnrollAutopay]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.EnrollAutopay,
    path: [HelpFormCategoryNames.Billing, HelpFormCategoryNames.Autopay],
    inquiryType: HelpFormInquiryType.EnrollAutopay,
    formConfig: enrollAutopayFormConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.GetRefund]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.GetRefund,
    path: [HelpFormCategoryNames.Billing, HelpFormCategoryNames.Refunds],
    inquiryType: HelpFormInquiryType.GetRefund,
    formConfig: defaultFormWithStateRequired,
  },
  [HelpFormSlug.GiveFeedback]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.GiveFeedback,
    path: [HelpFormCategoryNames.Feedback],
    inquiryType: HelpFormInquiryType.GiveFeedback,
    formConfig: generalFormConfig,
  },
  [HelpFormSlug.InquireCoverage]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.InquireCoverage,
    path: [HelpFormCategoryNames.Quote],
    inquiryType: HelpFormInquiryType.InquireCoverage,
    formConfig: defaultQuoteConfigWithState,
  },
  [HelpFormSlug.InsuredEmail]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.InsuredEmail,
    path: [HelpFormCategoryNames.Billing, HelpFormCategoryNames.Contacts],
    inquiryType: HelpFormInquiryType.InsuredEmail,
    formConfig: updateInsuredEmailConfig,
  },
  [HelpFormSlug.Location]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.Location,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Endorsements],
    inquiryType: HelpFormInquiryType.Location,
    formConfig: locationConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.MakePayment]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.MakePayment,
    path: [HelpFormCategoryNames.Billing, HelpFormCategoryNames.Payments],
    inquiryType: HelpFormInquiryType.MakePayment,
    formConfig: makePaymentFormConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.MakeReinstatement]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.MakeReinstatement,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Reinstatements],
    inquiryType: HelpFormInquiryType.MakeReinstatement,
  },
  [HelpFormSlug.NamedInsured]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.NamedInsured,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Endorsements],
    inquiryType: HelpFormInquiryType.NamedInsured,
    formConfig: namedInsuredFormConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.Other]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.Other,
    path: [HelpFormCategoryNames.Other],
    inquiryType: HelpFormInquiryType.Other,
  },
  [HelpFormSlug.OtherAgency]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherAgency,
    path: [HelpFormCategoryNames.Agency],
    inquiryType: HelpFormInquiryType.OtherAgency,
    formConfig: generalFormConfig,
  },
  [HelpFormSlug.OtherAutopay]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherAutopay,
    path: [HelpFormCategoryNames.Billing, HelpFormCategoryNames.Autopay],
    inquiryType: HelpFormInquiryType.OtherAutopay,
  },
  [HelpFormSlug.OtherCancel]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherCancel,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Cancellations],
    inquiryType: HelpFormInquiryType.OtherCancel,
  },
  [HelpFormSlug.OtherCommission]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherCommission,
    path: [HelpFormCategoryNames.Agency, HelpFormCategoryNames.Commission],
    inquiryType: HelpFormInquiryType.OtherCommission,
    formConfig: generalFormConfig,
  },
  [HelpFormSlug.OtherBilling]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherBilling,
    path: [HelpFormCategoryNames.Billing],
    inquiryType: HelpFormInquiryType.OtherBilling,
  },
  [HelpFormSlug.OtherDocuments]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherDocuments,
    path: [HelpFormCategoryNames.Documents],
    inquiryType: HelpFormInquiryType.OtherDocuments,
  },
  [HelpFormSlug.OtherEligibility]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherEligibility,
    path: [HelpFormCategoryNames.InsuredAccount, HelpFormCategoryNames.Eligibility],
    inquiryType: HelpFormInquiryType.OtherEligibility,
  },
  [HelpFormSlug.OtherEndorsement]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherEndorsement,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Endorsements],
    inquiryType: HelpFormInquiryType.OtherEndorsement,
    formConfig: otherEndorsementConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.OtherFeedback]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherFeedback,
    path: [HelpFormCategoryNames.Feedback],
    inquiryType: HelpFormInquiryType.OtherFeedback,
    formConfig: generalFormConfig,
  },
  [HelpFormSlug.OtherInsured]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherInsured,
    path: [HelpFormCategoryNames.InsuredAccount],
    inquiryType: HelpFormInquiryType.OtherInsured,
  },
  [HelpFormSlug.OtherPayment]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherPayment,
    path: [HelpFormCategoryNames.Billing, HelpFormCategoryNames.Payments],
    inquiryType: HelpFormInquiryType.OtherPayment,
  },
  [HelpFormSlug.OtherPolicy]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherPolicy,
    path: [HelpFormCategoryNames.Policy],
    inquiryType: HelpFormInquiryType.OtherPolicy,
  },
  [HelpFormSlug.OtherPortal]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherPortal,
    path: [HelpFormCategoryNames.Agency],
    inquiryType: HelpFormInquiryType.OtherPortal,
  },
  [HelpFormSlug.OtherQuote]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherQuote,
    path: [HelpFormCategoryNames.Quote],
    inquiryType: HelpFormInquiryType.OtherQuote,
    formConfig: defaultQuoteConfig,
  },
  [HelpFormSlug.OtherRefund]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherRefund,
    path: [HelpFormCategoryNames.Billing, HelpFormCategoryNames.Refunds],
    inquiryType: HelpFormInquiryType.OtherRefund,
  },
  [HelpFormSlug.OtherReinstatement]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherReinstatement,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Reinstatements],
    inquiryType: HelpFormInquiryType.OtherReinstatement,
  },
  [HelpFormSlug.OtherRenewal]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherRenewal,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Renewals],
    inquiryType: HelpFormInquiryType.OtherRenewal,
  },
  [HelpFormSlug.OtherTechSupport]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.OtherTechSupport,
    path: [HelpFormCategoryNames.TechnicalSupport],
    inquiryType: HelpFormInquiryType.OtherTechSupport,
  },
  [HelpFormSlug.PaymentInstructions]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.PaymentInstructions,
    path: [HelpFormCategoryNames.Billing, HelpFormCategoryNames.Payments],
    inquiryType: HelpFormInquiryType.PaymentInstructions,
  },
  [HelpFormSlug.RemoveAccess]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RemoveAccess,
    path: [HelpFormCategoryNames.Agency],
    inquiryType: HelpFormInquiryType.RemoveAccess,
    formConfig: generalFormConfig,
  },
  [HelpFormSlug.RequestAppt]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RequestAppt,
    path: [HelpFormCategoryNames.Agency],
    inquiryType: HelpFormInquiryType.RequestAppt,
    formConfig: generalFormConfig,
  },
  [HelpFormSlug.RequestBOR]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RequestBOR,
    path: [HelpFormCategoryNames.InsuredAccount],
    inquiryType: HelpFormInquiryType.RequestBOR,
    formConfig: requestBorConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.RequestCancel]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RequestCancel,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Cancellations],
    inquiryType: HelpFormInquiryType.RequestCancel,
    formConfig: cancellationFormConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.RequestCOI]: {
    type: 'infoForm',
    formTitle: HelpFormTitle.RequestCOI,
    path: [HelpFormCategoryNames.Documents],
    inquiryType: HelpFormInquiryType.RequestCOI,
    formConfig: requestCOIConfig,
  },
  [HelpFormSlug.RequestCommission]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RequestCommission,
    path: [HelpFormCategoryNames.Agency, HelpFormCategoryNames.Commission],
    inquiryType: HelpFormInquiryType.RequestCommission,
    formConfig: requestCommissionFormConfig,
  },
  [HelpFormSlug.RequestCredentials]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RequestCredentials,
    path: [HelpFormCategoryNames.Agency],
    inquiryType: HelpFormInquiryType.RequestCredentials,
    formConfig: generalFormConfig,
  },
  [HelpFormSlug.RequestDocuments]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RequestDocuments,
    path: [HelpFormCategoryNames.Documents],
    inquiryType: HelpFormInquiryType.RequestDocuments,
    formConfig: defaultFormWithStateRequired,
  },
  [HelpFormSlug.RequestFeature]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RequestFeature,
    path: [HelpFormCategoryNames.Feedback],
    inquiryType: HelpFormInquiryType.RequestFeature,
    formConfig: generalFormConfig,
  },
  [HelpFormSlug.RequestForms]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RequestForms,
    path: [HelpFormCategoryNames.Documents],
    inquiryType: HelpFormInquiryType.RequestForms,
    formConfig: defaultFormWithStateRequired,
  },
  [HelpFormSlug.RequestInvoice]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RequestInvoice,
    path: [HelpFormCategoryNames.Billing],
    inquiryType: HelpFormInquiryType.RequestInvoice,
    formConfig: requestInvoiceFormConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.RequestLR]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RequestLR,
    path: [HelpFormCategoryNames.Policy],
    inquiryType: HelpFormInquiryType.RequestLR,
    formConfig: defaultFormWithStateRequired,
  },
  [HelpFormSlug.RequestLRDocs]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RequestLRDocs,
    path: [HelpFormCategoryNames.Documents],
    inquiryType: HelpFormInquiryType.RequestLRDocs,
    formConfig: defaultFormWithStateRequired,
  },
  [HelpFormSlug.RequestQuote]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RequestQuote,
    path: [HelpFormCategoryNames.Documents],
    inquiryType: HelpFormInquiryType.RequestQuote,
    formConfig: defaultFormWithStateRequired,
  },
  [HelpFormSlug.RequestReinstatement]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RequestReinstatement,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Reinstatements],
    inquiryType: HelpFormInquiryType.RequestReinstatement,
    formConfig: requestReinstatementFormConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.RequestRenewal]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RequestRenewal,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Renewals],
    inquiryType: HelpFormInquiryType.RequestRenewal,
  },
  [HelpFormSlug.RequestReview]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RequestReview,
    path: [HelpFormCategoryNames.Quote],
    inquiryType: HelpFormInquiryType.RequestReview,
    formConfig: quoteReviewConfig,
  },
  [HelpFormSlug.RequestPayment]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.RequestPayment,
    path: [HelpFormCategoryNames.Billing],
    inquiryType: HelpFormInquiryType.RequestPayment,
    formConfig: requestPaymentFormConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.ResetPassword]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.ResetPassword,
    path: [HelpFormCategoryNames.Agency],
    inquiryType: HelpFormInquiryType.ResetPassword,
    formConfig: generalFormConfig,
  },
  [HelpFormSlug.UnenrollAutopay]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.UnenrollAutopay,
    path: [HelpFormCategoryNames.Billing, HelpFormCategoryNames.Autopay],
    inquiryType: HelpFormInquiryType.UnenrollAutopay,
  },
  [HelpFormSlug.UpdateAccount]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.UpdateAccount,
    path: [HelpFormCategoryNames.InsuredAccount],
    inquiryType: HelpFormInquiryType.UpdateAccount,
  },
  [HelpFormSlug.UpdateAddress]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.UpdateAddress,
    path: [HelpFormCategoryNames.Billing, HelpFormCategoryNames.Refunds],
    inquiryType: HelpFormInquiryType.UpdateAddress,
  },
  [HelpFormSlug.UpdateBusiness]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.UpdateBusiness,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Endorsements],
    inquiryType: HelpFormInquiryType.UpdateBusiness,
    formConfig: updateBusinessInfoConfig,
    authedDeflectConfig: sseDeflectConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.UpdateEmail]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.UpdateEmail,
    path: [HelpFormCategoryNames.Agency, HelpFormCategoryNames.Commission],
    inquiryType: HelpFormInquiryType.UpdateEmail,
    formConfig: generalFormConfig,
  },
  [HelpFormSlug.UpdateSalesPayroll]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.UpdateSalesPayroll,
    path: [HelpFormCategoryNames.Policy, HelpFormCategoryNames.Endorsements],
    inquiryType: HelpFormInquiryType.UpdateSalesPayroll,
    formConfig: updateSalesPayrollFormConfig,
    validators: [policyNumberBusinessValidator],
  },
  [HelpFormSlug.UpdateInfo]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.UpdateInfo,
    path: [HelpFormCategoryNames.Agency],
    inquiryType: HelpFormInquiryType.UpdateInfo,
    formConfig: generalFormConfig,
  },
  [HelpFormSlug.UpdateComBank]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.UpdateComBank,
    path: [HelpFormCategoryNames.Agency, HelpFormCategoryNames.Commission],
    inquiryType: HelpFormInquiryType.UpdateComBank,
    formConfig: updateComBankConfig,
  },
  [HelpFormSlug.UpdateRefundBank]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.UpdateRefundBank,
    path: [HelpFormCategoryNames.Billing, HelpFormCategoryNames.Refunds],
    inquiryType: HelpFormInquiryType.UpdateRefundBank,
    formConfig: updateRefundBankConfig,
  },
  [HelpFormSlug.ResolveQuoteContingency]: {
    type: 'fillableForm',
    formTitle: HelpFormTitle.ResolveQuoteContingency,
    path: [HelpFormCategoryNames.InsuredAccount],
    inquiryType: HelpFormInquiryType.ResolveQuoteContingency,
    formConfig: resolveQuoteContingency,
  },
};
// End UI form info

// Help Center Request Categories
export enum HelpCenterSectionName {
  Agency = 'My agency',
  Policy = 'Policy questions',
  Eligibility = 'Quoting, binding, and eligibility',
  Other = 'Other',
}

export enum HelpCenterCategoryName {
  Parent = 'PARENT CATEGORY',
  Commission = 'Commission questions',
  RequestCommissionStatement = 'Request commission statement',
  UpdateBankingInfo = 'Update banking information for payouts',
  CommissionOther = 'Other commission question',
  Portal = 'Portal user management',
  AddNewUser = 'Add a new user',
  RemoveAccess = 'Remove access for a broker',
  ResetPassword = 'Reset password',
  PortalOther = 'Other portal user management question',
  Agency = 'My agency - other',
  Endorse = 'Endorse a policy',
  AddUpdateAi = 'Add or update an additional insured',
  AddUpdateCoverage = 'Add or update coverage',
  AddUpdateLocation = 'Add or update location',
  AddUpdateNamedInsured = 'Add or update a named insured',
  ChangeDbaMailingAddress = 'Change DBA or business mailing address',
  ChangeEffectiveDate = 'Change effective date',
  UpdateSalesPayroll = 'Update sales and payroll',
  AddBuilding = 'Add a building',
  Other = 'Other',
  Cancel = 'Cancel a policy',
  RequestLossRuns = 'Request loss runs',
  RequestReinstatement = 'Request policy reinstatement',
  RequestBOR = 'Request a BOR',
  Billing = 'Billing',
  RequestPaymentSchedule = 'Request payment schedule / history',
  RequestInvoice = 'Request invoice',
  RequestIvans = 'Request IVANS download',
  NewIvans = 'Setup New IVANS account',
  MakePayment = 'Make a payment',
  ConfirmPayment = 'Confirm payment was made',
  EnrollAutopay = 'Enroll in or update autopay',
  GetRefund = 'Get a refund',
  Renewals = 'Renewals',
  RequestRenewal = 'Request renewal quote',
  EditRenewal = 'Edit renewal quote',
  RequestPolicyDocument = 'Request a policy document',
  RequestCertificate = 'Request certificate of insurance',
  RequestInsuredDocument = 'Request insured policy document',
  RequestPolicyForms = 'Request policy forms or enhancements',
  RequestQuoteLetter = 'Request quote letter',
  PolicyOther = 'Policy questions - other',
  ClassifyBusiness = 'Help me classify a business',
  RequestQuoteReview = 'Request a quote review',
  CoverageQuestions = 'Coverage questions',
  BindQuote = 'Bind a quote',
  BindCpspQuote = 'Bind request', // Liberty Mutual specific
  QuotingOther = 'Quoting, binding, and eligibility - other',
  ChangePayment = 'Change payment plan',
  AddWaiver = 'Add a waiver of subrogation',
  ResolveQuoteContingency = 'Resolve quote contingency',
}

export enum HelpCenterCategoryQuestion {
  Commission = 'What type of commission question do you have?',
  Portal = 'What type of portal user management question do you have?',
  Endorse = 'What type of endorsement would you like to quote?',
  Billing = 'What type of billing question do you have?',
  Renewals = 'What type of renewals question do you have?',
  RequestPolicyDocument = 'What sort of document do you need?',
}

export interface HelpCenterFormStructure {
  title: HelpCenterCategoryName;
  question: string;
  sections: HelpCenterSection[];
}
export interface HelpCenterSection {
  title: HelpCenterSectionName;
  children: (HelpCenterCategory | HelpCenterForm)[];
}

export interface HelpCenterCategory {
  title: HelpCenterCategoryName;
  subcategoryQuestion: HelpCenterCategoryQuestion;
  children: HelpCenterForm[];
}

export interface HelpCenterForm {
  title: HelpCenterCategoryName;
  slug: HelpFormSlug;
  showMessage: boolean;
}

export const HELP_CENTER_FORMS: HelpCenterFormStructure = {
  title: HelpCenterCategoryName.Parent,
  question: 'What can we help you with?',
  sections: [
    {
      title: HelpCenterSectionName.Agency,
      children: [
        {
          title: HelpCenterCategoryName.Commission,
          subcategoryQuestion: HelpCenterCategoryQuestion.Commission,
          children: [
            {
              title: HelpCenterCategoryName.RequestCommissionStatement,
              slug: HelpFormSlug.RequestCommission,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.UpdateBankingInfo,
              slug: HelpFormSlug.UpdateComBank,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.CommissionOther,
              slug: HelpFormSlug.OtherCommission,
              showMessage: true,
            },
          ],
        },
        {
          title: HelpCenterCategoryName.Portal,
          subcategoryQuestion: HelpCenterCategoryQuestion.Portal,
          children: [
            {
              title: HelpCenterCategoryName.AddNewUser,
              slug: HelpFormSlug.RequestCredentials,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.RemoveAccess,
              slug: HelpFormSlug.RemoveAccess,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.ResetPassword,
              slug: HelpFormSlug.ResetPassword,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.PortalOther,
              slug: HelpFormSlug.OtherPortal,
              showMessage: true,
            },
          ],
        },
        {
          title: HelpCenterCategoryName.RequestIvans,
          slug: HelpFormSlug.RequestIvans,
          showMessage: true,
        },
        {
          title: HelpCenterCategoryName.NewIvans,
          slug: HelpFormSlug.NewIvans,
          showMessage: true,
        },
        {
          title: HelpCenterCategoryName.Agency,
          slug: HelpFormSlug.OtherAgency,
          showMessage: true,
        },
      ],
    },
    {
      title: HelpCenterSectionName.Policy,
      children: [
        {
          title: HelpCenterCategoryName.Endorse,
          subcategoryQuestion: HelpCenterCategoryQuestion.Endorse,
          children: [
            {
              title: HelpCenterCategoryName.AddUpdateAi,
              slug: HelpFormSlug.AdditionalInsured,
              showMessage: false,
            },
            {
              title: HelpCenterCategoryName.AddUpdateNamedInsured,
              slug: HelpFormSlug.NamedInsured,
              showMessage: false,
            },
            {
              title: HelpCenterCategoryName.AddUpdateCoverage,
              slug: HelpFormSlug.ChangeCoverage,
              showMessage: false,
            },
            {
              title: HelpCenterCategoryName.AddUpdateLocation,
              slug: HelpFormSlug.Location,
              showMessage: false,
            },
            {
              title: HelpCenterCategoryName.AddWaiver,
              slug: HelpFormSlug.AddWaiver,
              showMessage: false,
            },
            {
              title: HelpCenterCategoryName.ChangeDbaMailingAddress,
              slug: HelpFormSlug.UpdateBusiness,
              showMessage: false,
            },
            {
              title: HelpCenterCategoryName.ChangeEffectiveDate,
              slug: HelpFormSlug.ChangeEffectiveDate,
              showMessage: false,
            },
            {
              title: HelpCenterCategoryName.UpdateSalesPayroll,
              slug: HelpFormSlug.UpdateSalesPayroll,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.AddBuilding,
              slug: HelpFormSlug.AddBuilding,
              showMessage: false,
            },
          ],
        },
        {
          title: HelpCenterCategoryName.Cancel,
          slug: HelpFormSlug.RequestCancel,
          showMessage: true,
        },
        {
          title: HelpCenterCategoryName.RequestLossRuns,
          slug: HelpFormSlug.RequestLR,
          showMessage: true,
        },
        {
          title: HelpCenterCategoryName.RequestReinstatement,
          slug: HelpFormSlug.RequestReinstatement,
          showMessage: true,
        },
        {
          title: HelpCenterCategoryName.RequestBOR,
          slug: HelpFormSlug.RequestBOR,
          showMessage: true,
        },
        {
          title: HelpCenterCategoryName.Billing,
          subcategoryQuestion: HelpCenterCategoryQuestion.Billing,
          children: [
            {
              title: HelpCenterCategoryName.RequestPaymentSchedule,
              slug: HelpFormSlug.RequestPayment,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.ChangePayment,
              slug: HelpFormSlug.ChangePayment,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.RequestInvoice,
              slug: HelpFormSlug.RequestInvoice,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.MakePayment,
              slug: HelpFormSlug.MakePayment,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.ConfirmPayment,
              slug: HelpFormSlug.ConfirmPayment,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.EnrollAutopay,
              slug: HelpFormSlug.EnrollAutopay,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.GetRefund,
              slug: HelpFormSlug.GetRefund,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.Other,
              slug: HelpFormSlug.OtherBilling,
              showMessage: true,
            },
          ],
        },
        {
          title: HelpCenterCategoryName.Renewals,
          subcategoryQuestion: HelpCenterCategoryQuestion.Renewals,
          children: [
            {
              title: HelpCenterCategoryName.RequestRenewal,
              slug: HelpFormSlug.RequestRenewal,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.EditRenewal,
              slug: HelpFormSlug.EditRenewal,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.Other,
              slug: HelpFormSlug.OtherRenewal,
              showMessage: true,
            },
          ],
        },
        {
          title: HelpCenterCategoryName.RequestPolicyDocument,
          subcategoryQuestion: HelpCenterCategoryQuestion.RequestPolicyDocument,
          children: [
            {
              title: HelpCenterCategoryName.RequestCertificate,
              slug: HelpFormSlug.RequestCOI,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.RequestInsuredDocument,
              slug: HelpFormSlug.RequestDocuments,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.RequestPolicyForms,
              slug: HelpFormSlug.RequestForms,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.RequestQuoteLetter,
              slug: HelpFormSlug.RequestQuote,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.RequestLossRuns,
              slug: HelpFormSlug.RequestLRDocs,
              showMessage: true,
            },
            {
              title: HelpCenterCategoryName.Other,
              slug: HelpFormSlug.OtherDocuments,
              showMessage: true,
            },
          ],
        },
        {
          title: HelpCenterCategoryName.PolicyOther,
          slug: HelpFormSlug.OtherPolicy,
          showMessage: true,
        },
      ],
    },
    {
      title: HelpCenterSectionName.Eligibility,
      children: [
        {
          title: HelpCenterCategoryName.ClassifyBusiness,
          slug: HelpFormSlug.AssistEligibility,
          showMessage: true,
        },
        {
          title: HelpCenterCategoryName.RequestQuoteReview,
          slug: HelpFormSlug.RequestReview,
          showMessage: true,
        },
        {
          title: HelpCenterCategoryName.CoverageQuestions,
          slug: HelpFormSlug.InquireCoverage,
          showMessage: true,
        },
        {
          title: HelpCenterCategoryName.BindQuote,
          slug: HelpFormSlug.BindQuote,
          showMessage: true,
        },
        {
          title: HelpCenterCategoryName.BindCpspQuote,
          slug: HelpFormSlug.BindCpspQuote,
          showMessage: true,
        },
        {
          title: HelpCenterCategoryName.ResolveQuoteContingency,
          slug: HelpFormSlug.ResolveQuoteContingency,
          showMessage: true,
        },
        {
          title: HelpCenterCategoryName.QuotingOther,
          slug: HelpFormSlug.OtherQuote,
          showMessage: true,
        },
      ],
    },
    {
      title: HelpCenterSectionName.Other,
      children: [
        {
          title: HelpCenterCategoryName.Other,
          slug: HelpFormSlug.Other,
          showMessage: true,
        },
      ],
    },
  ],
};
// End Help Center Request Categories
