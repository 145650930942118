import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { PreferenceService, PreferenceLevel } from '../../../../shared/services/preference.service';
import { carrierAllowsEdit } from '../../../digital-carrier/models/carrier-helpers';
import {
  AttuneBopFrontendQuoteWithLinks,
  AttuneBOPProduct,
  AttuneProduct,
  DigitalCarrierProduct,
  FrontendQuoteBundle,
  isQuotedQuoteResponse,
  isReferralResponse,
  ProductAvailability,
  ProductCombination,
  ProductCombinationForAvailability,
  QuotedQuoteResponse,
  ReferralResponse,
} from '../../../digital-carrier/models/types';
import * as _ from 'lodash';
import { find, get, isEmpty, set, some } from 'lodash';
import {
  AccountSummaryLoadedView,
  InsuredAccountSummaryService,
  AccountSummaryView,
  DigitalCarrierPolicyDetailsWithLinks,
  FrontendQuoteWithLinks,
} from '../../services/insured-account-summary.service';
import { InsuredAccount } from '../../models/insured-account.model';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of as observableOf,
  Subscription,
  timer,
} from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { DraftQuote, DraftQuoteService } from '../../../../shared/services/draft-quote.service';
import { QuoteSummary } from '../../../../shared/models/quote-summary';
import { ATTUNE_WC_GUIDE, EMPLOYERS_WC_GUIDE } from '../../../support/models/support-constants';
import {
  WcBackendPricedQuote,
  WcDocument,
  WcPricedQuote,
  WcQuoteWithDocuments,
} from '../../../../workers-comp/employers/models/wc-priced-quote';
import {
  DIGITAL_PRODUCTS,
  HAB_PRODUCER_CODES,
  HAS_HAB,
  WC_DIGITAL_PRODUCT,
  US_DATE_MASK,
} from '../../../../constants';
import {
  HiscoxBackendPricedQuote,
  HiscoxPricedQuote,
} from '../../../hiscox/models/hiscox-priced-quote';
import {
  DEFAULT_ELIGIBILITY,
  NaicsService,
  ProductEligibility,
} from '../../../../shared/services/naics.service';
import { HISCOX_GL_PL_GUIDE } from '../../../hiscox/models/constants';
import { CrossSellConfiguration } from '../../../../shared/product-cross-sell/configs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { InsuredAccountService } from '../../services/insured-account.service';
import { WcQuoteService } from '../../../../workers-comp/employers/services/workers-comp-quote.service';
import { DigitalCarrierQuoteService } from '../../../digital-carrier/services/digital-carrier-quote.service';
import { HiscoxQuoteService } from '../../../hiscox/services/hiscox-quote.service';
import { InformService } from '../../../../core/services/inform.service';
import { AmplitudeService } from '../../../../core/services/amplitude.service';
import { SentryService } from '../../../../core/services/sentry.service';
import { AttuneBopQuoteService } from '../../../attune-bop/services/attune-bop-quote.service';
import { AttuneWcEligibilityService } from 'app/workers-comp/attune/services/attune-wc-eligibility.service';
import { NON_BINDING_ROLES, UserService } from '../../../../core/services/user.service';
import { InvoicesListService } from '../../../invoices/services/invoices-list.service';
import { UWAlertService } from '../../../../shared/services/uw-alert.service';
import {
  BOP_BIND_INFORMATIONAL,
  NewInformationalService,
} from '../../../../shared/services/new-informational.service';
import {
  FeatureFlagService,
  BOOLEAN_FLAG_NAMES,
  JSON_FLAG_NAMES,
} from '../../../../core/services/feature-flag.service';
import { AttuneBopEligibilityService } from '../../../attune-bop/services/attune-bop-eligibility.service';
import { HISCOX_PRODUCTS, HiscoxQuoteStatus } from '../../../hiscox/models/hiscox-types';
import {
  catchError,
  filter,
  first,
  flatMap,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeWhile,
} from 'rxjs/operators';
import {
  BOP_CLASS_ELIGIBILITY_OVERRIDE_FIELD,
  BOP_GENERAL_ELIGIBILITY_OVERRIDE_FIELD,
  isBopV1,
  isBopV2,
  isBopV2orV3,
} from '../../../attune-bop/models/constants';
import { BopQuoteFormValue } from '../../../attune-bop/models/bop-policy';
import { ProducerDetailsResponse } from '../../../../bop/guidewire/typings';
import { shouldGetBopV2, shouldGetMeToo } from '../../../../shared/helpers/account-helpers';
import * as moment from 'moment';
import { hasQuoteExpired } from '../../../hiscox/models/hiscox-customization';
import {
  API_DATE_FORMAT,
  RESTRICTED_EMPLOYERS_STATES,
} from '../../../../workers-comp/employers/constants';
import { SAMPLE_NOT_AVAILABLE_MESSAGE } from '../../../../shared/services/onboarding.service';
import { formatMoneyNoCents } from '../../../../shared/helpers/number-format-helpers';
import { getAttuneWCBrandName } from 'app/workers-comp/attune/helpers/branding';

const DOCUMENT_POLL_INTERVAL = 5000;
const DOCUMENT_POLL_MAX_MS = 60 * 1000;
// Hiscox quotes are available to renew 60 days in advance
const DAYS_UNTIL_HISCOX_AUTO_RENEW = 60;

interface QuoteListItem {
  routerLink: string[] | null;
  quoteNumber: string | null;
  lastUpdated: moment.Moment;
  status: string;
  cost: number | null;
  uwCompanyCode?: string;
  AutoBindRollOver_ATTN: boolean;
}

interface BopQuoteListItem extends QuoteListItem {
  hasExcessPolicy: boolean;
}

@Component({
  selector: 'app-insured-account-new-quote',
  templateUrl: './insured-account-new-quote.component.html',
  providers: [CurrencyPipe],
})
export class InsuredAccountNewQuoteComponent implements OnInit, OnDestroy {
  @Input() showBackButton = true;
  @Input() excludeNonCrossSell = false;
  @Input() excludeNeptune = false;
  @Input() showAlternativeCarriersOnly = false;
  @Input() compact = false;
  model: InsuredAccount = new InsuredAccount();
  model$: Observable<InsuredAccount>;
  nonBindingRole: boolean;
  loading = true;
  hasEmployersValidationErrors = false;
  hasEmployersFeinError = false;
  employersValidationErrorsAndWarnings: string[];
  neptuneFloodQuote: QuotedQuoteResponse | ReferralResponse | null = null;
  accountId: string;
  US_DATE_MASK = US_DATE_MASK;
  isEmployersWorkersCompEligible = true;
  isSampleAccount = false;
  digitalProductsRolledOver = environment.digitalProductsRolledOver as string[];
  digitalProducts = this.displayableDigitalProducts();

  // BOP Quote Variables
  bopDraftQuotes: DraftQuote[] = [];
  isBopEligible = true;
  bopPrefillDeclineReasons: string[] = [];
  isBOPCatRestrictedForProducer = false;
  recentNonDeclinedBopV1Quote: QuoteSummary | undefined;
  recentNonDeclinedBopPlusQuote: QuoteSummary | undefined;

  // Attune WC Quote variables
  recentNonDeclinedAttuneWcQuote: QuoteSummary | undefined;
  isEverpeakEnabled = false;
  isAdpWcUser = false;
  attuneWcEnabledStates: string[];
  isAttuneWcEligible = true;
  attuneWcGuidelinesLink = ATTUNE_WC_GUIDE;
  // Helper function to get Attune WC brand name;
  getAttuneWCBrandName = getAttuneWCBrandName;
  attuneWcEligibilityDeclineReasons: string[] = [];
  attuneWcDraftQuotes: DraftQuote[] = [];

  // Employers WC Quote Variables
  employersWcQuoteLoading = true;
  wcQuoteReferring = false;
  wcQuoteServerError = false;
  showWcPendingReferModal = false;
  hasWcQuote = false;
  employersWcDraft: DraftQuote | undefined;
  hasBoundWcQuote = false;
  wcQuote: WcPricedQuote;
  wcDigitalProduct = WC_DIGITAL_PRODUCT;
  isEmployersWcAvailable = false;
  employersWcProduct: ProductCombination = {
    pasSource: 'employers',
    product: 'wc',
  };

  // GL V4 Quote Variables
  isGlV4Eligible = false;
  glV4QuoteLoading = false;
  glV4QuoteLetterLoading = false;
  glV4QuoteLetterError = false;
  glV4Quote: HiscoxPricedQuote;
  hasGlV4Quote = false;
  hasBoundGlV4Quote = false;
  glV4QuoteExpired = false;
  isGlV4Available = false;
  glV4Product: ProductCombination = {
    pasSource: 'hiscox',
    product: 'gl',
  };

  isNeptuneFloodAvailable = false;
  loadingNeptune = false;

  // PL V4 Quote Variables
  isPlV4Eligible = false;
  plV4QuoteLoading = false;
  plV4QuoteLetterLoading = false;
  plV4QuoteLetterError = false;
  plV4Quote: HiscoxPricedQuote;
  hasPlV4Quote = false;
  hasBoundPlV4Quote = false;
  plV4QuoteExpired = false;
  isPlV4Available = false;
  plV4Product: ProductCombination = {
    pasSource: 'hiscox',
    product: 'pl',
  };

  // `eligibilities` is determined by AskKodiak for all insurance products
  // whereas `productAvailabilities` is determined in SQ for insurance products
  // on DCP, e.g. LM BOP. An insurance product may be eligible for a given state
  // and classcode, i.e. within appetite, but might not be available to a
  // broker.
  eligibilities: ProductEligibility;
  productAvailabilities$: BehaviorSubject<ProductAvailability[]> = new BehaviorSubject<
    ProductAvailability[]
  >([]);
  productAvailabilities: ProductAvailability[];
  productAvailabilitiesLoading = true;
  formatMoneyNoCents = formatMoneyNoCents;

  // V4 / Digital Carrier Partners implementation
  accountSummaryView$: BehaviorSubject<AccountSummaryView> = new BehaviorSubject({
    status: 'loading',
  });
  private sub: Subscription = new Subscription();
  quoteNames: Record<string, string> = {};
  showUWAlertWarning$: Observable<boolean>;
  invoices: BackendListInvoice[];

  bopQuoteTableData: BopQuoteListItem[] = [];
  bopPlusQuoteTableData: BopQuoteListItem[] = [];

  hiscoxOutages: Observable<boolean | null>;

  // Product combination for Neptune Flood
  neptuneFloodProduct: ProductCombination = {
    pasSource: 'neptune',
    product: 'flood',
  };

  hiscoxGlPlGuidelinesLink = HISCOX_GL_PL_GUIDE;
  employersWcGuidelinesLink = EMPLOYERS_WC_GUIDE;
  wcPreferenceLevel: PreferenceLevel | null = null;
  showWcStronglyPreferred = false;
  bopPreferenceLevel: PreferenceLevel | null = null;
  doesAccountHaveCyberQuotes = false;
  isBrokerEnabledForCyber = false;
  isAccountEligibleForCyber = false;
  isCrossSellWindowEnabled = false;
  isEligibilityOverride = false;
  isClassEligibilityOverride = false;
  hiscoxGlPlCyberCrossSellConfiguration: CrossSellConfiguration | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private insuredAccountService: InsuredAccountService,
    private wcQuoteService: WcQuoteService,
    private digitalCarrierQuoteService: DigitalCarrierQuoteService,
    private hiscoxQuoteService: HiscoxQuoteService,
    private naicsService: NaicsService,
    private draftQuoteService: DraftQuoteService,
    private informService: InformService,
    private amplitudeService: AmplitudeService,
    private sentryService: SentryService,
    private bopQuoteService: AttuneBopQuoteService,
    private userService: UserService,
    private billingListService: InvoicesListService,
    private currencyPipe: CurrencyPipe,
    private uwAlertService: UWAlertService,
    private newInformationalService: NewInformationalService,
    private accountSummaryService: InsuredAccountSummaryService,
    protected featureFlagService: FeatureFlagService,
    private attuneBopEligibilityService: AttuneBopEligibilityService,
    private preferenceService: PreferenceService,
    private attuneWcEligibilityService: AttuneWcEligibilityService
  ) {}

  // Lifecycle functions
  ngOnInit() {
    // Check if Hiscox renewal policies outages feature flag is enabled
    this.hiscoxOutages = this.featureFlagService.isEnabled(
      BOOLEAN_FLAG_NAMES.HISCOX_RENEWAL_POLICY_OUTAGES
    );

    this.getInsuredAccountOnRouteChanges();
    this.getDigitalAccountSummaryOnRouteChanges();
    this.getWorkersCompQuoteOnRouteChanges();
    // get gl v4 quote
    this.getGlQuoteOnRouteChanges();
    // get gl v4 quote
    this.getPlQuoteOnRouteChanges();
    this.showWorkersCompToast();
    this.loadInvoices();
    this.loadNeptuneFloodQuote();
    this.updateProductEligibility();
    this.updateAvailability();
    this.getBopPrefillDeclinesOnRouteChanges();
    this.getAttuneWcEligibilityDeclinesOnRouteChanges();

    this.showUWAlertWarning$ = this.route.params.pipe(
      switchMap((params) => {
        return forkJoin(this.uwAlertService.fetchAlerts(params.accountId), this.model$);
      }),
      map(([uwAlerts, model]) => {
        // Note: Check `this.model.bopQuotes` length incase we create a UW alert, but a quote fails (since these process happen in parallel)
        return (
          uwAlerts && uwAlerts.length > 0 && model && model.bopQuotes && model.bopQuotes.length > 0
        );
      }),
      shareReplay(1),
      startWith(false)
    );

    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.SHOW_WC_STRONGLY_PREFERRED)
        .subscribe((isEnabled) => {
          this.showWcStronglyPreferred = isEnabled || false;
        })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  // Init Functions
  getInsuredAccountOnRouteChanges() {
    this.model$ = this.route.params.pipe(
      switchMap((params) => {
        return this.insuredAccountService.get(params['accountId']);
      })
    );

    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.EVERPEAK_WORKERS_COMP)
        .subscribe((enabled) => {
          this.isEverpeakEnabled = enabled || false;
        })
    );

    this.sub.add(
      this.featureFlagService
        .getJsonFlagValue<string[]>(JSON_FLAG_NAMES.ATTUNE_WC_ENABLED_STATES)
        .subscribe((attuneWcEnabledStates) => {
          this.attuneWcEnabledStates = attuneWcEnabledStates || [];
        })
    );

    this.sub.add(
      this.featureFlagService.isEnabled(BOOLEAN_FLAG_NAMES.ADP_WC_ACCESS).subscribe((enabled) => {
        this.isAdpWcUser = enabled || false;
      })
    );

    this.sub.add(
      this.route.params
        .pipe(
          switchMap((params) => {
            this.accountId = params['accountId'];
            this.loading = true;

            // Set to true in the case where we are overriding eligibility to always be true
            // (for the sake of gathering data even about illegible class codes)
            this.isEligibilityOverride = !!localStorage.getItem(
              `${BOP_GENERAL_ELIGIBILITY_OVERRIDE_FIELD}_${this.accountId}`
            );
            this.isClassEligibilityOverride = !!localStorage.getItem(
              `${BOP_CLASS_ELIGIBILITY_OVERRIDE_FIELD}_${this.accountId}`
            );

            return combineLatest(
              this.model$,
              this.draftQuoteService.get(this.accountId),
              this.bopQuoteService.getNames(this.accountId)
            );
          })
        )
        .subscribe(
          ([insuredAccount, draftQuotes, names]) => {
            const isCurrentAccount = this.accountId === insuredAccount.id.toString();
            this.model = insuredAccount;

            if (!insuredAccount.naicsCode) {
              this.sentryService.notify('No naics code on insured account.', {
                severity: 'warning',
                metaData: {
                  insuredAccount,
                },
              });
            }

            this.quoteNames = names.quoteNames || {};
            this.isSampleAccount = insuredAccount.sampleAccount || false;

            const bopProductName = 'Businessowners';
            this.bopDraftQuotes = draftQuotes.filter((drafts) => {
              const formData = drafts.formData as DeepPartial<BopQuoteFormValue>;
              return (
                drafts.product === 'bop' &&
                drafts.pasSource === 'attune_gw' &&
                formData?.policyInfo?.bopVersion !== null
              );
            });

            this.generateBopQuoteTableData();

            // Employers WC will only have one draft as there is only ever one WC quote created at a time for an account.

            this.employersWcDraft = draftQuotes.filter(
              (draft) => draft.product === 'wc' && draft.pasSource === 'employers'
            )[0];

            // Attune WC drafts
            this.attuneWcDraftQuotes = draftQuotes.filter((draft) => {
              return draft.pasSource === 'attune_gw' && draft.product === 'wc';
            });

            if (isCurrentAccount) {
              this.recentNonDeclinedBopV1Quote = insuredAccount.bopQuotes.find((quote) => {
                return (
                  quote.productName.includes(bopProductName) &&
                  ['quoted', 'quoting'].includes(quote.status.toLowerCase()) &&
                  isBopV1(quote.uwCompanyCode)
                );
              });

              this.recentNonDeclinedBopPlusQuote = insuredAccount.bopQuotes.find((quote) => {
                return (
                  quote.productName.includes(bopProductName) &&
                  ['quoted', 'quoting'].includes(quote.status.toLowerCase()) &&
                  isBopV2(quote.uwCompanyCode)
                );
              });

              this.recentNonDeclinedAttuneWcQuote = insuredAccount.attuneWcQuotes.find((quote) => {
                return ['quoted', 'quoting'].includes(quote.status.toLowerCase());
              });

              if (insuredAccount.naicsCode) {
                this.wcPreferenceLevel = null;
                this.bopPreferenceLevel = null;
                this.getPreferences(insuredAccount.naicsCode.hash, insuredAccount.state);
                forkJoin(
                  this.naicsService.getProductEligibility(
                    insuredAccount.naicsCode.hash,
                    insuredAccount.state
                  ),
                  this.insuredAccountService
                    .isProducerCatRestricted(this.model.getProducerCodes()[0], this.model.state)
                    // Use a default value here to prevent eligibility processing from hanging.
                    .pipe(catchError(() => observableOf(false)))
                ).subscribe(
                  ([eligibilities, isRestricted]) => {
                    this.isBOPCatRestrictedForProducer = isRestricted;
                    if (eligibilities) {
                      return isCurrentAccount && this.onCompleteNaics(eligibilities);
                    }
                  },
                  (err) => {
                    console.warn(err.message);
                    return isCurrentAccount && this.onCompleteNaics(DEFAULT_ELIGIBILITY);
                  }
                );

                this.naicsService.getProductAvailability().subscribe((resp) => {
                  this.productAvailabilities = resp;
                  this.productAvailabilities$.next(resp);
                  this.productAvailabilitiesLoading = false;
                });
              } else {
                this.insuredAccountService
                  .isProducerCatRestricted(this.model.getProducerCodes()[0], this.model.state)
                  .subscribe((isRestricted) => {
                    this.isBOPCatRestrictedForProducer = isRestricted;
                  });

                this.productAvailabilitiesLoading = false;
                this.onCompleteNaics(DEFAULT_ELIGIBILITY);
              }
            }
          },
          (error) => {
            console.warn(
              '*** error response from insuredAccountService.get ***' + error.toString()
            );
            this.onCompleteNaics(DEFAULT_ELIGIBILITY);
          }
        )
    );

    this.sub.add(
      this.userService
        .getUser()
        .pipe(
          switchMap((user) => {
            return this.insuredAccountService.getProducerDetails(user.producer);
          })
        )
        .subscribe((response: ProducerDetailsResponse) => {
          this.nonBindingRole = response.Roles.Entry.every((role) =>
            NON_BINDING_ROLES.includes(role)
          );
        })
    );
  }

  getBopPrefillDeclinesOnRouteChanges() {
    this.sub.add(
      this.route.params
        .pipe(
          switchMap((params) => {
            this.bopPrefillDeclineReasons = [];
            return this.attuneBopEligibilityService.getEligibilityDeclineReasons(params.accountId);
          })
        )
        .subscribe((response) => {
          this.bopPrefillDeclineReasons = response.declineReasons;
        })
    );
  }

  brokerIsEligibleForBOP() {
    const productAvailability = find(this.productAvailabilities, {
      pasSource: 'attune_gw',
      product: 'bop',
    }) as ProductAvailability;

    if (!productAvailability) {
      return false;
    }

    return productAvailability.classCodeSelection !== 'NONE';
  }

  getDigitalAccountSummaryOnRouteChanges() {
    if (!this.anyProductsRolledOver()) {
      return;
    }

    this.sub.add(
      this.route.params
        .pipe(
          switchMap((params) => {
            this.accountSummaryView$.next({
              status: 'loading',
            });
            return this.accountSummaryService.getSummary(params.accountId);
          })
        )
        .subscribe(
          (accountSummary: AccountSummaryView) => {
            this.accountSummaryView$.next(accountSummary);
          },
          (e: Error) => {
            this.accountSummaryView$.next({
              status: 'error',
              errorMessage: `There was an error loading this account: ${e.message}`,
            });
          }
        )
    );
  }

  getWorkersCompQuoteOnRouteChanges() {
    this.sub.add(
      this.route.params
        .pipe(
          switchMap((params) => {
            this.resetWcLoadingState();
            return this.wcQuoteService.getQuote(params['accountId']);
          })
        )
        .subscribe(
          (wcQuoteResponse: WcQuoteWithDocuments) => {
            this.processWcQuoteResponse(wcQuoteResponse.quote, wcQuoteResponse.documents);
          },
          (e: any) => {
            this.employersWcQuoteLoading = false;
            this.wcQuoteServerError = true;
          }
        )
    );
  }

  getGlQuoteOnRouteChanges() {
    this.sub.add(
      this.route.params
        .pipe(
          switchMap((params) => {
            this.setGlV4LoadingState();
            return this.hiscoxQuoteService.getQuote(HISCOX_PRODUCTS.gl, params['accountId']);
          })
        )
        .subscribe(
          (glQuoteResponse: HiscoxBackendPricedQuote) => {
            this.processGlQuoteResponse(glQuoteResponse);
          },
          (glHttpError: HttpErrorResponse) => {
            this.processGlQuoteResponse(glHttpError);
          }
        )
    );
  }

  getPlQuoteOnRouteChanges() {
    this.sub.add(
      this.route.params
        .pipe(
          switchMap((params) => {
            this.setPlV4LoadingState();
            return this.hiscoxQuoteService.getQuote(HISCOX_PRODUCTS.pl, params['accountId']);
          })
        )
        .subscribe(
          (plQuoteResponse: HiscoxBackendPricedQuote) => {
            this.processPlQuoteResponse(plQuoteResponse);
          },
          (plHttpError: HttpErrorResponse) => {
            this.processPlQuoteResponse(plHttpError);
          }
        )
    );
  }

  showWorkersCompToast() {
    this.sub.add(
      this.route.queryParams.subscribe((params) => {
        if (params['wc-bound']) {
          this.informService.successIconToast(
            `View our FAQs to learn how your insured can access WC invoices online.`,
            'check_cutout',
            'Invoice ready',
            'view',
            (buttonClicked: boolean) => {
              if (buttonClicked) {
                window.open(
                  'https://attunehelp.zendesk.com/hc/en-us/articles/360032231151-How-does-EMPLOYERS-bill-',
                  '_blank'
                );
              }
            },
            15000
          );
        }
      })
    );
  }

  loadInvoices() {
    this.sub.add(
      this.route.params
        .pipe(
          switchMap((params) => {
            this.accountId = params['accountId'];
            return this.userService.getUser();
          }),
          switchMap((user) => {
            return this.billingListService.getInvoiceList(
              user.producer,
              0,
              25,
              undefined,
              this.accountId,
              undefined
            );
          })
        )
        .subscribe((invoiceListOrError) => {
          if (invoiceListOrError && (invoiceListOrError as HttpErrorResponse).error) {
            this.sentryService.notify(
              'Policies pane: Received an error fetching account invoices',
              {
                severity: 'error',
                metaData: (invoiceListOrError as HttpErrorResponse).error,
              }
            );
            return;
          }
          this.invoices = (invoiceListOrError as BackendInvoiceSummary).invoices;

          if (
            this.newInformationalService.isUserAtBopBindState(
              BOP_BIND_INFORMATIONAL.BILLING_CARD_TIP
            ) &&
            (this.loading || this.insuredAccountHasActivePolicies()) &&
            this.invoices.length === 0
          ) {
            // wait fifteen seconds and then refresh the data
            const FIFTEEN_SECONDS = 15 * 1000;
            setTimeout(() => this.loadInvoices(), FIFTEEN_SECONDS);
          }
        })
    );
  }

  loadNeptuneFloodQuote() {
    if (this.excludeNeptune) {
      return;
    }
    this.sub.add(
      combineLatest(
        this.model$,
        this.productAvailabilities$.pipe(
          filter((availabilities) => availabilities.length !== 0),
          first()
        )
      ).subscribe(([model, productAvailabilites]) => {
        const productAvailability = find(
          productAvailabilites,
          this.neptuneFloodProduct
        ) as ProductAvailability;
        this.neptuneFloodQuote = null;
        if (
          productAvailability &&
          productAvailability.classCodeSelection === 'PARTIAL' &&
          model.naicsCode &&
          productAvailability.classCodes.naics.includes(model.naicsCode.code)
        ) {
          this.getFloodQuote(model);
        } else if (productAvailability && productAvailability.classCodeSelection === 'ALL') {
          this.getFloodQuote(model);
        }
      })
    );
  }

  updateProductEligibility() {
    this.sub.add(
      combineLatest([
        this.naicsService.updateProductEligibility.pipe(
          filter((updatedNaicsDetails) => !!updatedNaicsDetails),
          switchMap(
            (updatedNaicsDetails: {
              naicsCode: NaicsCode;
              stateCode: string;
              accountId: string;
            }) => {
              this.loading = true;

              const currentNaicsHash = this.model.naicsCode && this.model.naicsCode.hash;

              if (
                updatedNaicsDetails.accountId === this.accountId &&
                currentNaicsHash !== updatedNaicsDetails.naicsCode.hash
              ) {
                return this.naicsService.getProductEligibility(
                  updatedNaicsDetails.naicsCode.hash,
                  updatedNaicsDetails.stateCode
                );
              }
              return observableOf(null);
            }
          )
        ),
        this.insuredAccountService.isProducerCatRestricted(
          this.model.getProducerCodes()[0],
          this.model.state
        ),
      ]).subscribe({
        next: ([eligibilities, isRestricted]) => {
          this.isBOPCatRestrictedForProducer = isRestricted;
          if (eligibilities) {
            this.onCompleteNaics(eligibilities);
          }
          this.loading = false;
        },
        error: (err) => {
          this.sentryService.notify('Unable to update NAICS product eligibility', {
            severity: 'error',
            metaData: {
              updatedNaicsDetails: this.naicsService.updateProductEligibility.value,
              underlyingError: err,
              underlyingErrorMessage: err && err.message,
            },
          });
          this.loading = false;
        },
      })
    );
  }

  // Sets visibility for WC, PL, GL, NEPTUNE products that do not use the product card component
  updateAvailability = () => {
    this.sub.add(
      combineLatest([
        this.model$,
        this.productAvailabilities$.pipe(
          filter((availabilities) => availabilities.length !== 0),
          first()
        ),
      ]).subscribe(([model, productAvailabilites]) => {
        [
          this.glV4Product,
          this.plV4Product,
          this.employersWcProduct,
          this.neptuneFloodProduct,
        ].forEach((product) => {
          const productAvailability = find(productAvailabilites, product) as ProductAvailability;
          if (
            productAvailability &&
            productAvailability.classCodeSelection === 'PARTIAL' &&
            model.naicsCode &&
            productAvailability.classCodes.naics.includes(model.naicsCode.code)
          ) {
            this.setAvailabilityFlag(product, true);
          } else if (productAvailability && productAvailability.classCodeSelection === 'ALL') {
            this.setAvailabilityFlag(product, true);
          } else {
            this.setAvailabilityFlag(product, false);
          }
        });
      })
    );
  };

  // OFAC warning
  showOfacWarning() {
    return this.model.ofacAlertPresent;
  }

  // Billing Card
  shouldShowBillingSection() {
    const digitalCarrierAccountSummary = this.accountSummaryView() as AccountSummaryLoadedView;

    return (
      (this.insuredAccountHasActivePolicies() && !!this.invoices) ||
      (digitalCarrierAccountSummary.policyTerms &&
        digitalCarrierAccountSummary.policyTerms.some((term) => term.pasSource === 'coalition'))
    );
  }

  // BOP+ Card
  insuredAccountHasBopPlus() {
    if (this.loading) {
      return false;
    }

    return this.bopPlusQuoteTableData.length > 0 || shouldGetBopV2(this.model.state);
  }

  insuredAccountHasBopPlusInForce() {
    return !this.loading && this.model.bopV2Policies && this.model.bopV2Policies.length > 0;
  }

  insuredAccountHasAttuneWcInForce() {
    return !this.loading && this.model?.attuneWcPolicies?.length > 0;
  }

  insuredAccountHasBopPlusQuotesOrDrafts() {
    return this.bopPlusQuoteTableData.length > 0;
  }

  insuredAccountHasMeToo() {
    return shouldGetMeToo(this.model.state);
  }

  // TODO: get back here
  listBopPlusQuotes(): BopQuoteListItem[] {
    if (this.isEligibilityOverride) {
      return this.bopPlusQuoteTableData.map((quote) => {
        return {
          ...quote,
          status: 'Review',
        };
      });
    }
    return this.bopPlusQuoteTableData;
  }

  listInForceBopPlus() {
    return this.model.bopV2Policies;
  }

  // BOP v1 Card
  insuredAccountHasBopV1() {
    if (this.loading) {
      return false;
    }

    return this.bopQuoteTableData.length > 0 || !shouldGetBopV2(this.model.state);
  }

  insuredAccountHasBopV1QuotesOrDrafts() {
    return this.bopQuoteTableData.length > 0;
  }

  listBopQuotes(): BopQuoteListItem[] {
    if (!this.bopQuoteTableData) {
      this.generateBopQuoteTableData();
    }

    if (this.isEligibilityOverride) {
      return this.bopQuoteTableData.map((quote) => {
        return {
          ...quote,
          status: 'Review',
        };
      });
    }
    return this.bopQuoteTableData;
  }

  listInForceBopPolicies() {
    return this.model.bopV1Policies;
  }

  listHabQuotes(): QuoteListItem[] {
    const habQuotes = this.model.habQuotes.map((quote) => ({
      routerLink: this.isQuoteLinkable(quote) ? ['hab', 'policies', quote.id] : null,
      quoteNumber: quote.id,
      lastUpdated: quote.timeLastUpdated,
      status: this.status(quote.status),
      cost: quote.totalCost,
      AutoBindRollOver_ATTN: false,
    }));

    return habQuotes.sort((a, b) => (a.lastUpdated < b.lastUpdated ? 1 : -1));
  }

  isHabOn(): boolean {
    if (HAS_HAB) {
      return true;
    }
    const userProducerCodes: string[] = this.model.getProducerCodes();
    return userProducerCodes.some((code) => HAB_PRODUCER_CODES.includes(code));
  }

  // GL V4 Card
  continueToGlV4Bind() {
    if (this.glV4QuoteExpired) {
      this.informService.infoToast(
        'The effective date for this General Liability v4 quote has expired. Please edit this quote and update the effective date in order to bind.'
      );
      return;
    }

    this.router.navigate(
      ['hiscox/gl/policies/', this.glV4Quote.quoteId, 'bind', this.glV4Quote.version],
      {
        relativeTo: this.route,
      }
    );
  }

  getGlV4QuoteLetterBlob() {
    // EOD needed, otherwise formatting this in current timezone puts date on previous day
    if (this.glV4Quote.effectiveDate) {
      const effectiveDateUTC = this.glV4Quote.effectiveDate.endOf('day').format();
      const tz = moment.tz.guess();
      // Quote effective date is valid through end of day
      const effectiveDateLocal = moment.tz(effectiveDateUTC, tz).endOf('day');
      const currentDateTime = moment.tz(tz);

      if (effectiveDateLocal.isBefore(currentDateTime)) {
        this.informService.infoToast(
          'General Liability quote effective date is in the past, please edit and requote to access quote letter.'
        );
        return;
      }
    } else {
      this.informService.errorToast(
        'We encountered an error loading the GL quote letter for this account.',
        null,
        'Hiscox quote letter request error.',
        'Okay',
        null,
        0
      );
      return;
    }

    this.glV4QuoteLetterLoading = true;

    const accountId = this.route.snapshot.params.accountId;
    const quoteLetterBlob = this.hiscoxQuoteService.getQuoteLetterBlob('gl', accountId).subscribe(
      (hiscoxQuoteLetterBlobResponse: any) => {
        this.glV4Quote.hasQuoteLetter = true;
        this.glV4QuoteLetterLoading = false;
        const link = document.createElement('a');
        link.download = `Attune-GL-V4-Quote-Letter-${accountId}-${this.glV4Quote.quoteId}.pdf`;
        link.href = window.URL.createObjectURL(hiscoxQuoteLetterBlobResponse);
        link.click();
        link.remove();
      },
      (hiscoxHttpError: HttpErrorResponse) => {
        this.glV4Quote.hasQuoteLetter = false;
        this.glV4QuoteLetterError = true;
        this.glV4QuoteLetterLoading = false;
        this.informService.errorToast(
          'We encountered an error loading the GL quote letter for this account.',
          null,
          'Hiscox quote letter request error.',
          'Okay',
          null,
          0
        );
      }
    );
  }

  isGlV4Loading() {
    return this.glV4QuoteLoading || this.loading;
  }

  showGlV4GetQuote() {
    return !this.hasGlV4Quote;
  }

  showGlV4EditQuote() {
    return this.hasGlV4Quote && this.glV4Quote.status !== HiscoxQuoteStatus.BOUND;
  }

  showGlV4BindQuote() {
    return this.hasGlV4Quote && this.glV4Quote.isQuoted() && !this.glV4Quote.isBound();
  }

  processGlV4QuoteResponse(glQuoteResponse: HiscoxBackendPricedQuote | HttpErrorResponse) {
    this.glV4QuoteLoading = false;

    if (glQuoteResponse instanceof HttpErrorResponse) {
      this.hasGlV4Quote = false;
      return;
    }

    this.hasGlV4Quote = !isEmpty(glQuoteResponse);

    if (this.hasGlV4Quote) {
      this.glV4Quote = new HiscoxPricedQuote(<HiscoxBackendPricedQuote>glQuoteResponse);

      this.glV4QuoteExpired = hasQuoteExpired(this.glV4Quote);
    } else {
      this.glV4Quote = new HiscoxPricedQuote(<HiscoxBackendPricedQuote>{});
    }
  }

  processGlQuoteResponse(glQuoteResponse: HiscoxBackendPricedQuote | HttpErrorResponse) {
    this.processGlV4QuoteResponse(glQuoteResponse);
  }

  setGlV4LoadingState() {
    this.glV4QuoteLoading = true;
    this.hasGlV4Quote = false;
    this.hasBoundGlV4Quote = false;
  }

  showGlV4Quote() {
    return this.hasGlV4Quote && !this.glV4Quote.isBound();
  }

  showGlV4BoundQuote() {
    return this.hasGlV4Quote && this.glV4Quote.isBound();
  }

  showGlV4ReferredMessage() {
    return this.hasGlV4Quote && this.glV4Quote.isReferred();
  }

  showGlV4DownloadQuoteLetter() {
    return (
      this.hasGlV4Quote &&
      !this.glV4Quote.isDeclined() &&
      !this.glV4Quote.isReferred() &&
      !this.glV4QuoteLetterError &&
      !this.glV4QuoteLetterLoading
    );
  }

  showGlV4DocumentLoading() {
    return this.hasGlV4Quote && this.glV4Quote.isQuoted() && this.glV4QuoteLetterLoading;
  }

  showGlV4DownloadError() {
    return this.hasGlV4Quote && !this.glV4Quote.isDeclined() && this.glV4QuoteLetterError;
  }

  // PL V4 Card
  continueToPlV4Bind() {
    if (this.plV4QuoteExpired) {
      this.informService.infoToast(
        'The effective date for this Professional Liability v4 quote has expired. Please edit this quote and update the effective date in order to bind.'
      );
      return;
    }

    this.router.navigate(
      ['hiscox/pl/policies/', this.plV4Quote.quoteId, 'bind', this.plV4Quote.version],
      {
        relativeTo: this.route,
      }
    );
  }

  getPlV4QuoteLetterBlob() {
    // EOD needed, otherwise formatting this in current timezone puts date on previous day
    if (this.plV4Quote.effectiveDate) {
      const effectiveDateUTC = this.plV4Quote.effectiveDate.endOf('day').format();
      const tz = moment.tz.guess();
      // Quote effective date is valid through end of day
      const effectiveDateLocal = moment.tz(effectiveDateUTC, tz).endOf('day');
      const currentDateTime = moment.tz(tz);

      if (effectiveDateLocal.isBefore(currentDateTime)) {
        this.informService.infoToast(
          'Professional Liability quote effective date is in the past, please edit and requote to access quote letter.'
        );
        return;
      }
    } else {
      this.informService.errorToast(
        'We encountered an error loading the PL quote letter for this account.',
        null,
        'Hiscox quote letter request error.',
        'Okay',
        null,
        0
      );
      return;
    }

    this.plV4QuoteLetterLoading = true;

    const accountId = this.route.snapshot.params.accountId;
    this.hiscoxQuoteService.getQuoteLetterBlob('pl', accountId).subscribe(
      (hiscoxQuoteLetterBlobResponse: any) => {
        this.plV4Quote.hasQuoteLetter = true;
        this.plV4QuoteLetterLoading = false;
        const link = document.createElement('a');
        link.download = `Attune-PL-V4-Quote-Letter-${accountId}-${this.plV4Quote.quoteId}.pdf`;
        link.href = window.URL.createObjectURL(hiscoxQuoteLetterBlobResponse);
        link.click();
        link.remove();
      },
      (hiscoxHttpError: HttpErrorResponse) => {
        console.log('hiscox pdf blob error', hiscoxHttpError);
        this.plV4Quote.hasQuoteLetter = false;
        this.plV4QuoteLetterError = true;
        this.plV4QuoteLetterLoading = false;
        this.informService.errorToast(
          'We encountered an error loading the PL quote letter for this account.',
          null,
          'Hiscox quote letter request error.',
          'Okay',
          null,
          0
        );
      }
    );
  }

  isPlV4Loading() {
    return this.plV4QuoteLoading || this.loading;
  }

  showPlV4GetQuote() {
    return !this.hasPlV4Quote;
  }

  showPlV4EditQuote() {
    return this.hasPlV4Quote && this.plV4Quote.status !== HiscoxQuoteStatus.BOUND;
  }

  showPlV4BindQuote() {
    return this.hasPlV4Quote && this.plV4Quote.isQuoted() && !this.plV4Quote.isBound();
  }

  processPlV4QuoteResponse(plQuoteResponse: HiscoxBackendPricedQuote | HttpErrorResponse) {
    this.plV4QuoteLoading = false;

    if (plQuoteResponse instanceof HttpErrorResponse) {
      this.hasPlV4Quote = false;
      return;
    }

    this.hasPlV4Quote = !isEmpty(plQuoteResponse);

    if (this.hasPlV4Quote) {
      this.plV4Quote = new HiscoxPricedQuote(<HiscoxBackendPricedQuote>plQuoteResponse);

      this.plV4QuoteExpired = hasQuoteExpired(this.plV4Quote);
    } else {
      this.plV4Quote = new HiscoxPricedQuote(<HiscoxBackendPricedQuote>{});
    }
  }

  processPlQuoteResponse(plQuoteResponse: HiscoxBackendPricedQuote | HttpErrorResponse) {
    this.processPlV4QuoteResponse(plQuoteResponse);
  }

  setPlV4LoadingState() {
    this.plV4QuoteLoading = true;
    this.hasPlV4Quote = false;
    this.hasBoundPlV4Quote = false;
  }

  showPlV4Quote() {
    return this.hasPlV4Quote && !this.plV4Quote.isBound();
  }

  showPlV4BoundQuote() {
    return this.hasPlV4Quote && this.plV4Quote.isBound();
  }

  showPlV4ReferredMessage() {
    return this.hasPlV4Quote && this.plV4Quote.isReferred();
  }

  showPlV4DownloadQuoteLetter() {
    return (
      this.hasPlV4Quote &&
      !this.plV4Quote.isDeclined() &&
      !this.plV4Quote.isReferred() &&
      !this.plV4QuoteLetterError &&
      !this.plV4QuoteLetterLoading
    );
  }

  showPlV4DocumentLoading() {
    return this.hasPlV4Quote && this.plV4Quote.isQuoted() && this.plV4QuoteLetterLoading;
  }

  showPlV4DownloadError() {
    return this.hasPlV4Quote && !this.plV4Quote.isDeclined() && this.plV4QuoteLetterError;
  }

  // WC Card
  isEmployersWcLoading() {
    return this.employersWcQuoteLoading || this.loading || this.productAvailabilitiesLoading;
  }

  showWcGetQuote() {
    return (
      !this.hasWcQuote &&
      !this.employersWcDraft &&
      this.isStateWithEmployers(this.model.state) &&
      !this.hasEmployersValidationErrors
    );
  }

  showWcEditQuote() {
    const isQuotedIndicatedOrExpired = this.hasWcQuote
      ? this.wcQuote.isQuoted() || this.wcQuote.isIndication() || this.wcQuote.isExpired()
      : false;
    return isQuotedIndicatedOrExpired || this.hasEmployersValidationErrors;
  }

  showWcDraftQuote() {
    return (
      !this.hasWcQuote &&
      this.employersWcDraft &&
      this.isStateWithEmployers(this.model.state) &&
      !this.hasEmployersValidationErrors
    );
  }

  showWcRequote() {
    return this.hasWcQuote && this.wcQuote.isExpired();
  }

  showWcDownloadError() {
    return this.hasWcQuote && this.wcQuote.isQuoted() && !this.wcQuote.hasQuoteLetter;
  }

  showWcDocumentLoading() {
    return (
      this.hasWcQuote &&
      this.wcQuote.isQuoted() &&
      this.wcQuote.hasQuoteLetter &&
      !this.wcQuote.quoteLetterDownloadLink
    );
  }

  showWcDownloadQuote() {
    return this.hasWcQuote && !!this.wcQuote.quoteLetterDownloadLink;
  }

  showWcDialog() {
    return this.wcQuote.isReferred() || this.wcQuote.isIndication() || this.wcQuote.isBound();
  }

  showWcBindQuote() {
    return this.hasWcQuote && this.wcQuote.isQuoted();
  }

  showWcQuote() {
    return this.hasWcQuote && !this.wcQuote.isBound();
  }

  hasWcPolicy() {
    const accountSummary = this.accountSummaryView();
    if (accountSummary.status === 'success') {
      return some(
        accountSummary.policyTerms,
        (policyTerm: DigitalCarrierPolicyDetailsWithLinks) => {
          return policyTerm.product === 'wc';
        }
      );
    }
    return false;
  }

  closeWcPendingReferModal() {
    this.showWcPendingReferModal = false;
  }

  referWcQuote() {
    this.closeWcPendingReferModal();
    this.wcQuoteReferring = true;
    const refAccountId = this.route.snapshot.params['accountId'];

    if (!this.wcQuote.uuid) {
      this.wcQuoteServerError = true;
      return;
    }

    this.sub.add(
      this.wcQuoteService
        .referQuote(this.wcQuote.uuid)
        .pipe(
          flatMap((resp: any) => {
            this.resetWcLoadingState();
            this.wcQuoteService.cachebust();
            return this.wcQuoteService.getQuote(refAccountId);
          })
        )
        .subscribe((wcQuoteResponse: WcQuoteWithDocuments) => {
          // If you haven't moved between pages, render newly referred quote.
          if (refAccountId === this.route.snapshot.params['accountId']) {
            this.processWcQuoteResponse(wcQuoteResponse.quote, wcQuoteResponse.documents);
          }
        }, this.processWcQuoteError)
    );
  }

  resetWcLoadingState() {
    this.employersWcQuoteLoading = true;
    this.hasWcQuote = false;
    this.hasEmployersValidationErrors = false;
    this.showWcPendingReferModal = false;
    this.wcQuoteServerError = false;
    this.wcQuoteReferring = false;
    this.hasBoundWcQuote = false;
  }

  requoteWcQuote() {
    // Expired quotes can be requoted with the same original inputs except effective date is updated to today.
    this.employersWcQuoteLoading = true;

    this.sub.add(
      this.wcQuoteService
        .getQuoteSubmission(this.accountId)
        .pipe(
          switchMap((quoteSubmissionResponse) => {
            const currentDate = moment.utc().format(API_DATE_FORMAT);

            // Update expired effective date with today's date.
            set(quoteSubmissionResponse, 'policy.effectiveDate', currentDate);

            return this.wcQuoteService.requestQuote(quoteSubmissionResponse, true);
          })
        )
        .subscribe((response) => {
          this.employersWcQuoteLoading = false;
          this.processWcQuoteResponse(response);

          if (response && response.status && typeof response.status === 'string') {
            // TODO(olex) use explicit event (quoted / declined /pending_refer etc)
            this.amplitudeService.track({
              eventName: String(response.status),
              detail: 'workers_comp',
              useLegacyEventName: true,
            });
          }

          if (response && response.error) {
            this.amplitudeService.track({
              eventName: 'wc_error',
              detail: JSON.stringify(response.error),
              useLegacyEventName: true,
            });
            this.informService.minorErrorToast(
              'We encountered an error while requoting this policy. Please try refreshing the page.',
              null,
              'Failed to requote policy.',
              'Retry',
              () => this.requoteWcQuote(),
              0
            );
            return;
          }
        })
    );
  }

  wcDocumentPdfPoll() {
    this.sub.add(
      timer(0, DOCUMENT_POLL_INTERVAL)
        .pipe(
          takeWhile(() => this.showWcDocumentLoading()),
          map((val: number) => {
            if (val * DOCUMENT_POLL_INTERVAL > DOCUMENT_POLL_MAX_MS) {
              throw new Error(`Error loading WC quote document for ${this.accountId}`);
            }
            return val;
          }),
          switchMap(() => {
            this.wcQuoteService.cachebust();
            return this.wcQuoteService.getQuote(this.accountId);
          })
        )
        .subscribe(
          (wcQuoteResponse: WcQuoteWithDocuments) => {
            this.wcQuote = new WcPricedQuote(
              wcQuoteResponse.quote as WcBackendPricedQuote,
              wcQuoteResponse.documents
            );
          },
          (err) => {
            this.sentryService.notify('Loading WC Quote took > 1 min', {
              severity: 'error',
              metaData: {
                policyNumber: this.wcQuote.policyNumber,
              },
            });
            this.wcQuote.hasQuoteLetter = false;
          }
        )
    );
  }

  processWcQuoteResponse(
    wcQuoteResponse: WcBackendPricedQuote | null,
    wcDocuments: WcDocument[] = []
  ) {
    this.employersWcQuoteLoading = false;

    this.hasWcQuote = !isEmpty(wcQuoteResponse);

    if (!wcQuoteResponse) {
      return;
    }

    this.hasEmployersValidationErrors = wcQuoteResponse.status === 'EMPLOYERS_VALIDATION_ERROR';
    this.hasEmployersFeinError = wcQuoteResponse.employersApiErrors
      ? wcQuoteResponse.employersApiErrors.some((e) => e === 'Prior Submission')
      : false;
    if (this.hasEmployersValidationErrors) {
      this.employersValidationErrorsAndWarnings = get(
        wcQuoteResponse,
        'employersApiErrors',
        []
      ).concat(get(wcQuoteResponse, 'employersApiWarnings', []));
    }

    this.wcQuote = new WcPricedQuote(wcQuoteResponse);
    this.hasBoundWcQuote = this.wcQuote.isBound();

    if (this.showWcDocumentLoading()) {
      this.wcDocumentPdfPoll();
    }

    if (this.wcQuote.justReferred) {
      this.showWcPendingReferModal = true;
    }
  }

  processWcQuoteError(wcQuoteResponse: HttpErrorResponse) {
    this.employersWcQuoteLoading = false;
    this.hasWcQuote = false;
    if (wcQuoteResponse.status >= 500) {
      this.wcQuoteServerError = true;
      return;
    }
  }

  // DCP Card
  anyProductsRolledOver() {
    return this.digitalProductsRolledOver.length;
  }

  accountSummaryView() {
    return this.accountSummaryView$.value;
  }

  // Flood mixin
  getFloodQuote(model: InsuredAccount) {
    this.loadingNeptune = true;
    this.sub.add(
      this.digitalCarrierQuoteService
        .passiveQuote(this.digitalCarrierQuoteService.parseAccountForQuoteSubmit(model))
        .subscribe(
          (response) => {
            if (
              model.id === this.model.id &&
              (isQuotedQuoteResponse(response) || isReferralResponse(response))
            ) {
              this.neptuneFloodQuote = response;
            }
            this.loadingNeptune = false;
          },
          (err) => {
            this.loadingNeptune = false;
            this.sentryService.notify(
              'Neptune Flood: Error submitting Neptune Flood passive quote',
              {
                severity: 'error',
                metaData: {
                  insuredAccountId: model.id,
                  errorMessage: err.message,
                },
              }
            );
          }
        )
    );
  }

  // Not tied to specific card
  onCompleteNaics = (eligibilities: ProductEligibility) => {
    this.isBopEligible = eligibilities.isBopEligible;
    this.isAttuneWcEligible = eligibilities.isAttuneWcEligible;
    this.isEmployersWorkersCompEligible = eligibilities.isEmployersWcEligible;
    // Using the same NAICS eligibility for v4 Hiscox products
    this.isGlV4Eligible = eligibilities.isGlEligible;
    this.isPlV4Eligible = eligibilities.isPlEligible;
    this.eligibilities = eligibilities;
    this.loading = false;
  };

  generateBopQuoteTableData() {
    const bopDraftQuotes = this.bopDraftQuotes
      .filter((draft) => draft.product === 'bop')
      .map((draft) => {
        const formData = draft.formData as DeepPartial<BopQuoteFormValue>;
        return {
          routerLink: ['bop', 'drafts', draft.id],
          hasExcessPolicy: false,
          quoteNumber: null,
          quoteName: null,
          lastUpdated: draft.updatedAt,
          status: 'Draft',
          cost: null,
          bopVersion: formData.policyInfo ? formData.policyInfo.bopVersion : 1,
          AutoBindRollOver_ATTN: false,
          meToo: formData?.policyInfo?.meToo || false,
        };
      });
    const bopQuotes = this.model.bopQuotes.map((quote) => ({
      routerLink: this.isQuoteLinkable(quote) ? ['bop', 'policies', quote.id] : null,
      hasExcessPolicy: quote.hasExcessPolicy,
      quoteNumber: quote.id,
      quoteName: this.quoteNames[quote.id] || null,
      lastUpdated: quote.timeLastUpdated,
      status: this.status(quote.status),
      cost: quote.totalCost,
      uwCompanyCode: quote.uwCompanyCode,
      AutoBindRollOver_ATTN: quote.AutoBindRollOver_ATTN,
    }));

    const oldBopQuotes = bopQuotes.filter((quote) => isBopV1(quote.uwCompanyCode));
    const bopPlusQuotes = bopQuotes.filter((quote) => isBopV2orV3(quote.uwCompanyCode));

    const oldDraftQuotes = bopDraftQuotes.filter((quote) => {
      // Once the account's state has been rolled over to BopV2, we no longer want to show BopV1 drafts
      return (
        quote.bopVersion === 1 &&
        !shouldGetBopV2(this.model.state) &&
        !shouldGetMeToo(this.model.state) &&
        !quote.meToo
      );
    });
    const newDraftQuotes = bopDraftQuotes.filter((quote) => {
      return quote.bopVersion === 2 || quote.meToo;
    });

    this.bopPlusQuoteTableData = [...newDraftQuotes, ...bopPlusQuotes].sort((a, b) =>
      a.lastUpdated < b.lastUpdated ? 1 : -1
    );

    this.bopQuoteTableData = [...oldDraftQuotes, ...oldBopQuotes].sort((a, b) =>
      a.lastUpdated < b.lastUpdated ? 1 : -1
    );
  }

  autobindDisabledForAllQuotes() {
    return (
      !!this.listInForceBopPolicies().length &&
      !this.listInForceBopPlus().length &&
      !this.bopPlusQuoteTableData.some((quote) => quote.AutoBindRollOver_ATTN)
    );
  }

  insuredAccountHasActivePolicies() {
    return !this.loading && this.model.policiesWithTerms && this.model.policiesWithTerms.length > 0;
  }

  insuredAccountStartedLob(productName: string): boolean {
    if (this.model.id === '') {
      return false;
    }

    const hasProductPolicy = some(this.model.policiesWithTerms, (item) => {
      if (item.productName === null) {
        return productName === 'Habitational';
      }
      return item.productName.includes(productName);
    });

    return (
      hasProductPolicy ||
      some(this.model.bopQuotes, (item) => {
        return (
          item.productName !== null &&
          item.productName.includes(productName) &&
          ['quoted', 'quoting', 'draft', 'expired'].includes(item.status.toLowerCase())
        );
      })
    );
  }

  status(quoteStatus: string) {
    // NOTE: Gw does not currently distinguish between in-progress quotes, and quotes stuck in an indefinite draft state due to external service API errors.
    // All of these will return a status of `Draft`.
    if (quoteStatus === 'Draft') {
      return 'Declined';
    } else if (quoteStatus === 'NotTaken') {
      return 'Not-taken';
    }

    return quoteStatus;
  }

  isStateWithEmployers(state: string) {
    return !RESTRICTED_EMPLOYERS_STATES.includes(state);
  }

  isQuoteLinkable(quote: QuoteSummary) {
    return (
      quote.status.toLowerCase() === 'quoted' ||
      quote.status.toLowerCase() === 'expired' ||
      quote.status.toLowerCase() === 'draft'
    );
  }

  displayRestrictedWcNote() {
    if (this.isSampleAccount) {
      this.informService.infoToast(SAMPLE_NOT_AVAILABLE_MESSAGE);
      return;
    }
  }

  displayRestrictedNote() {
    if (this.isSampleAccount) {
      this.informService.infoToast(SAMPLE_NOT_AVAILABLE_MESSAGE);
    }
  }

  selectQuote(quoteRoute: string[]) {
    if (quoteRoute) {
      this.router.navigate([quoteRoute.join('/')], { relativeTo: this.route });
    }
  }

  hasDeclineReasons() {
    return !isEmpty(this.wcQuote.declineReasons);
  }

  hasGlV4DeclineReasons() {
    return !isEmpty(this.glV4Quote.declineReasons);
  }

  hasPlV4DeclineReasons() {
    return !isEmpty(this.plV4Quote.declineReasons);
  }

  updateQuoteName(quoteNumber: string, newName: string) {
    // Preemptively set the new name, so that there's immediate visual feedback
    this.quoteNames[quoteNumber] = newName;
    this.generateBopQuoteTableData();
    // Refresh the quote name data for the entire account
    this.sub.add(
      this.bopQuoteService.getNames(this.accountId).subscribe((names: QSQuoteNameListResponse) => {
        this.quoteNames = names.quoteNames;
        this.generateBopQuoteTableData();
      })
    );
  }

  hasRollover() {
    const hasNonRenewingV1 = this.model.bopV1Policies.some((quote) =>
      quote.terms.some((term) => term.status === 'Non-renewing' || term.status === 'Non-renewed')
    );
    return (
      hasNonRenewingV1 &&
      (this.bopPlusQuoteTableData.length >= 1 || this.model.bopV2Policies.length >= 1)
    );
  }

  public quoteStatusDisplayName(status: string): string {
    return status.replace('-', ' ');
  }

  isHiscoxRolledOver() {
    return this.digitalProductsRolledOver.includes('hiscox');
  }

  // Frontend helpers
  formatCost(quote: QuoteListItem) {
    if (quote.status === 'Declined' || quote.status === 'Expired') {
      return 'N/A';
    }

    const cost = quote.cost;
    if (cost) {
      return this.currencyPipe.transform(cost, 'USD', 'symbol', '1.2-2');
    }
    return 'N/A';
  }

  getBindFormQueryParams(insuredAccount: InsuredAccount, quote: QuoteSummary) {
    return {
      effectiveDate: quote.policyEffectiveDate.format(US_DATE_MASK),
      accountNumber: insuredAccount.id,
      quoteNumber: quote.id,
    };
  }

  isPolicyUpForRenewal(quote: HiscoxPricedQuote) {
    const effectiveDate = moment.utc(quote.effectiveDate);
    const expirationDate = moment(effectiveDate).add(1, 'year');
    const today = moment.utc();
    const diffDays = expirationDate.diff(today, 'days');

    return diffDays <= DAYS_UNTIL_HISCOX_AUTO_RENEW;
  }

  showNeptuneFloodQuote() {
    return (
      !this.excludeNeptune &&
      !this.loading &&
      !this.nonBindingRole &&
      this.neptuneFloodQuote &&
      this.neptuneFloodQuote.details &&
      'link' in this.neptuneFloodQuote.details &&
      this.isNeptuneFloodAvailable
    );
  }

  private setAvailabilityFlag(product: ProductCombination, flag: boolean) {
    switch (product) {
      case this.glV4Product:
        this.isGlV4Available = flag;
        break;
      case this.plV4Product:
        this.isPlV4Available = flag;
        break;
      case this.employersWcProduct:
        this.isEmployersWcAvailable = flag;
        break;
      case this.neptuneFloodProduct:
        this.isNeptuneFloodAvailable = flag;
    }
  }

  private displayableDigitalProducts() {
    return DIGITAL_PRODUCTS.filter(({ pasSource, showOnDifferentProductCard }) => {
      if (pasSource === 'liberty_mutual') {
        return false;
      }
      return !showOnDifferentProductCard && this.digitalProductsRolledOver.includes(pasSource);
    });
  }

  showBOPPrefillDeclineReasons() {
    // Once a broker has any BOP quotes we no longer want to show prefill decline reasons.
    if (
      this.insuredAccountHasBopPlusQuotesOrDrafts() ||
      this.insuredAccountHasBopV1QuotesOrDrafts()
    ) {
      return false;
    }
    return this.bopPrefillDeclineReasons.length > 0;
  }

  isProductAvailable({ pasSource, product }: ProductCombination | AttuneProduct) {
    const productAvailability = _.find(this.productAvailabilities, {
      pasSource,
      product,
    }) as ProductAvailability;

    if (!productAvailability) {
      return false;
    }

    if (productAvailability.classCodeSelection === 'ALL') {
      return true;
    }

    return (
      productAvailability.classCodeSelection === 'PARTIAL' &&
      this.model.naicsCode &&
      _.includes(productAvailability.classCodes.naics, this.model.naicsCode.code)
    );
  }

  isProductCrossSell(productCombination: ProductCombination | AttuneBOPProduct) {
    const accountSummary = this.accountSummaryView();
    if (accountSummary.status !== 'success') {
      return true;
    }

    const hasPolicy = some(
      accountSummary.policyTerms,
      (policyTerm: DigitalCarrierPolicyDetailsWithLinks) =>
        policyTerm.product === productCombination.product &&
        policyTerm.pasSource === productCombination.pasSource
    );
    const hasQuote = this.productHasQuotes(productCombination);
    const hasBundledQuote = some(accountSummary.quoteBundles, (bundle: FrontendQuoteBundle) =>
      some(
        bundle.quotes,
        (quote: FrontendQuoteWithLinks | AttuneBopFrontendQuoteWithLinks) =>
          quote.product === productCombination.product &&
          quote.pasSource === productCombination.pasSource
      )
    );

    return !(hasPolicy || hasQuote || hasBundledQuote);
  }

  productHasEditableQuotes(productCombination: ProductCombinationForAvailability) {
    if (
      productCombination.pasSource !== 'attune_gw' &&
      !carrierAllowsEdit(productCombination.pasSource)
    ) {
      return false;
    }
    const accountSummary = this.accountSummaryView();
    if (accountSummary.status !== 'success') {
      return false;
    }
    return some(
      accountSummary.quotes,
      (quote: DigitalCarrierPolicyDetailsWithLinks) =>
        ['draft', 'quoted'].includes(quote.status) &&
        quote.product === productCombination.product &&
        quote.pasSource === productCombination.pasSource
    );
  }

  productHasQuotes(productCombination: ProductCombinationForAvailability) {
    const accountSummary = this.accountSummaryView();
    if (accountSummary.status !== 'success') {
      return false;
    }
    return some(
      accountSummary.quotes,
      (quote: DigitalCarrierPolicyDetailsWithLinks) =>
        quote.product === productCombination.product &&
        quote.pasSource === productCombination.pasSource
    );
  }

  getAttuneWcEligibilityDeclinesOnRouteChanges() {
    this.sub.add(
      this.route.params
        .pipe(
          switchMap((params) => {
            this.attuneWcEligibilityDeclineReasons = [];
            return this.attuneWcEligibilityService.getEligibilityDeclineReasons(params.accountId);
          })
        )
        .subscribe((response) => {
          this.attuneWcEligibilityDeclineReasons = response.declineReasons;
        })
    );
  }

  showAttuneWcDeclineReasons() {
    if (
      this.model.attuneWcQuotes.length > 0 ||
      this.model.attuneWcPolicies.length > 0 ||
      this.attuneWcDraftQuotes.length > 0
    ) {
      return false;
    }
    return this.attuneWcEligibilityDeclineReasons.length > 0;
  }

  showAttuneWcProduct() {
    const attuneWCEligibleOrEmployersIneligible =
      this.isAttuneWcEligible ||
      !this.isEmployersWorkersCompEligible ||
      !this.isStateWithEmployers(this.model.state) ||
      !this.isProductAvailable({
        pasSource: 'employers',
        product: 'wc',
      });
    // Conditions to show Attune WC
    // 1. Attune WC enabled
    // 2. Attune WC is eligible OR employers product is ineligible/unavailable.
    // 3. Location state is in the list of enabled states for the broker
    const state = this.model.state;
    let inAllowedAttuneWcState = true;
    if (state) {
      inAllowedAttuneWcState = this.attuneWcEnabledStates.includes(state);
    }
    return (
      this.isEverpeakEnabled && attuneWCEligibleOrEmployersIneligible && inAllowedAttuneWcState
    );
  }

  showEmployersProduct() {
    // If this is set to true, it means the account has a Attune WC quote already.
    // In that case, we want to present the broker with alternative options.
    if (this.showAlternativeCarriersOnly || this.attuneWcEligibilityDeclineReasons.length > 0) {
      return true;
    }

    // If there is an existing Employers WC quote, allow the product to be shown.
    if (this.hasWcQuote) {
      return true;
    }
    // We do not want to show the employers product
    // if we meet the conditions to show the Attune WC product
    // We want their first quote for WC to be with Attune WC if it is eligible.
    // OR if there is already an in-force Attune wc policy.
    // NOTE: Existing employers quotes + policies will still show.
    return !this.showAttuneWcProduct() && !this.insuredAccountHasAttuneWcInForce();
  }

  getPreferences(naicsHash: string, state: string) {
    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.BOP_CLASS_PREFERENCE)
        .pipe(
          filter((isEnabled) => {
            return !!isEnabled;
          }),
          switchMap(() => this.naicsService.getAccreditedBopNaicsMapping(naicsHash)),
          switchMap((industry) => {
            if (industry.length === 1) {
              return this.preferenceService.getPreference('bop', industry[0].id, state);
            } else {
              return observableOf(null);
            }
          })
        )
        .subscribe((preferenceResponse) => {
          this.bopPreferenceLevel = preferenceResponse?.preferenceLevel ?? null;
        })
    );

    this.sub.add(
      this.naicsService
        .getNcciWcNaicsMapping(naicsHash)
        .pipe(
          switchMap((industry) => {
            if (industry.length >= 1) {
              const codes = _.map(industry, 'id');
              return this.preferenceService.getPreferenceForMultipleCodes('wc', codes, state);
            } else {
              return observableOf(null);
            }
          })
        )
        .subscribe((preferenceResponse) => {
          this.wcPreferenceLevel = preferenceResponse?.preferenceLevel ?? null;
        })
    );
  }

  handleNavigate($event: { type: 'new' | 'edit' }, productConfig: DigitalCarrierProduct) {
    if ($event.type === 'new') {
      this.router.navigate(['/accounts', this.accountId, productConfig.pathForUrl, 'new']);
    }
  }
}
