import { Injectable } from '@angular/core';
import { SentryService } from '../../core/services/sentry.service';
import { CurrentUserService } from '../../core/services/current-user.service';
import { AmplitudeService } from '../../core/services/amplitude.service';
import { LocalStorageService } from '../../core/services/local-storage.service';

import { environment } from 'environments/environment';

export const enum INFORMATIONAL_NAMES {
  // The "NEW" tag on the Endorse button
  NEW_ENDORSE_BUTTON = 'NEW_ENDORSE_BUTTON',
  // The "Check out rewards" educational tip on the left nav
  REWARDS_NAV_CTA = 'REWARDS_NAV_CTA',
  // The "Set your insureds up for success.." tip on policy details pane
  INSURED_BILLING_GUIDE = 'INSURED_BILLING_GUIDE',
  // The blinking indicator on the Effective Date info tooltip, explaining that eff. date affects rating
  EFFECTIVE_DATE_RATING_WARNING = 'EFFECTIVE_DATE_RATING_WARNING',
  // The autobind explanation pane on the rollover summary page
  AUTOBIND_ROLLOVER_EXPLANATION = 'AUTOBIND_ROLLOVER_EXPLANATION',
  // The marketing modal for new help center
  NEW_HELP_CENTER = 'NEW_HELP_CENTER',
  // Growth experiment module for estimating potential premium savings
  PRICE_REDUCTION_ESTIMATE = 'PRICE_REDUCTION_ESTIMATE',
  // Information video modal for first time LRO building step.
  LRO_STEP_VIDEO = 'LRO_STEP_VIDEO',
}

// Constants for the BOP Bind flow--see methods below
export const enum BOP_BIND_INFORMATIONAL {
  BIND_VIDEO_MODAL = 'BIND_VIDEO_MODAL',
  BIND_EMAIL_INPUT_TIP = 'BIND_EMAIL_INPUT_TIP',
  ENDORSE_BUTTON_TIP = 'ENDORSE_BUTTON_TIP',
  BILLING_CARD_TIP = 'BILLING_CARD_TIP',
}

const BOP_BIND_INFORMATIONAL_ORDER = [
  BOP_BIND_INFORMATIONAL.BIND_VIDEO_MODAL,
  BOP_BIND_INFORMATIONAL.BIND_EMAIL_INPUT_TIP,
  BOP_BIND_INFORMATIONAL.ENDORSE_BUTTON_TIP,
  BOP_BIND_INFORMATIONAL.BILLING_CARD_TIP,
];

const BOP_BIND_INFORMATIONAL_FIELD = 'BOP_BIND_SEQUENCE';
const INCLUDE_EXPIRED_CURRENT_USER = true;

@Injectable()
export class NewInformationalService {
  constructor(
    private amplitudeService: AmplitudeService,
    private sentryService: SentryService,
    private currentUserService: CurrentUserService,
    private localStorageService: LocalStorageService
  ) {}

  // This service encapsulates using localstorage to determine whether
  // a user has seen a particular informational item--for example, a "NEW" tag
  // or an educational modal.
  // Before showing the item, use getValue to see if the user has already
  // interacted with the element. When the user interacts with the element,
  // use incrementValue so that they will not be shown the item again.

  // The naming convention is login_fieldname
  // This means that if you want to change a field you can do so in the console, e.g.
  //      this.localStorageService.setString('ebozeman@attuneinsurance.com_NEW_ACTIVITY_TAB', '0')

  getValue(fieldName: INFORMATIONAL_NAMES) {
    try {
      const key = this.getLocalStorageKey(fieldName);
      return this.localStorageService.getInteger(key) || 0;
    } catch (e) {
      this.sentryService.notify('Error reading from local storage', {
        severity: 'error',
        metaData: {
          fieldName: fieldName,
          errorMessage: e.message,
        },
      });
    }
    // In the case of errors, return the default value (zero).
    return 0;
  }

  incrementValue(fieldName: INFORMATIONAL_NAMES) {
    const currentValue = this.getValue(fieldName);
    const key = this.getLocalStorageKey(fieldName);
    try {
      this.localStorageService.setNumber(key, currentValue + 1);
      if (fieldName === INFORMATIONAL_NAMES.REWARDS_NAV_CTA) {
        this.amplitudeService.track({
          eventName: 'rewards_checklist_educationalTip_seen',
          detail: 'rewards_checklist',
        });
      }
    } catch (e) {
      this.sentryService.notify('Error writing to local storage', {
        severity: 'error',
        metaData: {
          fieldName: key,
          errorMessage: e.message,
        },
      });
    }
  }

  getLocalStorageKey(fieldName: INFORMATIONAL_NAMES) {
    const user = this.currentUserService.getCurrentUser(INCLUDE_EXPIRED_CURRENT_USER);
    if (user && user.username) {
      return `${user.username}_${fieldName}`;
    } else {
      this.sentryService.notify('Attempted to get local storage, but there was no current user', {
        severity: 'error',
      });
      return fieldName;
    }
  }

  // The methods below relate to the BOP Bind educational displays. These educational items
  // are in *sequence* (one only appears after the other is completed). These methods
  // reflect the user's position in the sequence.
  // Note that the current value reflects the *last informational shown*, meaning that
  // before any item at all has been shown, it will be blank.
  // Note that the sequence is not stored as a numeric value--this was a conscious decision,
  // allowing for flexibility in case new items are added or the ordering is changed.
  getUserBopBindState() {
    try {
      const user = this.currentUserService.getCurrentUser(INCLUDE_EXPIRED_CURRENT_USER);
      const key = `${user ? user.username : ''}_${BOP_BIND_INFORMATIONAL_FIELD}`;
      return this.localStorageService.getString(key) || '';
    } catch (e) {
      this.sentryService.notify('Error reading user BOP bind state', {
        severity: 'error',
        metaData: {
          fieldName: BOP_BIND_INFORMATIONAL_FIELD,
          errorMessage: e.message,
        },
      });
    }
  }

  // Returns true if the user's current BOP bind state directly precedes the expected state
  isUserAtBopBindState(expectedState: BOP_BIND_INFORMATIONAL): boolean {
    if (!environment.showBopBindEducational) {
      return false;
    }

    const currentState = this.getUserBopBindState();

    let currentIndex = -1;
    if (currentState) {
      currentIndex = BOP_BIND_INFORMATIONAL_ORDER.findIndex((item) => item === currentState);
    }
    const expectedIndex = BOP_BIND_INFORMATIONAL_ORDER.findIndex((item) => item === expectedState);

    if (expectedIndex === -1) {
      this.sentryService.notify(
        'Tried to check user BOP bind state, but expected state was not in the sequence',
        {
          severity: 'error',
          metaData: {
            expectedState,
          },
        }
      );
    }

    return expectedIndex === currentIndex + 1;
  }

  // Move forward to the next state in the BOP bind flow, if the next state is equal to the passed state
  // Using this method ensures that the sequence only goes linearly forward, with no items skipped
  completeBopBindState(completedState: BOP_BIND_INFORMATIONAL) {
    if (!environment.showBopBindEducational) {
      return;
    }

    const currentState = this.getUserBopBindState();
    const currentIndex = BOP_BIND_INFORMATIONAL_ORDER.findIndex((item) => item === currentState);
    const nextState = BOP_BIND_INFORMATIONAL_ORDER[currentIndex + 1];
    if (completedState === nextState) {
      this.setUserBopBindState(nextState);
    }
  }

  // Sets the user's BOP bind state to the given value,
  private setUserBopBindState(newState: BOP_BIND_INFORMATIONAL) {
    try {
      const user = this.currentUserService.getCurrentUser(INCLUDE_EXPIRED_CURRENT_USER);
      const key = `${user ? user.username : ''}_${BOP_BIND_INFORMATIONAL_FIELD}`;
      this.localStorageService.setString(key, newState);
    } catch (e) {
      this.sentryService.notify('Error setting user BOP bind state', {
        severity: 'error',
        metaData: {
          fieldName: BOP_BIND_INFORMATIONAL_FIELD,
          errorMessage: e.message,
        },
      });
    }
  }
}
