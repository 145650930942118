import { catchError, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { ALERT_STATUS_S3_URI } from 'app/constants';

import { SentryService } from 'app/core/services/sentry.service';
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';
@Injectable()
export class AlertService {
  constructor(
    private http: HttpClient,
    private sentryService: SentryService,
    private route: ActivatedRoute
  ) {}

  public response: AlertResponse | null = null;
  public shouldDisplayAlert = false;
  public clickedClose = false;
  public isMaintenanceMode: BehaviorSubject<boolean> = new BehaviorSubject(false);
  previousMessage: string;
  public bannerPreviewMode = false;
  public bannerPreviewMessage: string = '';

  public getMessage(): string {
    // This allows us to create a preview link from the banner internal tool.
    if (this.bannerPreviewMode) {
      return this.bannerPreviewMessage;
    }
    return this.response !== null ? this.response.message : '';
  }

  public fetchAlert(): Observable<AlertResponse | null> {
    // Check if banner preview mode should be enabled in staging environment.
    this.checkAndSetBannerPreviewMode();
    const responseObs: Observable<AlertResponse | null> = this.http
      .get<AlertResponse | null>(ALERT_STATUS_S3_URI, {
        headers: new HttpHeaders({
          'Cache-Control': 'no-store',
        }),
      })
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to fetch alert.', {
            severity: 'info',
            metaData: {
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableOf(null);
        })
      );

    responseObs.pipe(first()).subscribe((response: AlertResponse | null) => {
      this.response = response;
      if (response) {
        if (response.maintenance && response.maintenance === 'enabled') {
          this.isMaintenanceMode.next(true);
        } else {
          this.isMaintenanceMode.next(false);
        }

        if (
          response.message &&
          response.message.includes('update') &&
          response.message.includes('refresh')
        ) {
          // If the user has already refreshed and has the versionCheck code, do not show them the banner again.
          return;
        }

        if (response.message !== this.previousMessage) {
          this.previousMessage = response.message;
          this.clickedClose = false;
          this.showAlert();
        } else if (this.clickedClose) {
          this.hideAlert();
        }
      } else if (!this.bannerPreviewMode) {
        // Only hide alert if we're not in preview mode
        this.hideAlert();
      }
    });

    return responseObs;
  }

  public showAlert(): void {
    if (this.response || this.bannerPreviewMode) {
      this.shouldDisplayAlert = true;
      const bodyEl: Element | null = document.querySelector('body');
      if (bodyEl) {
        bodyEl.classList.add('body__alert-enabled');
      }
    }
  }

  public hideAlert(): void {
    this.shouldDisplayAlert = false;
    const bodyEl: Element | null = document.querySelector('body');
    if (bodyEl) {
      bodyEl.classList.remove('body__alert-enabled');
    }
  }

  public handleClickedClose(): void {
    this.clickedClose = true;
    this.hideAlert();
  }

  public shouldShowBanner() {
    return this.shouldDisplayAlert || this.bannerPreviewMode;
  }

  private checkAndSetBannerPreviewMode() {
    // Only allow preview banner to show in staging environment
    if (this.getEnvironmentStage() !== 'staging') {
      return;
    }
    // Get banner-text from query params
    const params = this.route.snapshot.queryParams;
    let bannerText = params['banner-text'];

    // Check if banner-text is nested in redirect parameter
    if (!bannerText && params['redirect']) {
      const redirectUrl = params['redirect'];
      const queryString = redirectUrl.includes('?') ? redirectUrl.split('?')[1] : '';

      if (queryString) {
        const searchParams = new URLSearchParams(queryString);
        bannerText = searchParams.get('banner-text');
      }
    }

    if (bannerText) {
      this.bannerPreviewMode = true;
      this.bannerPreviewMessage = bannerText;
      this.showAlert();
    }
  }

  public getEnvironmentStage() {
    return environment.stage;
  }
}
