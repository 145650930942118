<div class="policies-overview-main-section">
  <div *ngIf="dialogHeader && dialogBody" class="policies-overview-dialog">
    <app-dialog-box
      class="dialog-text__full-width dialog-text__no-margin"
      type="primary"
      [header]="dialogHeader"
    >
      <p [innerHTML]="dialogBody"></p>
    </app-dialog-box>
  </div>
  <div class="welcome-back-header">
    <h1>Welcome back</h1>
    <a
      class="button button__small button__primary button__no-margin"
      [routerLink]="['/accounts/new']"
      >Start a new quote</a
    >
  </div>

  <ng-container *ngIf="showWcPromo && !isAdpWcUser">
    <app-activity-incentive-wc-hot-classes></app-activity-incentive-wc-hot-classes>
  </ng-container>
  <app-activity-incentive-streaks></app-activity-incentive-streaks>
  <app-activity-incentive-cyber-binds
    *ngIf="cyberRewardsConfig"
    [config]="cyberRewardsConfig"
  ></app-activity-incentive-cyber-binds>

  <app-incentives-new-users-progress> </app-incentives-new-users-progress>

  <app-activity-book-mix-insights></app-activity-book-mix-insights>

  <app-activity-list-quotes dataType="QUOTE"> </app-activity-list-quotes>
  <app-activity-list-quotes dataType="BIND"> </app-activity-list-quotes>

  <app-activity-list-renewals [policyCount]="policyCount" [policyPageLength]="POLICY_PAGE_LENGTH">
  </app-activity-list-renewals>

  <app-activity-list-pending-cancellation
    [policyCount]="policyCount"
    [policyPageLength]="POLICY_PAGE_LENGTH"
  >
  </app-activity-list-pending-cancellation>

  <app-activity-list-cancellation
    [policyCount]="policyCount"
    [policyPageLength]="POLICY_PAGE_LENGTH"
  >
  </app-activity-list-cancellation>
</div>
<div class="policies-overview-info-sidebar">
  <div *ngIf="highlightedProduct" class="policies-overview-sidebar-content">
    <ng-container *ngIf="highlightedProduct === 'ATTUNE_WC'">
      <img src="assets/img/attune_wc_promo_banner.svg" alt="" />
      <h3 class="sidebar-unit-header">Introducing Attune's Workers' Compensation</h3>
      <ul class="bulleted-list bulleted-list__tight">
        <li>Get a quote in minutes, bind in seconds</li>
        <li>Over 380 straight through class codes</li>
        <li>AM Best A-rated for your financial protection</li>
      </ul>
      <a
        class="sidebar-button button button__small button__secondary button__no-margin"
        [routerLink]="['/accounts/new']"
        >Start a new quote</a
      >
    </ng-container>
    <ng-container *ngIf="highlightedProduct === 'COALITION_CYBER'">
      <img
        src="assets/img/attune_coalition_promo_banner.svg"
        alt="Active Cyber Insurance: Protection designed for cyber risk"
      />
      <h3 class="sidebar-unit-header">Quote standalone cyber now!</h3>
      <ul class="bulleted-list bulleted-list__tight">
        <li>More digital coverage for the dollar with Coalition</li>
        <li>Free active cyber risk monitoring with all policies</li>
        <li>Speed and ease of Attune with direct billing</li>
      </ul>
      <a
        class="sidebar-button button button__small button__secondary button__no-margin"
        href="https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/Coalition+Overview.pdf"
        target="_blank"
        >Learn more</a
      >
    </ng-container>
  </div>
  <app-activity-incentive-referrals></app-activity-incentive-referrals>
</div>
