<ng-container>
  <app-left-navigation></app-left-navigation>
  <router-outlet></router-outlet>

  <div class="appetite-main">
    <div class="appetite-container">
      <section>
        <header>
          <h1>Appetite Checker</h1>
        </header>
      </section>
      <div class="appetite-search">
        <div class="appetite-naics-column">
          <app-form-field-dropdown-search
            inputId="naicsCode"
            nameOfFormControl="naicsCode"
            labelText="Search by class code, NAICS code or descriptions"
            typeaheadPlaceholderText="Search descriptions"
            [formatter]="naicsFormatter"
            [queryMethod]="getNaicsCodes"
            [searchExpanded]="false"
            [targetFormControl]="form.get('naicsCode')"
          >
          </app-form-field-dropdown-search>
        </div>
        <div class="appetite-state-column">
          <app-form-field-select
            inputId="state"
            [form]="form"
            labelText="State"
            nameOfFormControl="state"
            placeholder="State"
            [availableOptions]="usStateService.getUsStateAbbreviations()"
          >
          </app-form-field-select>
        </div>
      </div>
      <div class="appetite-search-footer">
        <div>
          <span>
            Need classification help?
            <a
              id="live-chat"
              (click)="openChat()"
              href="javascript:void(0);"
              data-amplitude-input-name="appetite-search-live-chat"
            >
              Live chat us
            </a>
          </span>
        </div>
        <div>
          <button
            type="submit"
            [disabled]="!form.valid"
            (click)="submitForm()"
            class="button button__primary"
            data-amplitude-input-name="appetite-search"
          >
            <div class="button-loader" role="presentation" *ngIf="loading">
              <app-loading-spinner></app-loading-spinner>
            </div>
            Search
          </button>
        </div>
      </div>
      <app-appetite-search-results
        *ngIf="displayResults"
        [naicsCode]="form.get('naicsCode').value"
        [productAvailabities]="productAvailabities"
        [state]="form.get('state').value"
      ></app-appetite-search-results>
    </div>
  </div>
</ng-container>
