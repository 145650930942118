import { Component, Input } from '@angular/core';

// Constants
import { RISK_ID_LENGTH_BY_STATE } from 'app/workers-comp/attune/helpers/form-validations';

// Models
import { ExperienceModFormGroup } from 'app/workers-comp/attune/models/state-coverages-form.model';

// Services
import { UsStateService } from 'app/shared/services/us-state.service';

@Component({
  selector: 'app-attune-wc-experience-mod',
  templateUrl: './attune-wc-experience-mod.component.html',
})
export class AttuneWcExperienceModComponent {
  @Input() form: ExperienceModFormGroup;
  @Input() submitted: boolean;
  @Input() state: string;
  constructor(private usStateService: UsStateService) {}

  get fullStateName() {
    return this.usStateService.getUsFullStateNameByAbbreviation(this.state);
  }

  get riskIdValidationLength() {
    return RISK_ID_LENGTH_BY_STATE[this.state] || 9;
  }

  get riskIdPlaceholder() {
    return '0'.repeat(this.riskIdValidationLength);
  }
}
