import { Component, OnDestroy, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HiscoxQuoteService } from '../../../hiscox/services/hiscox-quote.service';
import { DraftQuote, DraftQuoteService } from '../../../../shared/services/draft-quote.service';
import { InformService } from '../../../../core/services/inform.service';
import { SentryService } from '../../../../core/services/sentry.service';
import { AttuneBopQuoteService } from '../../../attune-bop/services/attune-bop-quote.service';
import {
  InsuredAccountSummaryService,
  AccountSummaryView,
  DigitalCarrierPolicyDetailsWithLinks,
  FrontendQuoteWithLinks,
} from '../../services/insured-account-summary.service';
import { AmplitudeService } from '../../../../core/services/amplitude.service';
import { NaicsService } from '../../../../shared/services/naics.service';
import { DigitalCarrierQuoteService } from '../../../digital-carrier/services/digital-carrier-quote.service';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import { InsuredAccount, QuoteOverviewItem } from '../../models/insured-account.model';
import { switchMap, mergeMap, catchError, map } from 'rxjs/operators';
import { InsuredAccountService } from '../../services/insured-account.service';
import {
  WcBackendPricedQuote,
  WcPricedQuote,
  WcQuoteWithDocuments,
} from '../../../../workers-comp/employers/models/wc-priced-quote';
import {
  HiscoxBackendPricedQuote,
  HiscoxPricedQuote,
} from '../../../hiscox/models/hiscox-priced-quote';
import { HISCOX_PRODUCTS, HiscoxQuoteStatus } from '../../../hiscox/models/hiscox-types';
import {
  AttuneBopFrontendQuoteWithLinks,
  AttuneInsuranceProductBadge,
  FrontendQuoteBundle,
  InsuranceProductBadge,
  InsuranceProductIcon,
  ProductAvailability,
  ProductCombination,
} from '../../../digital-carrier/models/types';
import * as moment from 'moment';
import { BopQuoteFormValue } from '../../../attune-bop/models/bop-policy';
import { QuoteSummary } from '../../../../shared/models/quote-summary';
import { find, flatMap, flattenDeep, isEmpty, maxBy, sortBy } from 'lodash';
import {
  BOP_BIND_INFORMATIONAL,
  NewInformationalService,
} from '../../../../shared/services/new-informational.service';
import { NON_BINDING_ROLES, UserService } from '../../../../core/services/user.service';
import { InvoicesListService } from '../../../invoices/services/invoices-list.service';
import {
  BOOLEAN_FLAG_NAMES,
  FeatureFlagService,
} from '../../../../core/services/feature-flag.service';
import * as _ from 'lodash';
import { ProducerDetailsResponse } from '../../../../bop/guidewire/typings';
import { WcQuoteService } from '../../../../workers-comp/employers/services/workers-comp-quote.service';
import {
  HIGH_HAZARD_GROUPS,
  STRONGLY_PREFERRED_HAZARD_GROUPS,
  PREFERRED_HAZARD_GROUPS,
} from 'app/workers-comp/attune/constants';
import { GWBindService } from '../../../../shared/services/gw-bind.service';
import {
  PreferenceLevel,
  PreferenceLevelResponse,
  PreferenceService,
} from 'app/shared/services/preference.service';
import {
  ATTUNE_BOP_UW_COMPANY_TO_PRODUCT_BADGE,
  ATTUNE_BOP_VERSION_TO_PRODUCT_BADGE,
  NON_PREFERRED_PREFERENCE_LEVELS,
  PREFERRED_PREFERENCE_LEVELS,
  STRONGLY_PREFERRED_PREFERENCE_LEVELS,
} from 'app/features/attune-bop/models/constants';

enum QuoteListItemFlags {
  QUOTE_EXPIRED = 'QUOTE_EXPIRED',
  SHOW_PENDING_REFER = 'SHOW_PENDING_REFER',
  QUOTE_REFERRING = 'QUOTE_REFERRING',
}

export enum PreferenceCategory {
  HOT_CLASS = 'Hot Class',
  STRONGLY_PREFERRED = 'Strongly Preferred',
  PREFERRED = 'Preferred',
  NON_PREFERRED = 'Non-preferred',
}

export interface QuoteListItem {
  id: string; // Typically uuid but bop/ others refer to `id`
  product: InsuranceProductBadge;
  pasSource: InsuranceProductIcon;
  quoteName: string;
  status: string;
  premium?: number | null;
  link: string | null;
  lastUpdated: moment.Moment;
  effectiveDate?: moment.Moment;
  flags?: QuoteListItemFlags[];
  renewalOf?: string; // For renewal quotes only
  quoteId?: string; // For policies only
  expirationDate?: moment.Moment;
  hazardGroup?: string;
  preferenceCategory?: PreferenceCategory | null;
  quoteType: string;
  isBindBlocked: boolean;
}

interface BundleQuoteListItem {
  product: 'bundle';
  status: string;
  premium?: number | null;
  link: string | null;
  lastUpdated: moment.Moment;
  effectiveDate?: moment.Moment;
  bundleItems: QuoteListItem[];
}

@Component({
  selector: 'app-insured-account-quotes.account-policies',
  templateUrl: './insured-account-quotes.component.html',
  providers: [CurrencyPipe],
})
export class InsuredAccountQuotesComponent implements OnInit, OnDestroy {
  accountId: string;
  model: InsuredAccount = new InsuredAccount();
  model$: Observable<InsuredAccount>;
  accountSummary: AccountSummaryView;
  accountSummary$: Subject<AccountSummaryView> = new Subject<AccountSummaryView>();
  nonBindingRole: boolean;
  quotes: (QuoteListItem | BundleQuoteListItem)[] = [];
  policies: QuoteListItem[] = [];
  quoteNames: Record<string, string> = {};
  productAvailabilities$: BehaviorSubject<ProductAvailability[]> = new BehaviorSubject<
    ProductAvailability[]
  >([]);
  productAvailabilities: ProductAvailability[];
  invoices: BackendListInvoice[];
  // Product combination for Hiscox GL V4
  hiscoxGlV4Product: ProductCombination = {
    pasSource: 'hiscox',
    product: 'gl',
  };
  hiscoxPlV4Product: ProductCombination = {
    pasSource: 'hiscox',
    product: 'pl',
  };
  loading = false;

  isNewPoliciesPaneEnabled = false;

  hasHiscoxGlV4ProductAvailability = false;
  hasHiscoxPlV4ProductAvailability = false;

  hasEverpeakWcEnabled = false;
  hasAlternativeCarrierCardEnabled = false;
  isAttuneWcBindBlockProducer = false;
  isAttuneBopBindBlockProducer = false;
  showAttuneBopPreference = false;
  showAttuneWcStronglyPreferred = false;
  preferenceCategory = PreferenceCategory;
  attuneWcEligibilityDeclineReasons: string[] = [];

  private sub: Subscription = new Subscription();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private insuredAccountService: InsuredAccountService,
    private wcQuoteService: WcQuoteService,
    private digitalCarrierQuoteService: DigitalCarrierQuoteService,
    private hiscoxQuoteService: HiscoxQuoteService,
    private naicsService: NaicsService,
    private draftQuoteService: DraftQuoteService,
    private informService: InformService,
    private amplitudeService: AmplitudeService,
    private sentryService: SentryService,
    private bopQuoteService: AttuneBopQuoteService,
    private newInformationalService: NewInformationalService,
    private userService: UserService,
    private billingListService: InvoicesListService,
    private bindService: GWBindService,
    private accountSummaryService: InsuredAccountSummaryService,
    private currencyPipe: CurrencyPipe,
    private featureFlagService: FeatureFlagService,
    private preferenceService: PreferenceService
  ) {}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.getProductAvailabilityOnRouteChange();
    this.getInsuredAccountOnRouteChanges();
    this.loadInvoices();
  }

  getProductAvailabilityOnRouteChange() {
    this.sub.add(
      this.naicsService.getProductAvailability().subscribe((resp) => {
        this.productAvailabilities = resp;
        this.productAvailabilities$.next(resp);
      })
    );

    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.EVERPEAK_WORKERS_COMP)
        .subscribe((enabled) => {
          this.hasEverpeakWcEnabled = enabled || false;
        })
    );
    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.ALTERNATIVE_CARRIER_CARD)
        .subscribe((enabled) => {
          this.hasAlternativeCarrierCardEnabled = enabled || false;
        })
    );
  }

  getInsuredAccountOnRouteChanges() {
    this.model$ = this.route.params.pipe(
      switchMap((params) => {
        if (this.accountId !== params['accountId']) {
          // We want to avoid a condition where we set loading to true after the
          // below switchMap concludes, as that would make the policies panel
          // stay in the loading state forever, so ensure we only set it once here
          this.accountId = params['accountId'];
          this.loading = true;
          this.quotes = [];
          this.policies = [];
        }

        return combineLatest([
          this.insuredAccountService.get(params['accountId']),
          this.featureFlagService.isEnabled(BOOLEAN_FLAG_NAMES.ATTUNE_WC_BIND_BLOCK),
          this.featureFlagService.isEnabled(BOOLEAN_FLAG_NAMES.ATTUNE_BOP_BIND_BLOCK),
          this.featureFlagService.isEnabled(BOOLEAN_FLAG_NAMES.ATTUNE_WC_NON_PREFERRED_NO_ACCESS),
          this.featureFlagService.isEnabled(BOOLEAN_FLAG_NAMES.BOP_CLASS_PREFERENCE),
          this.featureFlagService.isEnabled(BOOLEAN_FLAG_NAMES.SHOW_WC_STRONGLY_PREFERRED),
        ]);
      }),
      map(
        ([
          insuredAccount,
          isWcManualBindBlock,
          isBopManualBindBlock,
          isWcNonPreferredNoAccess,
          showBopPreference,
          showWcStronglyPreferred,
        ]) => {
          this.showAttuneBopPreference = showBopPreference || false;
          this.showAttuneWcStronglyPreferred = showWcStronglyPreferred || false;
          this.isAttuneBopBindBlockProducer = isBopManualBindBlock || false;
          this.isAttuneWcBindBlockProducer =
            isWcManualBindBlock || isWcNonPreferredNoAccess || false;
          this.model = insuredAccount;
          return insuredAccount;
        }
      )
    );

    this.model$
      .pipe(switchMap(() => this.accountSummaryService.getSummary(this.accountId)))
      .subscribe((accountSummary) => {
        this.accountSummary$.next(accountSummary);
        this.accountSummary = accountSummary;
      });

    this.sub.add(
      this.model$
        .pipe(
          switchMap(() => {
            const attuneProductDraftQuotes$ = this.attuneProductDraftQuotes();

            // bop quotes require the quote names to properly map their names.
            const bopQuotes$ = this.attuneBopQuotes$();
            const attuneWcQuotes$ = this.attuneWcQuotes$();
            const bopV1Policies$ = this.bopPolicies$({ bopVersion: 1 });
            const bopV2Policies$ = this.bopPolicies$({ bopVersion: 2 });
            const bopV3Policies$ = this.bopPolicies$({ bopVersion: 3 });
            const attuneWcPolicies$ = this.attuneWcPolicies$();

            // account summary mapping
            const accountSummaryQuotes$ = this.accountSummaryQuotes$();
            const accountSummaryPolicies$ = this.accountSummaryPolicies$();
            const accountSummaryBundles$ = this.accountSummaryBundles$();
            // end account summary

            // Hiscox
            const glv4Quote$: Observable<QuoteListItem | null> = this.model$.pipe(
              switchMap(() => this.hiscoxQuoteService.getQuote(HISCOX_PRODUCTS.gl, this.accountId)),
              map((glv4Quote) => {
                return this.mapHiscoxPricedQuoteToQuoteListItem(
                  <HiscoxBackendPricedQuote>glv4Quote,
                  'gl'
                );
              }),
              this.dataCatchError('hiscox gl v4', null)
            );
            const plv4Quote$: Observable<QuoteListItem | null> = this.model$.pipe(
              switchMap(() => this.hiscoxQuoteService.getQuote(HISCOX_PRODUCTS.pl, this.accountId)),
              map((plv4Quote) => {
                return this.mapHiscoxPricedQuoteToQuoteListItem(
                  <HiscoxBackendPricedQuote>plv4Quote,
                  'pl'
                );
              }),
              this.dataCatchError('hiscox pl v4', null)
            );
            // end Hiscox

            // Employers WC
            const wcQuote$: Observable<QuoteListItem | null> = this.model$.pipe(
              switchMap(() => this.wcQuoteService.getQuote(this.accountId)),
              map((wcQuoteWithDocuments) => {
                return this.mapWorkersCompToQuoteListItem(wcQuoteWithDocuments.quote);
              }),
              this.dataCatchError('employers wc', null)
            );
            // end Employers WC

            return combineLatest([
              attuneProductDraftQuotes$,
              bopQuotes$,
              attuneWcQuotes$,
              bopV2Policies$,
              bopV1Policies$,
              bopV3Policies$,
              attuneWcPolicies$,
              accountSummaryQuotes$,
              accountSummaryPolicies$,
              accountSummaryBundles$,
              glv4Quote$,
              plv4Quote$,
              wcQuote$,
              this.productAvailabilities$,
            ]);
          })
        )
        .subscribe(
          ([
            attuneProductDraftQuotes,
            bopQuotes,
            attuneWcQuotes,
            bopV2Policies,
            bopV1Policies,
            bopV3Policies,
            attuneWcPolicies,
            accountSummaryQuotes,
            accountSummaryPolicies,
            accountSummaryBundles,
            glV4QuoteResponse,
            plV4QuoteResponse,
            wcQuoteResponse,
            productAvailabilities,
          ]: [
            QuoteListItem[],
            QuoteListItem[],
            QuoteListItem[],
            QuoteListItem[],
            QuoteListItem[],
            QuoteListItem[],
            QuoteListItem[],
            QuoteListItem[],
            QuoteListItem[],
            BundleQuoteListItem[],
            QuoteListItem | null,
            QuoteListItem | null,
            QuoteListItem | null,
            ProductAvailability[]
          ]) => {
            this.loadHiscoxV4ProductAvailabilities(productAvailabilities);
            this.handleQuoteListItemOrganization([
              attuneProductDraftQuotes,
              bopQuotes,
              attuneWcQuotes,
              bopV2Policies,
              bopV1Policies,
              bopV3Policies,
              attuneWcPolicies,
              accountSummaryQuotes,
              accountSummaryPolicies,
              accountSummaryBundles,
              glV4QuoteResponse,
              plV4QuoteResponse,
              wcQuoteResponse,
            ]);
          }
        )
    );
    this.sub.add(
      this.userService
        .getUser()
        .pipe(
          switchMap((user) => {
            return this.insuredAccountService.getProducerDetails(user.producer);
          })
        )
        .subscribe((response: ProducerDetailsResponse) => {
          this.nonBindingRole = response.Roles.Entry.every((role) =>
            NON_BINDING_ROLES.includes(role)
          );
        })
    );
  }

  handleQuoteListItemOrganization([
    attuneProductDraftQuotes,
    bopQuotes,
    attuneWcQuotes,
    bopV2Policies,
    bopV1Policies,
    bopV3Policies,
    attuneWcPolicies,
    accountSummaryQuotes,
    accountSummaryPolicies,
    accountSummaryBundles,
    glV4Quote,
    plV4Quote,
    employersWcQuote,
  ]: [
    QuoteListItem[],
    QuoteListItem[],
    QuoteListItem[],
    QuoteListItem[],
    QuoteListItem[],
    QuoteListItem[],
    QuoteListItem[],
    QuoteListItem[],
    QuoteListItem[],
    BundleQuoteListItem[],
    QuoteListItem | null,
    QuoteListItem | null,
    QuoteListItem | null
  ]) {
    this.policies = [
      ...bopV2Policies,
      ...bopV1Policies,
      ...bopV3Policies,
      ...attuneWcPolicies,
      glV4Quote?.status === HiscoxQuoteStatus.BOUND ? glV4Quote : null,
      plV4Quote?.status === HiscoxQuoteStatus.BOUND ? plV4Quote : null,
      ...accountSummaryPolicies,
    ].filter((policy) => !!policy) as QuoteListItem[];

    const policiesWithoutEffectiveDate = this.policies.filter((policy) => !policy.effectiveDate);
    const policiesWithEffectiveDate = sortBy(
      this.policies.filter((policy) => !!policy.effectiveDate), // has effective date
      (policy) => policy.effectiveDate // order by effective date
    ).reverse(); // most recent first
    this.policies = [...policiesWithEffectiveDate, ...policiesWithoutEffectiveDate]; // policies without effective date goes last
    const activeOrScheduledProductsToExpirationDates: Partial<
      Record<InsuranceProductBadge, moment.Moment>
    > = {};
    this.policies
      .filter(
        (policy) => !['expired', 'cancelled', 'canceled'].includes(policy.status.toLowerCase())
      )
      .forEach((policy) => {
        if (
          !activeOrScheduledProductsToExpirationDates[policy.product] ||
          activeOrScheduledProductsToExpirationDates[policy.product]?.isBefore(
            policy.expirationDate
          )
        ) {
          activeOrScheduledProductsToExpirationDates[policy.product] = policy.expirationDate;
        }
      });
    const activeOrScheduledProducts = Object.keys(activeOrScheduledProductsToExpirationDates);
    const bopOrCyberPolicyExists =
      activeOrScheduledProducts.includes('bop') ||
      activeOrScheduledProducts.includes('bop-plus') ||
      activeOrScheduledProducts.includes('bop-fortegra') ||
      activeOrScheduledProducts.includes('cyber_admitted') ||
      activeOrScheduledProducts.includes('cyber_surplus');

    this.quotes = [
      ...bopQuotes,
      ...(this.hasEverpeakWcEnabled ? attuneWcQuotes : []),
      ...attuneProductDraftQuotes,
      glV4Quote,
      plV4Quote,
      employersWcQuote,
      ...accountSummaryQuotes,
      ...accountSummaryBundles,
    ].filter((quote) => !!quote) as (QuoteListItem | BundleQuoteListItem)[];

    const bundledQuoteNames = flatMap(accountSummaryBundles, (quote) => quote.bundleItems).map(
      (quote) => quote.quoteName
    );
    this.quotes = this.quotes.filter((quote) => {
      if (quote.product === 'bundle') {
        return !bopOrCyberPolicyExists;
      } else if (!bopOrCyberPolicyExists && bundledQuoteNames.includes(quote.quoteName)) {
        return false;
      } else if (this.isRenewalOfActivePolicy(quote)) {
        return true;
      } else {
        // If the product does not have an active policy show the quote
        // If the product does have an active policy and the effective date of the quote is after the expiration date of the policy then show the quote
        return (
          !activeOrScheduledProducts.includes(quote.product) ||
          (quote.effectiveDate &&
            activeOrScheduledProductsToExpirationDates[quote.product]?.isSameOrBefore(
              quote.effectiveDate,
              'day'
            ))
        );
      }
    });
    this.quotes = sortBy(this.quotes, (policy) => policy.lastUpdated).reverse();

    this.loading = false;
  }

  isRenewalOfActivePolicy(quote: QuoteListItem) {
    const isRenewal = !!quote.renewalOf;
    const isBound = ['bound', 'bound_with_subjectivity'].includes(quote.status);
    if (!isRenewal || isBound) {
      return false;
    }
    const policiesForProduct = this.policies.filter(({ product, pasSource, expirationDate }) => {
      return product === quote.product && pasSource === quote.pasSource && expirationDate;
    });

    const nonCancelledPolicies = policiesForProduct.filter(({ status }) =>
      ['in_force', 'scheduled', 'expired'].includes(status)
    );

    const mostRecentPolicy = maxBy(policiesForProduct, ({ expirationDate }) => {
      return expirationDate?.valueOf();
    });

    const mostRecentNonCancelledPolicy = maxBy(nonCancelledPolicies, ({ expirationDate }) => {
      return expirationDate?.valueOf();
    });

    if (!mostRecentPolicy || !mostRecentNonCancelledPolicy) {
      return false;
    }

    return (
      mostRecentNonCancelledPolicy.expirationDate?.isSameOrAfter(mostRecentPolicy.expirationDate) &&
      mostRecentNonCancelledPolicy.quoteId === quote.renewalOf
    );
  }

  mapQuoteBundles(quoteBundle: FrontendQuoteBundle): BundleQuoteListItem | null {
    const bopQuote = find(
      quoteBundle.quotes,
      (quote) => quote.pasSource === 'attune_gw' && quote.product === 'bop'
    );
    const cyberQuote = find(
      quoteBundle.quotes,
      (quote) =>
        quote.pasSource === 'coalition' &&
        ['cyber_admitted', 'cyber_surplus'].includes(quote.product)
    );
    if (!bopQuote || !cyberQuote) {
      return null;
    }
    const premium =
      bopQuote.premium && cyberQuote.premium
        ? parseFloat(bopQuote.premium as unknown as string) +
          parseFloat(cyberQuote.premium as unknown as string)
        : null;
    return {
      product: 'bundle',
      status: bopQuote.status,
      premium: premium,
      link: `/accounts/${this.accountId}/bundle/${quoteBundle.uuid}`,
      lastUpdated: moment(bopQuote.updatedAt),
      effectiveDate: moment(bopQuote.effectiveDate),
      bundleItems: [
        this.mapFrontendQuoteWithLinksToQuoteListItem(bopQuote),
        this.mapFrontendQuoteWithLinksToQuoteListItem(cyberQuote),
      ],
    };
  }

  mapWorkersCompToQuoteListItem(
    wcBackendPricedQuote: WcBackendPricedQuote | null
  ): QuoteListItem | null {
    if (!wcBackendPricedQuote) {
      return null;
    }
    const wcQuote = new WcPricedQuote(wcBackendPricedQuote);
    const preferenceCategory = this.determinePreferenceCategory(
      'wc',
      'employers',
      wcQuote.status,
      null,
      null
    );
    return {
      id: wcQuote.uuid,
      product: 'wc',
      pasSource: 'employers',
      quoteName: wcQuote.policyNumber,
      status: wcQuote.status,
      premium: wcQuote.premium,
      // TODO implement 'requote'
      link: wcQuote.isQuoted()
        ? `/accounts/${this.accountId}/workers-comp/quotes/${wcQuote.uuid}`
        : `/accounts/${this.accountId}/workers-comp/edit`,
      lastUpdated: wcQuote.createdAt,
      effectiveDate: wcQuote.effectiveDate,
      flags: wcQuote.justReferred ? [QuoteListItemFlags.SHOW_PENDING_REFER] : [],
      preferenceCategory,
      quoteType: this.getQuoteType('wc', 'employers', preferenceCategory),
      isBindBlocked: this.isQuoteBindBlocked(
        'wc',
        'employers',
        wcQuote.status,
        preferenceCategory,
        null
      ),
    };
  }

  mapHiscoxPricedQuoteToQuoteListItem(
    hiscoxBackendPricedQuote: HiscoxBackendPricedQuote,
    product: 'gl' | 'pl'
  ): QuoteListItem | null {
    if (isEmpty(hiscoxBackendPricedQuote)) {
      return null;
    }
    const hiscoxPricedQuote = new HiscoxPricedQuote(
      <HiscoxBackendPricedQuote>hiscoxBackendPricedQuote
    );

    // EOD needed, otherwise formatting this in current timezone puts date on previous day
    let flags;
    let preferenceCategory = null;
    if (hiscoxPricedQuote.effectiveDate) {
      const effectiveDateUTC = hiscoxPricedQuote.effectiveDate.endOf('day').format();
      const tz = moment.tz.guess();
      // Quote effective date is valid through end of day
      const effectiveDateLocal = moment.tz(effectiveDateUTC, tz).endOf('day');
      const currentDateTime = moment.tz(tz);
      preferenceCategory = this.determinePreferenceCategory(
        product,
        'hiscox',
        hiscoxPricedQuote.status,
        null,
        null
      );
      flags =
        hiscoxPricedQuote.isQuoted() && effectiveDateLocal.isBefore(currentDateTime)
          ? [QuoteListItemFlags.QUOTE_EXPIRED]
          : [];
    }
    return {
      id: hiscoxPricedQuote.uuid,
      product,
      pasSource: 'hiscox',
      quoteName: hiscoxPricedQuote.quoteId ? hiscoxPricedQuote.quoteId : '',
      status: hiscoxPricedQuote.status,
      premium: hiscoxPricedQuote.premium,
      link: `/accounts/${this.accountId}/${product}/details`,
      lastUpdated: hiscoxPricedQuote.createdAt,
      flags,
      effectiveDate: hiscoxPricedQuote.effectiveDate,
      preferenceCategory,
      quoteType: this.getQuoteType(product, 'hiscox', preferenceCategory),
      isBindBlocked: this.isQuoteBindBlocked(
        product,
        'hiscox',
        hiscoxPricedQuote.status,
        preferenceCategory,
        null
      ),
    };
  }

  mapAttuneQuoteSummaryToQuoteListItem(
    quote: QuoteSummary,
    line: AttuneInsuranceProductBadge,
    quoteDetails: QuoteDetails | null,
    quotePreference: PreferenceLevelResponse | null
  ): QuoteListItem {
    const status = this.status(quote.status);
    const preferenceLevel = quotePreference?.preferenceLevel || null;
    const preferenceCategory = this.determinePreferenceCategory(
      line,
      'attune_gw',
      status,
      preferenceLevel,
      quoteDetails
    );
    return {
      id: quote.id,
      product: line,
      pasSource: 'attune_gw',
      quoteName: this.quoteNames[quote.id] || quote.id,
      status,
      premium: quote.totalCost,
      link: this.getQuoteLink(quote, line),
      lastUpdated: moment(quote.timeLastUpdated),
      effectiveDate: moment(quote.policyEffectiveDate),
      hazardGroup: quoteDetails?.hazardGroup,
      preferenceCategory,
      quoteType: this.getQuoteType(line, 'attune_gw', preferenceCategory),
      isBindBlocked: this.isQuoteBindBlocked(
        line,
        'attune_gw',
        status,
        preferenceCategory,
        quoteDetails
      ),
    };
  }

  getQuoteLink(quote: QuoteSummary, line: AttuneInsuranceProductBadge): string | null {
    if (!this.isQuoteLinkable(quote)) {
      return null;
    }

    if (line === 'wc') {
      return `/accounts/${this.accountId}/attune/workers-comp/quotes/${quote.id}`;
    }
    return `/accounts/${this.accountId}/bop/policies/${quote.id}`;
  }

  mapQuoteDraftToQuoteListItem(draft: DraftQuote): QuoteListItem {
    const productUrlSegment = draft.product === 'bop' ? 'bop' : 'attune/workers-comp';
    return {
      id: draft.id,
      product: draft.product,
      pasSource: draft.pasSource,
      // Truncating this to avoid displaying the entire UUID which makes the UI look strange.
      quoteName: draft.id?.slice(0, 8),
      status: 'Draft',
      link: `/accounts/${this.accountId}/${productUrlSegment}/drafts/${draft.id}`,
      lastUpdated: draft.updatedAt,
      preferenceCategory: null,
      quoteType: this.getQuoteType('bop', 'attune_gw', null),
      isBindBlocked: false,
    };
  }

  mapQuoteOverviewItemToQuoteListItems(
    policy: QuoteOverviewItem,
    product: InsuranceProductBadge,
    pasSource: InsuranceProductIcon,
    policyPreference: PreferenceLevelResponse | null,
    policyPeriodDetails: QuoteDetails | null
  ): QuoteListItem[] {
    return policy.terms.map((term) => {
      const preferenceLevel = policyPreference?.preferenceLevel || null;
      const preferenceCategory = this.determinePreferenceCategory(
        product,
        pasSource,
        term.status,
        preferenceLevel,
        policyPeriodDetails
      );

      return {
        id: term.id,
        product: product,
        pasSource: pasSource,
        quoteName: term.id,
        status: term.status,
        premium: term.totalCost,
        link: `/accounts/${this.accountId}/terms/${term.policyNumber}/${term.termNumber}`,
        lastUpdated: moment(term.updatedAt),
        effectiveDate: moment(term.policyEffectiveDate),
        hazardGroup: policyPeriodDetails?.hazardGroup,
        preferenceCategory,
        quoteType: this.getQuoteType(product, pasSource, preferenceCategory),
        isBindBlocked: false,
      };
    });
  }

  mapFrontendQuoteWithLinksToQuoteListItem(
    quote: FrontendQuoteWithLinks | AttuneBopFrontendQuoteWithLinks
  ): QuoteListItem {
    const isRenewalDraft = !!quote.renewalDetails?.previousQuoteUuid && quote.status === 'draft';
    let premium;
    if (quote.premium && quote.surcharges) {
      const surcharges = _.sumBy(quote.surcharges, 'amount');
      premium = Number(quote.premium) + surcharges;
    } else {
      premium = quote.premium;
    }

    const preferenceCategory = this.determinePreferenceCategory(
      quote.product,
      quote.pasSource,
      quote.status,
      null,
      null
    );

    return {
      id: quote.uuid,
      product: quote.product,
      pasSource: quote.pasSource,
      quoteName: quote.displayId || quote.pasId,
      status: isRenewalDraft ? 'action required' : quote.status,
      premium: premium,
      link: quote.routerLink,
      lastUpdated: moment(quote.updatedAt),
      effectiveDate: moment(quote.effectiveDate),
      renewalOf: quote.renewalDetails?.previousQuoteUuid,
      preferenceCategory,
      quoteType: this.getQuoteType(quote.product, quote.pasSource, preferenceCategory),
      isBindBlocked: this.isQuoteBindBlocked(
        quote.product,
        quote.pasSource,
        quote.status,
        preferenceCategory,
        null
      ),
    };
  }

  determineStatus(policyTerm: DigitalCarrierPolicyDetailsWithLinks) {
    if (
      moment(policyTerm.effectiveDate).isSameOrBefore(moment(), 'day') &&
      moment(policyTerm.expirationDate).isAfter(moment(), 'day') &&
      policyTerm.status === 'scheduled'
    ) {
      return 'in_force';
    } else if (
      moment(policyTerm.effectiveDate).isAfter(moment(), 'day') &&
      policyTerm.status === 'in_force'
    ) {
      return 'scheduled';
    } else if (moment(policyTerm.expirationDate).isBefore(moment(), 'day')) {
      return 'expired';
    } else {
      return policyTerm.status;
    }
  }

  mapDigitalCarrierPolicyDetailsWithLinksToQuoteListItem(
    policyTerm: DigitalCarrierPolicyDetailsWithLinks
  ): QuoteListItem {
    return {
      id: policyTerm.uuid,
      product: policyTerm.product,
      pasSource: policyTerm.pasSource,
      quoteName: policyTerm.policyNumber || 'N/A',
      status: this.determineStatus(policyTerm),
      premium: policyTerm.netPremium,
      link: policyTerm.routerLink,
      lastUpdated: moment(policyTerm.updatedAt),
      effectiveDate: moment(policyTerm.effectiveDate),
      quoteId: policyTerm.quoteId,
      expirationDate: moment(policyTerm.expirationDate),
      preferenceCategory: null,
      quoteType: this.getQuoteType(policyTerm.product, policyTerm.pasSource, null),
      isBindBlocked: false,
    };
  }

  getRouterLinkForNewQuote() {
    return `/accounts/${this.accountId}/quotes/new`;
  }

  status(quoteStatus: string) {
    // NOTE: Gw does not currently distinguish between in-progress quotes, and quotes stuck in an indefinite draft state due to external service API errors.
    // All of these will return a status of `Draft`.
    if (quoteStatus === 'Draft') {
      return 'Declined';
    } else if (quoteStatus === 'NotTaken') {
      return 'Not-taken';
    }

    return quoteStatus;
  }

  isQuoteLinkable(quote: QuoteSummary) {
    return (
      quote.status.toLowerCase() === 'quoted' ||
      quote.status.toLowerCase() === 'expired' ||
      quote.status.toLowerCase() === 'draft'
    );
  }

  showOfacWarning() {
    return this.model.ofacAlertPresent;
  }

  mapQuoteStatusToStyle(status: string, quote?: QuoteListItem): string {
    status = status.toLowerCase();
    if (!!quote && quote.isBindBlocked) {
      return 'warning';
    }
    switch (status) {
      case 'declined':
      case 'canceled':
      case 'cancelled':
        return 'danger';
      case 'referred':
      case 'under_review':
      case 'expiring soon':
      case 'action required':
        return 'warning';
      case 'scheduled':
      case 'quoted':
      case 'bound':
      case 'bound_with_subjectivity':
        return 'success';
      case 'binding':
      case 'in_force':
      case 'in force':
        return 'main';
      case 'expired':
        return 'expired';
      case 'non-renewing':
      case 'non-renewed':
        return 'non-renewal';
      case 'not-taking':
      case 'not-taken':
        return 'not-taken';
      default:
        return 'neutral';
    }
  }

  mapQuoteStatus(status: string): string {
    return status.replace(/_/g, ' ');
  }

  navigateToDetails(quote: QuoteListItem) {
    if (quote.flags?.includes(QuoteListItemFlags.QUOTE_EXPIRED) && quote.product === 'gl') {
      this.informService.infoToast(
        'The effective date for this General Liability quote has expired. Please edit this quote and update the effective date in order to bind.'
      );
      return;
    } else if (quote.flags?.includes(QuoteListItemFlags.QUOTE_EXPIRED) && quote.product === 'pl') {
      this.informService.infoToast(
        'The effective date for this Professional Liability quote has expired. Please edit this quote and update the effective date in order to bind.'
      );
      return;
    }
    if (quote.link) {
      this.router.navigateByUrl(quote.link);
    }
  }

  loadInvoices() {
    this.sub.add(
      this.route.params
        .pipe(
          switchMap(() => {
            return this.userService.getUser();
          }),
          switchMap((user) => {
            return this.billingListService.getInvoiceList(
              user.producer,
              0,
              25,
              undefined,
              this.accountId,
              undefined
            );
          })
        )
        .subscribe((invoiceListOrError) => {
          if ((invoiceListOrError as HttpErrorResponse)?.error) {
            this.sentryService.notify(
              'Policies pane: Received an error fetching account invoices',
              {
                severity: 'error',
                metaData: (invoiceListOrError as HttpErrorResponse).error,
              }
            );
            return;
          }
          this.invoices = (invoiceListOrError as BackendInvoiceSummary).invoices;

          if (
            this.newInformationalService.isUserAtBopBindState(
              BOP_BIND_INFORMATIONAL.BILLING_CARD_TIP
            ) &&
            (this.loading || this.insuredAccountHasActivePolicies()) &&
            this.invoices.length === 0
          ) {
            // wait fifteen seconds and then refresh the data
            const FIFTEEN_SECONDS = 15 * 1000;
            setTimeout(() => this.loadInvoices(), FIFTEEN_SECONDS);
          }
        })
    );
  }

  loadHiscoxV4ProductAvailabilities(productAvailabilites: ProductAvailability[]) {
    const hiscoxV4GlProductAvailability = find(
      productAvailabilites,
      this.hiscoxGlV4Product
    ) as ProductAvailability;

    if (
      hiscoxV4GlProductAvailability?.classCodeSelection === 'ALL' ||
      hiscoxV4GlProductAvailability?.classCodeSelection === 'PARTIAL'
    ) {
      this.hasHiscoxGlV4ProductAvailability = true;
    }

    const hiscoxV4PlProductAvailability = find(
      productAvailabilites,
      this.hiscoxPlV4Product
    ) as ProductAvailability;

    if (
      hiscoxV4PlProductAvailability?.classCodeSelection === 'ALL' ||
      hiscoxV4PlProductAvailability?.classCodeSelection === 'PARTIAL'
    ) {
      this.hasHiscoxPlV4ProductAvailability = true;
    }
  }

  insuredAccountHasActivePolicies() {
    return !this.loading && this.model.policiesWithTerms?.length > 0;
  }
  // Billing Card
  shouldShowBillingSection() {
    return (
      (this.insuredAccountHasActivePolicies() && !!this.invoices) ||
      this.policies.some(({ pasSource }) => pasSource === 'coalition')
    );
  }

  formatPremium(quote: QuoteListItem) {
    if (quote.status === 'Declined' || quote.status === 'Expired') {
      return 'N/A';
    }

    const cost = quote.premium;
    if (cost) {
      return this.currencyPipe.transform(cost, 'USD', 'symbol', '1.2-2');
    }
    return 'N/A';
  }

  referWcQuote(quote: QuoteListItem) {
    quote.flags = [QuoteListItemFlags.QUOTE_REFERRING];
    const refAccountId = this.route.snapshot.params['accountId'];

    if (!quote.id) {
      return;
    }

    this.sub.add(
      this.wcQuoteService
        .referQuote(quote.id)
        .pipe(
          mergeMap((resp: any) => {
            this.wcQuoteService.cachebust();
            return this.wcQuoteService.getQuote(refAccountId);
          })
        )
        .subscribe((wcQuoteResponse: WcQuoteWithDocuments) => {
          // If you haven't moved between pages, render newly referred quote.
          if (refAccountId === this.route.snapshot.params['accountId']) {
            const wcQuoteListItem = this.mapWorkersCompToQuoteListItem(wcQuoteResponse.quote);
            if (wcQuoteListItem) {
              wcQuoteListItem.flags = [];
              const wcIndex = _.findIndex(
                this.quotes,
                (quoteListItem) => quoteListItem.product === 'wc'
              );
              this.quotes[wcIndex] = wcQuoteListItem;
            }
          }
        })
    );
  }

  attuneProductDraftQuotes(): Observable<QuoteListItem[]> {
    return this.draftQuoteService.get(this.accountId).pipe(
      map((draftQuotes) => {
        return draftQuotes
          .filter((draft) => {
            if (draft.product === 'bop') {
              const bopFormData = draft.formData as DeepPartial<BopQuoteFormValue>;
              return draft.pasSource === 'attune_gw' && bopFormData.policyInfo?.bopVersion !== null;
            }
            if (draft.product === 'wc') {
              return draft.pasSource === 'attune_gw' && this.hasEverpeakWcEnabled === true;
            }
            return false;
          })
          .map((quote) => this.mapQuoteDraftToQuoteListItem(quote));
      }),
      this.dataCatchError('draft quotes', [])
    );
  }

  attuneBopQuotes$(): Observable<QuoteListItem[]> {
    return this.model$.pipe(
      switchMap((model) => {
        const names = this.bopQuoteService.getNames(this.accountId);

        if (model.bopQuotes.length === 0) {
          return forkJoin({
            names,
            quotes: of([]),
          });
        }
        const quotes = forkJoin(
          model.bopQuotes.map((quote) => {
            return forkJoin({
              quotePreference: this.showAttuneBopPreference
                ? this.preferenceService.getPreferenceByJobNumber('bop', quote.id)
                : of(null),
              quoteSummary: of(quote),
            });
          })
        );

        return forkJoin({
          names,
          quotes,
        });
      }),
      map((quoteInformation) => {
        this.quoteNames = quoteInformation?.names.quoteNames || {};
        return quoteInformation.quotes.map((quote) => {
          return this.mapAttuneQuoteSummaryToQuoteListItem(
            quote.quoteSummary,
            ATTUNE_BOP_UW_COMPANY_TO_PRODUCT_BADGE[
              quote.quoteSummary.uwCompanyCode as BopUwCompany
            ],
            null,
            quote.quotePreference
          );
        });
      }),
      this.dataCatchError('bop quotes', [])
    );
  }

  attuneWcQuotes$(): Observable<QuoteListItem[]> {
    return this.model$.pipe(
      switchMap((model) => {
        if (model.attuneWcQuotes.length === 0) {
          return of([]);
        }

        return forkJoin(
          model.attuneWcQuotes.map((quote) => {
            return forkJoin({
              quoteDetails: this.bindService.getQuoteDetails(quote.id),
              quoteSummary: of(quote),
            });
          })
        );
      }),
      map((quotes) => {
        return quotes.map((quote) =>
          this.mapAttuneQuoteSummaryToQuoteListItem(
            quote.quoteSummary,
            'wc',
            quote.quoteDetails,
            null
          )
        );
      }),
      this.dataCatchError('attune wc quotes', [])
    );
  }

  bopPolicies$({ bopVersion }: { bopVersion: BopVersion }): Observable<QuoteListItem[]> {
    return this.model$.pipe(
      switchMap((model) => {
        let bopPolicies: QuoteOverviewItem[];
        if (bopVersion === 1) {
          bopPolicies = model.bopV1Policies;
        } else if (bopVersion === 2) {
          bopPolicies = model.bopV2Policies;
        } else {
          bopPolicies = model.bopV3Policies;
        }
        if (bopPolicies.length === 0) {
          return of([]);
        }

        return forkJoin(
          bopPolicies.map((policy) => {
            const policyPreference$ =
              [2, 3].includes(bopVersion) && this.showAttuneBopPreference
                ? this.preferenceService.getPreferenceByJobNumber('bop', policy.id)
                : of(null);
            return forkJoin({
              policyPreference: policyPreference$,
              policyDetails: of(policy),
            });
          })
        );
      }),
      map((policies) => {
        return flattenDeep(
          policies.map((policy) =>
            this.mapQuoteOverviewItemToQuoteListItems(
              policy.policyDetails,
              ATTUNE_BOP_VERSION_TO_PRODUCT_BADGE[bopVersion],
              'attune_gw',
              policy.policyPreference,
              null
            )
          )
        );
      }),
      this.dataCatchError(`bop v${bopVersion} policies error`, [])
    );
  }

  attuneWcPolicies$(): Observable<QuoteListItem[]> {
    return this.model$.pipe(
      switchMap((model) => {
        if (model.attuneWcPolicies.length === 0) {
          return of([]);
        }

        return forkJoin(
          model.attuneWcPolicies.map((policy) => {
            return forkJoin({
              policyInfo: of(policy),
              policyDetails: this.bindService.getQuoteDetails(policy.id),
            });
          })
        );
      }),
      map((policies) => {
        return flattenDeep(
          policies.map((policy) =>
            this.mapQuoteOverviewItemToQuoteListItems(
              policy.policyInfo,
              'wc',
              'attune_gw',
              null,
              policy.policyDetails
            )
          )
        );
      })
    );
  }

  accountSummaryQuotes$(): Observable<QuoteListItem[]> {
    return this.accountSummary$.pipe(
      map((accountSummary: AccountSummaryView) => {
        if (accountSummary.status !== 'success') {
          return [];
        }
        return (
          accountSummary.quotes
            // filter out Attune BOP quotes and neptune, which are handled elsewhere
            .filter(
              (quote) =>
                (quote.pasSource as string) !== 'attune_gw' &&
                (quote.pasSource as string) !== 'neptune'
            )
            .map(
              (quote: FrontendQuoteWithLinks): QuoteListItem =>
                this.mapFrontendQuoteWithLinksToQuoteListItem(quote)
            )
        );
      }),
      this.dataCatchError('account summary quotes', [])
    );
  }

  accountSummaryPolicies$(): Observable<QuoteListItem[]> {
    return this.accountSummary$.pipe(
      map((accountSummary: AccountSummaryView) => {
        if (accountSummary.status !== 'success') {
          return [];
        }
        return accountSummary.policyTerms
          .filter(
            (quote) =>
              (quote.pasSource as string) !== 'attune_gw' &&
              (quote.pasSource as string) !== 'neptune' &&
              (quote.pasSource as string) !== 'hiscox' // handled elsewhere
          )
          .map(
            (policyTerm: DigitalCarrierPolicyDetailsWithLinks): QuoteListItem =>
              this.mapDigitalCarrierPolicyDetailsWithLinksToQuoteListItem(policyTerm)
          );
      }),
      this.dataCatchError('account summary policy terms', [])
    );
  }

  accountSummaryBundles$(): Observable<BundleQuoteListItem[]> {
    return this.accountSummary$.pipe(
      map((accountSummary: AccountSummaryView) => {
        if (accountSummary.status !== 'success') {
          return [];
        }
        return accountSummary.quoteBundles
          .map((bundle) => this.mapQuoteBundles(bundle))
          .filter((quote) => !!quote) as BundleQuoteListItem[];
      }),
      this.dataCatchError('account summary bundle quotes', [])
    );
  }

  showAlternativeCarriersCard() {
    if (!this.hasEverpeakWcEnabled || !this.hasAlternativeCarrierCardEnabled) {
      return false;
    }

    // If the broker has an Employers quote already.
    const hasEmployersQuote = this.quotes.find(
      (quote: QuoteListItem) => quote?.pasSource === 'employers' && quote?.product === 'wc'
    );
    if (hasEmployersQuote) {
      return false;
    }

    // For now the only alternative carrier shown in this card is employers but this may be expanded.
    // We show the alternative WC carrier card once the user has a Attune WC quote.
    // If they have a Attune WC policy, we do not want to show it.
    return this.model.attuneWcQuotes.length > 0 && this.model.attuneWcPolicies.length === 0;
  }

  private dataCatchError(dataType: string, returnObject: any) {
    return catchError((error) => {
      this.sentryService.notify(`Error getting and mapping quote or policy data: ${dataType}`, {
        severity: 'error',
        metaData: {
          underlyingErrorMessage: error && error.message,
          underlyingError: error,
        },
      });
      return of(returnObject);
    });
  }

  private determinePreferenceCategory(
    product: InsuranceProductBadge,
    pasSource: InsuranceProductIcon,
    status: string,
    preferenceLevel: PreferenceLevel | null,
    policyPeriodDetails: QuoteDetails | null
  ): PreferenceCategory | null {
    if (pasSource !== 'attune_gw' || status === 'Declined') {
      return null;
    }

    switch (product) {
      case 'bop-plus':
        if (!this.showAttuneBopPreference || !preferenceLevel) {
          return null;
        }

        if (STRONGLY_PREFERRED_PREFERENCE_LEVELS.includes(preferenceLevel)) {
          return PreferenceCategory.STRONGLY_PREFERRED;
        }
        if (PREFERRED_PREFERENCE_LEVELS.includes(preferenceLevel)) {
          return PreferenceCategory.PREFERRED;
        }
        if (NON_PREFERRED_PREFERENCE_LEVELS.includes(preferenceLevel)) {
          return PreferenceCategory.NON_PREFERRED;
        }
        break;
      case 'wc':
        if (!policyPeriodDetails?.hazardGroup) {
          return null;
        }

        // TODO in MAR-735 - remove WC specific hot class logic once updated incentive has been released
        const isStronglyPreferred = STRONGLY_PREFERRED_HAZARD_GROUPS.includes(
          policyPeriodDetails.hazardGroup
        );
        if (isStronglyPreferred && !this.showAttuneWcStronglyPreferred) {
          return PreferenceCategory.HOT_CLASS;
        }
        if (isStronglyPreferred && this.showAttuneWcStronglyPreferred) {
          return PreferenceCategory.STRONGLY_PREFERRED;
        }
        if (PREFERRED_HAZARD_GROUPS.includes(policyPeriodDetails.hazardGroup)) {
          return PreferenceCategory.PREFERRED;
        }
        if (HIGH_HAZARD_GROUPS.includes(policyPeriodDetails.hazardGroup)) {
          return PreferenceCategory.NON_PREFERRED;
        }
        break;
      default:
        return null;
    }

    return null;
  }

  private getQuoteType(
    product: InsuranceProductBadge,
    pasSource: InsuranceProductIcon,
    preferenceCategory: PreferenceCategory | null
  ) {
    if (product === 'cyber_surplus' && pasSource === 'coalition') {
      return 'Surplus';
    }

    if (preferenceCategory) {
      if (preferenceCategory === PreferenceCategory.HOT_CLASS) {
        return '\u{1F525}  Hot Class';
      }

      return preferenceCategory;
    }

    return 'Admitted';
  }

  private isQuoteBindBlocked(
    product: InsuranceProductBadge,
    pasSource: InsuranceProductIcon,
    status: string,
    preferenceCategory: PreferenceCategory | null,
    quoteDetails: QuoteDetails | null
  ): boolean {
    // We only bind block Attune WC/BOP, and we don't want to indicate bind block for declined quotes
    if (pasSource !== 'attune_gw' || status === 'Declined') {
      return false;
    }

    switch (product) {
      case 'bop-plus':
        return (
          this.isAttuneBopBindBlockProducer &&
          preferenceCategory === PreferenceCategory.NON_PREFERRED
        );
      case 'wc':
        if (!quoteDetails?.hazardGroup) {
          return false;
        }

        return (
          this.isAttuneWcBindBlockProducer && HIGH_HAZARD_GROUPS.includes(quoteDetails.hazardGroup)
        );
      default:
        return false;
    }
  }
}
